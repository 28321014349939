import { createAction } from '@reduxjs/toolkit';
import {
  GET_SERVICE,
  GET_SERVICES,
  ADD_COMMENT,
  GET_COMMENT_ID,
  ADD_COMMENT_DYNAMIC,
  FILTER_SERVICES,
  SERVICE_CATEGORY,
  GET_AMENITY_TYPES,
  SET_CURRENT_AMENITY_TYPE,
  UPDATE_SERVICE,
  CREATE_SERVICE,
  CLEAR_ERROR,
  GET_SERVICE_COMMENTS,
  SEARCH_REQUEST,
  COMPLETE_SERVICE,
  CLEAR_SERVICE,
  SET_ASSIGNED_OPERATOR_PROCESS,
  RESET_SERVICE_PAGINATION,
  CLEAR_SERVICE_LIST,
  SET_SERVICE_STATUS_FILTER,
  SET_SERVICE_SEARCH,
  SYNC_YARDI_REQUESTS,
  GET_SERVICE_CATEGORIES
} from '../constants';

export const getServices = createAction(GET_SERVICES);
export const searchRequests = createAction(SEARCH_REQUEST);
export const getService = createAction(GET_SERVICE);
export const clearService = createAction(CLEAR_SERVICE);
export const addComment = createAction(ADD_COMMENT);
export const getCommentId = createAction(GET_COMMENT_ID);
export const addCommentDynamic = createAction(ADD_COMMENT_DYNAMIC);
export const filterServices = createAction(FILTER_SERVICES);
export const getCategories = createAction(SERVICE_CATEGORY);
export const getServiceRequestCategories = createAction(GET_SERVICE_CATEGORIES);
export const getAmenityTypes = createAction(GET_AMENITY_TYPES);
export const setServiceSearch = createAction(SET_SERVICE_SEARCH);
export const setAssignedOperatorProcess = createAction(SET_ASSIGNED_OPERATOR_PROCESS);
export const setCurrentAmenityType = createAction(SET_CURRENT_AMENITY_TYPE);
export const completeService = createAction(COMPLETE_SERVICE);
export const updateService = createAction(UPDATE_SERVICE);
export const createService = createAction(CREATE_SERVICE);
export const clearServiceError = createAction(CLEAR_ERROR);
export const getServiceComments = createAction(GET_SERVICE_COMMENTS);
export const resetServicePagination = createAction(RESET_SERVICE_PAGINATION);
export const clearServiceList = createAction(CLEAR_SERVICE_LIST);
export const setSearviceStatusFilter = createAction(SET_SERVICE_STATUS_FILTER);
export const syncYardiRequests = createAction(SYNC_YARDI_REQUESTS);

export default {
  searchRequests,
  clearServiceError,
  getServiceComments,
  getServices,
  getService,
  addComment,
  getCommentId,
  addCommentDynamic,
  filterServices,
  getCategories,
  getServiceRequestCategories,
  setCurrentAmenityType,
  getAmenityTypes,
  updateService,
  createService,
  setAssignedOperatorProcess,
  resetServicePagination,
  clearServiceList,
  setSearviceStatusFilter,
  syncYardiRequests
};
