import React from 'react';
import { ModalHeader, StyledModal, StyledModalBody } from '../../components/Modals';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '../../components/ui/Form';
import { Flex } from '../../components/ui/Flex';
import { ButtonBodyVariant } from '../../components/ui/ButtonVariant';
import { Select, SelectItem } from '../../components/ui/Select';
import { SettingsModalInput } from '../settings/SettingsModals';
import {
  getThermostat,
  updateEcobeeThermostat,
  updateThermostat
} from '../../redux/actions/thermostat';
import { TimePicker } from 'antd';
import { DATE_FORMATS } from '../users/constant';
import styled from 'styled-components';
import { Fonts, Palette } from '../../components/utils';
import { MODES, TEMPERATURE_UNITS, THERMOSTAT_VENDORS } from '../../helper/constants';
import moment from 'moment';
import { setToast } from '../../redux/actions/window';
import _ from 'lodash';
import { requestPending } from '../../utils/status';
import { UPDATE_ECOBEE_THERMOSTAT } from '../../redux/constants';
import ModalConfirmation from '../conversation/templates/ModalConfirmation';

const LCC_PREFIX = 'LCC-';

export const ThermostatDetailsModal = () => {
  const { t } = useTranslation();
  const { modal, data } = useSelector((state) => state.headerModal);
  const updateEcobeeThermostatStatus = useSelector(
    (state) => state.thermostat.updateEcobeeThermostatStatus
  );
  const dispatch = useDispatch();
  const isLCC = data.id.startsWith(LCC_PREFIX);
  const temperatureUnit = TEMPERATURE_UNITS.FAHRENHEIT;

  const schema = Yup.object().shape({
    hvac_mode: Yup.string().required(t('HVAC Mode is required')),
    cool_temperature: Yup.number().when('hvac_mode', {
      is: (hvacMode) => {
        const mode = hvacMode?.toLowerCase() || '';
        return mode === MODES.AUTO || mode.includes(MODES.COOL);
      },
      then: (schema) =>
        schema
          .required(t('Temperature is required'))
          .min(
            data.coolRange.min,
            `${t('Cool temperature must be greater than or equal to')} ${data.coolRange.min}`
          )
          .max(
            data.coolRange.max,
            `${t('Cool temperature must be less than or equal to')} ${data.coolRange.max}`
          )
          .test(
            'hvac-auto-cool-temp',
            t('Cool temperature must be lower than heat temperature'),
            function (cool) {
              const { hvac_mode, heat_temperature } = this.parent;
              const mode = hvac_mode?.toLowerCase() || '';
              return mode !== MODES.AUTO || cool <= heat_temperature;
            }
          ),
      otherwise: Yup.number().notRequired()
    }),
    heat_temperature: Yup.number().when('hvac_mode', {
      is: (hvacMode) => {
        const mode = hvacMode?.toLowerCase() || '';
        return mode === MODES.AUTO || mode.includes(MODES.HEAT);
      },
      then: (schema) =>
        schema
          .required(t('Temperature is required'))
          .min(
            data.heatRange.min,
            `${t('Heat temperature must be greater than or equal to')} ${data.heatRange.min}`
          )
          .max(
            data.heatRange.max,
            `${t('Heat temperature must be less than or equal to')} ${data.heatRange.max}`
          )
          .test(
            'hvac-auto-heat-temp',
            t('Heat temperature must be higher than cool temperature'),
            function (heat) {
              const { hvac_mode, cool_temperature } = this.parent;
              const mode = hvac_mode?.toLowerCase() || '';
              return mode !== MODES.AUTO || heat >= cool_temperature;
            }
          ),
      otherwise: Yup.number().notRequired()
    }),
    fan: Yup.string().required(t('Fan mode is required')),
    temperature_duration: Yup.string().required(t('Hold Duration is required')),
    next_period_time: Yup.string().when(['temperature_duration'], {
      is: (temperature_duration) => isLCC && temperature_duration.includes(MODES.TEMPORARY),
      then: Yup.string().required('Fan mode period time is required'),
      otherwise: Yup.string().notRequired()
    })
  });

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      hvac_mode: data.hvacMode,
      cool_temperature: data.setPoint.desiredCool ? Math.round(data.setPoint.desiredCool) : 0,
      heat_temperature: data.setPoint.desiredHeat ? Math.round(data.setPoint.desiredHeat) : 0,
      fan: data.fanMode,
      temperature_duration: data.holdDuration,
      next_period_time: undefined
    }
  });

  const temperatureDurationWatch = useWatch({
    control: form.control,
    name: 'temperature_duration'
  })?.toLowerCase();

  const hvacModeWatch = useWatch({
    control: form.control,
    name: 'hvac_mode'
  })?.toLowerCase();

  const isAutoMode = hvacModeWatch === MODES.AUTO;
  const isHVACOff = hvacModeWatch === MODES.OFF;
  const shouldDisplayCoolTemperature = isAutoMode || hvacModeWatch?.includes(MODES.COOL);
  const shouldDisplayHeatTemperature = isAutoMode || hvacModeWatch?.includes(MODES.HEAT);

  const handleSubmit = (values) => {
    const body = {
      thermostat_id: data.id,
      hvac_mode: values.hvac_mode,
      cool_temperature: values.cool_temperature,
      heat_temperature: values.heat_temperature,
      fan: values.fan,
      temperature_duration: values.temperature_duration,
      fan_duration: values.temperature_duration
    };

    if (isLCC && data.temperature_duration) {
      if (data.temperature_duration.toLowerCase().includes(MODES.TEMPORARY)) {
        const nextPeriodTime = moment(values.next_period_time);
        body.next_period_time = nextPeriodTime.isValid()
          ? nextPeriodTime.format('HH:mm:00')
          : undefined;
      } else {
        body.next_period_time = moment('00:00:00');
      }
    }

    dispatch(
      updateThermostat({
        thermostat_id: data.id,
        body: body,
        success: () => {
          form.reset({
            ...body,
            next_period_time: values.next_period_time
          });

          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Thermostat has been successfully updated')
            })
          );
        },
        fail: (res) => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: res?.data?.message ? res.data.message : t('Thermostat update failed'),
              modal: 'errorToast'
            })
          );
        }
      })
    );
  };

  return (
    <StyledModal show={modal === 'thermostatDetailsModal'}>
      <ModalHeader title={data.unitNum} />
      <StyledModalBody>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <Flex direction="column" gap="24px">
              {data.hvacModesLabels && (
                <FormField
                  control={form.control}
                  name="hvac_mode"
                  render={({ field }) => (
                    <FormItem alt>
                      <FormLabel> {t('HVAC modes')}</FormLabel>
                      <FormControl>
                        <Select
                          {...field}
                          onChange={(e) => {
                            if (data.vendorId === THERMOSTAT_VENDORS.ECOBEE.vendorId) {
                              ModalConfirmation(
                                dispatch,
                                t(
                                  'After clicking confirm, the HVAC mode will be changed. Are you sure you want to continue?'
                                ),
                                t('Confirm HVAC Mode change'),
                                t,
                                () => {
                                  // cancel callback
                                },
                                () => {
                                  // ok callback

                                  dispatch(
                                    updateEcobeeThermostat({
                                      ecobeeID: data.id,
                                      body: {
                                        hvacMode: e
                                      },
                                      success: () => {
                                        field.onChange(e);
                                        dispatch(
                                          getThermostat({
                                            deviceId: data.id,
                                            fail: (res) => {
                                              dispatch(
                                                setToast({
                                                  toastShow: true,
                                                  toastMessage: res.data.message
                                                    ? res.data.message
                                                    : t('Failed to get thermostat data'),
                                                  modal: 'errorToast'
                                                })
                                              );
                                            }
                                          })
                                        );
                                        dispatch(
                                          setToast({
                                            toastShow: true,
                                            toastMessage: t(
                                              'Thermostat has been successfully updated'
                                            )
                                          })
                                        );
                                      },
                                      fail: (res) => {
                                        dispatch(
                                          setToast({
                                            toastShow: true,
                                            toastMessage: res.data.message
                                              ? res.data.message
                                              : t('Failed to update Ecobee HVAC Mode'),
                                            modal: 'errorToast'
                                          })
                                        );
                                      }
                                    })
                                  );
                                },
                                t('Confirm HVAC Mode change')
                              );
                            } else {
                              field.onChange(e);
                            }
                          }}
                          placeholder={'Please select'}>
                          {data.hvacModesLabels.map((hvacModes) => (
                            <SelectItem key={hvacModes.value} value={hvacModes.value}>
                              {hvacModes.label}
                            </SelectItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}

              {shouldDisplayCoolTemperature && (
                <FormField
                  control={form.control}
                  name="cool_temperature"
                  render={({ field }) => (
                    <FormItem alt>
                      <FormLabel>{`${t('Cool Temperature')} (${temperatureUnit.label})`}</FormLabel>
                      <FormControl>
                        <SettingsModalInput
                          type="number"
                          min={data.coolRange.min}
                          max={data.coolRange.max}
                          placeholder={t('Cool Temperature')}
                          autoComplete="off"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}

              {shouldDisplayHeatTemperature && (
                <FormField
                  control={form.control}
                  name="heat_temperature"
                  render={({ field }) => (
                    <FormItem alt>
                      <FormLabel>{`${t('Heat Temperature')} (${temperatureUnit.label})`}</FormLabel>
                      <FormControl>
                        <SettingsModalInput
                          type="number"
                          min={data.heatRange.min}
                          max={data.heatRange.max}
                          placeholder={t('Heat Temperature')}
                          autoComplete="off"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}

              {!isHVACOff && data.fanModes && (
                <FormField
                  control={form.control}
                  name="fan"
                  render={({ field }) => (
                    <FormItem alt>
                      <FormLabel> {t('Fan mode')}</FormLabel>
                      <FormControl>
                        <Select {...field} placeholder={'Please select'}>
                          {data.fanModes.map((fanMode) => (
                            <SelectItem key={fanMode.value} value={fanMode.value}>
                              {fanMode.label}
                            </SelectItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}

              {!isHVACOff && data.holdDurations && (
                <>
                  <FormField
                    control={form.control}
                    name="temperature_duration"
                    render={({ field }) => (
                      <FormItem alt>
                        <FormLabel> {t('Hold durations')}</FormLabel>
                        <FormControl>
                          <Select
                            {...field}
                            onChange={(e) => {
                              form.setValue('next_period_time', '');
                              field.onChange(e);
                            }}
                            placeholder={'Please select'}>
                            {data.holdDurations.map((holdDuration) => (
                              <SelectItem key={holdDuration.label} value={holdDuration.value}>
                                {holdDuration.label}
                              </SelectItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  {isLCC && temperatureDurationWatch.includes(MODES.TEMPORARY) && (
                    <FormField
                      control={form.control}
                      name="next_period_time"
                      render={({ field }) => {
                        const time = moment(field.value).isValid()
                          ? moment(field.value)
                          : undefined;
                        return (
                          <FormItem alt>
                            <FormLabel> {t('Next scheduled time')}</FormLabel>
                            <FormControl>
                              <StyledTimePicker
                                {...field}
                                onChange={(e) => {
                                  field.onChange(e);
                                }}
                                value={time}
                                minuteStep={15}
                                format={DATE_FORMATS.hh_mm_A}
                                use12Hours
                                showNow={false}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />
                  )}
                </>
              )}

              <ButtonBodyVariant
                type="submit"
                disabled={
                  !form.formState.isDirty ||
                  form.formState.isSubmitting ||
                  updateEcobeeThermostatStatus === requestPending(UPDATE_ECOBEE_THERMOSTAT) ||
                  (data.vendorId === THERMOSTAT_VENDORS.ECOBEE.vendorId &&
                    hvacModeWatch === MODES.OFF)
                }>
                {t('Save')}
              </ButtonBodyVariant>
            </Flex>
          </form>
        </Form>
      </StyledModalBody>
    </StyledModal>
  );
};

const StyledTimePicker = styled(TimePicker)`
  height: 56px;
  border-radius: 10px;
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: ${Palette.BLACK_50};
  background-color: ${Palette.SOLITUDE};
  border: none;
  padding: 0px 20px;
  box-shadow: none;

  .ant-picker-input {
    input {
      color: ${Palette.BLACK_50};
      border: none;
      font-family: ${Fonts.OPEN_SANS_FONT};
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;

      &::placeholder {
        color: ${Palette.BLACK_50};
        font-family: ${Fonts.OPEN_SANS_FONT};
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
      :hover {
        cursor: pointer;
      }
    }
  }

  :hover {
    border-color: inherit;
    cursor: pointer;
  }
`;

export default ThermostatDetailsModal;
