import React from 'react';
import { BreadcrumbIcon, BreadcrumbItem, BreadcrumbLink } from '../ui/Breadcrumb';

const DefaultBreadcrumb = ({ routeName, href }) => {
  return (
    <>
      <BreadcrumbIcon />
      <BreadcrumbItem>
        <BreadcrumbLink href={href}>{routeName}</BreadcrumbLink>
      </BreadcrumbItem>
    </>
  );
};

export default DefaultBreadcrumb;
