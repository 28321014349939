import { createAction } from '@reduxjs/toolkit';
import {
  CLEAR_AVATAR,
  ADD_AVATAR,
  GET_AVATAR,
  EDIT_AVATAR,
  GET_USER,
  ADD_USER,
  ADD_USERS_FROM_CSV,
  EDIT_USER,
  REMOVE_USER,
  SEARCH_USERS,
  BUILDING_USERS,
  CLEAR_USER,
  CLEAR_USERS_FROM_CSV,
  CLEAR_USERS_CSV_FILE,
  SET_USER_AVATAR,
  SET_USER,
  SET_USERS_FROM_CSV,
  SET_USERS_CSV_FILE,
  CLEAR_ERROR,
  INVITE_USER,
  GET_USER_DEVICE_SLOTS,
  SET_USER_DEVICE_SLOTS,
  SET_USERS_ACTIVATED,
  SET_USER_SEARCH,
  RESET_USER_PAGINATION,
  GET_ALL_USERS,
  ADD_SERVICE_REQUEST_CACHE_USER,
  SYNC_YARDI_USERS,
  REACTIVATE_USER
} from '../constants';

export const clearAvatar = createAction(CLEAR_AVATAR);
export const addCachedServiceRequestUser = createAction(ADD_SERVICE_REQUEST_CACHE_USER);
export const inviteUser = createAction(INVITE_USER);
export const addAvatar = createAction(ADD_AVATAR);
export const getAvatar = createAction(GET_AVATAR);
export const editAvatar = createAction(EDIT_AVATAR);
export const getUser = createAction(GET_USER);
export const addUser = createAction(ADD_USER);
export const addUsersFromCSV = createAction(ADD_USERS_FROM_CSV);
export const editUser = createAction(EDIT_USER);
export const removeUser = createAction(REMOVE_USER);
export const searchUsers = createAction(SEARCH_USERS);
export const getBuildingUsers = createAction(BUILDING_USERS);
export const clearUser = createAction(CLEAR_USER);
export const clearUsersFromCSV = createAction(CLEAR_USERS_FROM_CSV);
export const clearUsersCSVFile = createAction(CLEAR_USERS_CSV_FILE);
export const setUserAvatar = createAction(SET_USER_AVATAR);
export const setUser = createAction(SET_USER);
export const setUsersFromCSV = createAction(SET_USERS_FROM_CSV);
export const setUsersCSVFile = createAction(SET_USERS_CSV_FILE);
export const clearUserError = createAction(CLEAR_ERROR);
export const getUserDeviceSlots = createAction(GET_USER_DEVICE_SLOTS);
export const setUserDeviceSlots = createAction(SET_USER_DEVICE_SLOTS);
export const setUsersActivated = createAction(SET_USERS_ACTIVATED);
export const setUserSearch = createAction(SET_USER_SEARCH);
export const resetUserPagination = createAction(RESET_USER_PAGINATION);
export const getAllUsers = createAction(GET_ALL_USERS);
export const syncYardiUsers = createAction(SYNC_YARDI_USERS);
export const reactivateUser = createAction(REACTIVATE_USER);

export default {
  clearAvatar,
  addCachedServiceRequestUser,
  inviteUser,
  searchUsers,
  clearUserError,
  clearUser,
  clearUsersFromCSV,
  clearUsersCSVFile,
  addAvatar,
  getAvatar,
  editAvatar,
  getUser,
  addUser,
  addUsersFromCSV,
  setUsersFromCSV,
  setUsersCSVFile,
  editUser,
  removeUser,
  getBuildingUsers,
  setUsersActivated,
  setUserDeviceSlots,
  setUserSearch,
  resetUserPagination,
  getAllUsers,
  syncYardiUsers,
  reactivateUser
};
