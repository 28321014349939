import { createReducer } from "@reduxjs/toolkit";
import {
  ADD_OFFER,
  EDIT_OFFER,
  GET_OFFERS,
  GET_OFFER,
  ADD_LOGO,
  CLEAR_ERROR,
  CLEAR_LOGO,
  SEARCH_OFFERS,
  DELETE_OFFER,
  GET_CATEGORIES,
  SET_OFFER_TREE_DATA
} from "../constants";
import {
  requestSuccess,
  requestFail,
  requestPending
} from "../../utils/status";
import { ceil } from "lodash";

const initialState = {
  offers: [],
  offer: {},
  offerIndex: 0,
  status: "INIT",
  error: null,
  logo: "",
  offerCategories: [],
  totalDeals: null,
  perPages: null,
  pageCount: null,
  pagination: {
    currentPage: 1,
    perPage: null,
    total: null
  },
  offerTreeData: []
};

export default createReducer(initialState, {
  [CLEAR_ERROR]: (state) => ({
    ...state,
    error: null
  }),

  [CLEAR_LOGO]: (state, { payload }) => ({
    ...state,
    logo: ''
  }),

  [SET_OFFER_TREE_DATA]: (state, { payload }) => {
    return {
      ...state,
      offerTreeData: [payload.offerTreeData]
    };
  },
  [requestSuccess(ADD_LOGO)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(ADD_LOGO),
    logo: payload.filename,
    error: null
  }),

  [requestFail(ADD_LOGO)]: (state, { payload }) => ({
    ...state,
    status: requestFail(ADD_LOGO),
    logo: '',
    error: payload.error
  }),

  [requestSuccess(GET_OFFERS)]: (state, { payload }) => {
    return {
      ...state,
      status: requestSuccess(GET_OFFERS),
      offers: payload?.data,
      totalDeals: payload?.data?.total,

      pagination: {
        currentPage: payload?.pagination['pagination-current-page'],
        perPage: payload?.pagination['pagination-per-page'],
        total: payload?.pagination['pagination-total']
      },
      error: null
    };
  },

  [requestPending(GET_OFFERS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_OFFERS)
  }),

  [requestFail(GET_OFFERS)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_OFFERS),
    offers: [],
    error: payload.error
  }),
  [requestSuccess(GET_CATEGORIES)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(GET_CATEGORIES),
    offerCategories: payload,
    error: null
  }),

  [requestPending(GET_CATEGORIES)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_CATEGORIES)
  }),

  [requestFail(GET_CATEGORIES)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_CATEGORIES),
    offerCategories: [],
    error: payload.error
  }),

  [requestSuccess(GET_CATEGORIES)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(GET_CATEGORIES),
    offerCategories: payload,
    error: null
  }),

  [requestPending(GET_CATEGORIES)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_CATEGORIES)
  }),

  [requestFail(GET_CATEGORIES)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_CATEGORIES),
    offerCategories: [],
    error: payload.error
  }),

  [requestSuccess(GET_OFFER)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(GET_OFFER),
    offer: payload,
    error: null
  }),

  [requestPending(GET_OFFER)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_OFFER)
  }),

  [requestFail(GET_OFFER)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_OFFER),
    offer: {},
    error: payload.error
  }),

  [requestSuccess(ADD_OFFER)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(ADD_OFFER),
    error: null
  }),
  [requestPending(ADD_OFFER)]: (state, { payload }) => ({
    ...state,
    status: requestPending(ADD_OFFER)
  }),

  [requestFail(ADD_OFFER)]: (state, { payload }) => ({
    ...state,
    status: requestFail(ADD_OFFER),
    error: payload.data
  }),

  [requestSuccess(EDIT_OFFER)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(EDIT_OFFER),
    error: null
  }),
  [requestPending(EDIT_OFFER)]: (state, { payload }) => ({
    ...state,
    status: requestPending(EDIT_OFFER)
  }),

  [requestFail(EDIT_OFFER)]: (state, { payload }) => ({
    ...state,
    status: requestFail(EDIT_OFFER),
    error: payload.data
  }),
  [requestPending(SEARCH_OFFERS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(SEARCH_OFFERS)
  }),
  [requestSuccess(SEARCH_OFFERS)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(SEARCH_OFFERS),
    offers: payload.data,
    totalDeals: payload.total,
    perPage: payload.per_page,
    pageCount: Math.ceil(payload.total / payload.per_page),
    pagination: {
      currentPage: payload.current_page,
      perPage: payload.per_page,
      total: payload.total
    },
    error: null
  }),

  [requestFail(SEARCH_OFFERS)]: (state, { payload }) => ({
    ...state,
    status: requestFail(SEARCH_OFFERS),
    error: payload?.data?.message
  }),
  [requestSuccess(DELETE_OFFER)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(DELETE_OFFER),
    error: null
  }),
  [requestPending(DELETE_OFFER)]: (state, { payload }) => ({
    ...state,
    status: requestPending(DELETE_OFFER)
  }),

  [requestFail(DELETE_OFFER)]: (state, { payload }) => ({
    ...state,
    status: requestFail(DELETE_OFFER),
    error: payload.data
  })
});
