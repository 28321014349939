import React from 'react';
import {
  InputSeparator,
  SettingsModalBoldText,
  SettingsModalInput
} from '../../settings/SettingsModals';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { ErrorMessage } from '../../../components/ErrorMessage';
import { ErrorText } from '../../../components/utils/AvatarCropper';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getDDUnits, getReservations } from '../../../redux/actions/unit';
import { getAllBuildings } from '../../../redux/actions/building';
import { CInput } from '@coreui/react';
import styled from 'styled-components';
import { Fonts, Palette } from '../../../components/utils';
import { Select, SelectItem } from '../../../components/ui/Select';
import { BUILDING_TYPE, DATE_FORMATS, USER_ROLE, USER_TYPE } from '../../users/constant';
import { openModal } from '../../../helper/modals';
import { CustomDatePicker } from '../../../components/CustomSearchBar';
import moment from 'moment';
import { formatTimeString } from '../../../components/Calendar/eventHelper';
import {useGetConfigs} from '../../../hooks/useGetConfigs';

export const ConditionalField = ({ conditions, renderActive, renderInactive }) => {
  const isActive = conditions.every((condition) => condition);
  return isActive ? renderActive() : renderInactive();
};

export const ConditionalUserTypeField = () => {
  const {
    control,
    formState: { errors }
  } = useFormContext();
  return (
    <InputSeparator>
      <SettingsModalBoldText>User Type</SettingsModalBoldText>
      <Controller
        control={control}
        name="type"
        render={({ field: { value, name, onChange } }) => {
          return (
            <>
              <Select
                fieldNames={{ label: 'name', value: 'id' }}
                height={'56px'}
                options={Object.values(USER_TYPE).map((item) => ({
                  id: item.id,
                  name: item.value
                }))}
                error={errors?.type}
                placeholder="Select User Type"
                value={value || undefined}
                onChange={onChange}></Select>
              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => <ErrorText>{message}</ErrorText>}
              />
            </>
          );
        }}
      />
    </InputSeparator>
  );
};

export const ConditionalBuildingField = () => {
  const {
    control,
    resetField,
    setValue,
    formState: { errors }
  } = useFormContext();
  const organizationId = useWatch({ control, name: 'organization' });
  const dispatch = useDispatch();
  const buildings = useSelector((state) => state.building);
  const { roleId } = useSelector((state) => state.auth);
  const addUserType = useSelector((state) => state.addUserType);

  useEffect(() => {
    if (organizationId.organizationId) {
      dispatch(
        getAllBuildings({
          id: organizationId.organizationId
        })
      );
    }
  }, [organizationId]);

  return (
    <InputSeparator>
      <Controller
        control={control}
        defaultValue={addUserType.buildings?.buildingId}
        name="buildings"
        render={({ field: { value, name, onChange } }) => {
          return (
            <>
              <div className="flex justify-content-between">
                <SettingsModalBoldText>{'Building'}</SettingsModalBoldText>
                {roleId === USER_ROLE.MANAGER ||
                  roleId === USER_ROLE.ADMIN ||
                  (roleId === USER_ROLE.SUPER_ADMIN && (
                    <AddNewBuildingLink
                      onClick={() => {
                        openModal('addBuilding');
                      }}>
                      Add new building
                    </AddNewBuildingLink>
                  ))}
              </div>
              <Select
                disabled={
                  !organizationId.organizationId ||
                  buildings.status === 'GET_ALL_BUILDINGS_PENDING' ||
                  buildings.allBuildings?.length === 0
                }
                placeholder={
                  buildings.allBuildings?.length === 0
                    ? 'No buildings available'
                    : 'Select Building'
                }
                error={errors?.buildings}
                loading={buildings.status === 'GET_ALL_BUILDINGS_PENDING'}
                onChange={(e) => {
                  const foundBuilding = buildings.allBuildings.find(
                    (building) => e === building.id
                  );
                  onChange({
                    buildingId: foundBuilding.id,
                    type: foundBuilding.type,
                    name: foundBuilding.name
                  });
                  setValue('reservation', undefined);
                }}
                value={value.name || undefined}>
                {buildings.allBuildings.map((building) => (
                  <SelectItem key={building.id} value={building.id}>
                    {building.name}
                  </SelectItem>
                ))}
              </Select>

              <ErrorMessage
                overrideError={errors?.buildings?.name}
                render={({ message }) => <ErrorText>{message}</ErrorText>}
              />
            </>
          );
        }}
      />
    </InputSeparator>
  );
};

export const ConditionalResidentField = () => {
  const { control, errors, getValues, setValue, formState } = useFormContext();
  const buildingsWatch = useWatch({ control, name: 'buildings' });
  const userTypeWatch = useWatch({ control, name: 'type' });
  const unitWatch = useWatch({ control, name: 'unitNumber' });
  const dispatch = useDispatch();
  const units = useSelector((state) => state.unit);
  const addUserType = useSelector((state) => state.addUserType);
  const { config } = useGetConfigs();

  useEffect(() => {
    if (buildingsWatch.buildingId) {
      dispatch(
        getDDUnits({
          id: buildingsWatch.buildingId
        })
      );
    }
  }, [buildingsWatch]);

  return (
    <>
      <Controller
        control={control}
        defaultValue={addUserType.unitNumber?.id}
        name="unitNumber"
        render={({ field: { onChange, value, name } }) => {
          return (
            <InputSeparator>
              <SettingsModalBoldText>
                {config?.suite_enabled ? 'Room Number' : 'Unit Number'}
              </SettingsModalBoldText>
              <Select
                disabled={units.unitsForDD?.length === 0 || !buildingsWatch?.buildingId}
                height={'56px'}
                placeholder={
                  config?.suite_enabled ? 'Select Room Number' : 'Select Unit Number'
                }
                onChange={(e) => {
                  if (e === 0) {
                    onChange({ unitId: undefined, name: '' });
                    return;
                  }
                  const foundUnit = units.unitsForDD.find((unit) => e === unit.id);
                  onChange(foundUnit);
                  setValue('reservation', null);
                }}
                value={value?.id || undefined}
                error={formState.errors?.unitNumber}>
                <SelectItem value={undefined}>No Unit</SelectItem>
                {units.unitsForDD.map((unit) => (
                  <SelectItem key={unit.id} value={unit.id}>
                    {unit.unit_num}
                  </SelectItem>
                ))}
              </Select>
              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => <ErrorText>{message}</ErrorText>}
              />
            </InputSeparator>
          );
        }}
      />

      {buildingsWatch?.buildingId && (
        <>
          {buildingsWatch?.type === BUILDING_TYPE.TEMPORARY_RESIDENCY.id &&
          userTypeWatch === USER_TYPE.CO_RESIDENT.id ? (
            <>
              {unitWatch?.id && (
                <Controller
                  control={control}
                  name="reservation"
                  defaultValue={addUserType.reservation}
                  render={({ field: { onChange, value, name, ...rest } }) => {
                    const foundReservation = units?.unitsForDD.find(
                      (unit) => unit.id === unitWatch?.id
                    );
                    return (
                      <InputSeparator>
                        <SettingsModalBoldText>Reservation</SettingsModalBoldText>
                        <Select
                          name={name}
                          height={'56px'}
                          placeholder={'Reservation'}
                          onChange={(e) => {
                            if (e === 0) {
                              onChange(undefined);
                              return;
                            }
                            const reservation = foundReservation?.reservations?.find(
                              (reservation) => reservation.id === e
                            );
                            onChange(reservation);
                          }}
                          error={formState.errors?.reservation?.id || formState.errors?.reservation}
                          value={value?.id || undefined}>
                          {foundReservation?.reservations.length > 0 ? (
                            <>
                              {foundReservation?.reservations.map((reservation) => (
                                <SelectItem key={reservation.id} value={reservation.id}>
                                  {reservation.user_name} -{' '}
                                  {moment
                                    .utc(reservation.check_in_date)
                                    .format(DATE_FORMATS.MMM_D_YYYY)}{' '}
                                  -{' '}
                                  {moment
                                    .utc(reservation.check_out_date)
                                    .format(DATE_FORMATS.MMM_D_YYYY)}
                                </SelectItem>
                              ))}
                            </>
                          ) : (
                            <SelectItem value={0}>No Reservation</SelectItem>
                          )}
                        </Select>
                        <ErrorMessage
                          overrideError={
                            formState.errors?.reservation?.id || formState.errors?.reservation
                          }
                          render={({ message }) => <ErrorText>{message}</ErrorText>}
                        />
                      </InputSeparator>
                    );
                  }}
                />
              )}
            </>
          ) : (
            <>
              <Controller
                control={control}
                defaultValue={addUserType.moveInDate}
                name="moveInDate"
                render={({ field: { name, onChange, value, ...rest } }) => (
                  <InputSeparator>
                    <SettingsModalBoldText>Move In Date</SettingsModalBoldText>
                    <CustomDatePicker
                      {...rest}
                      width={'100%'}
                      height={'56px'}
                      min={new Date().toISOString().split('T')[0]}
                      disabledDate={(current) => current < moment().startOf('day')}
                      format={DATE_FORMATS.MM_DD_YYYY}
                      error={formState.errors?.moveInDate}
                      onChange={(current, dateString) => {
                        onChange(dateString);
                        const maxCreationDate = getValues('moveOutDate');
                        if (
                          current &&
                          maxCreationDate &&
                          current.isAfter(moment(maxCreationDate).subtract(1, 'd'))
                        ) {
                          setValue('moveOutDate', undefined);
                        }
                      }}
                      value={
                        value
                          ? moment(value)
                          : addUserType.moveInDate
                          ? moment(addUserType.moveInDate)
                          : undefined
                      }
                    />
                    <ErrorMessage
                      errors={errors}
                      name={name}
                      render={({ message }) => <ErrorText>{message}</ErrorText>}
                    />
                  </InputSeparator>
                )}
              />

              <Controller
                control={control}
                defaultValue={addUserType.moveOutDate}
                name="moveOutDate"
                render={({ field: { name, onChange, value } }) => {
                  return (
                    <InputSeparator>
                      <SettingsModalBoldText>Move Out Date</SettingsModalBoldText>
                      <CustomDatePicker
                        width={'100%'}
                        height={'56px'}
                        disabledDate={(current) => {
                          const minCreationDate = getValues('moveInDate');
                          if (minCreationDate) {
                            return current && current.isBefore(moment(minCreationDate).add(1, 'd'));
                          }

                          if (
                            current &&
                            minCreationDate &&
                            current.isBefore(moment(minCreationDate).add(1, 'd'))
                          ) {
                            setValue('moveInDate', undefined);
                          }
                        }}
                        error={formState.errors?.moveOutDate}
                        minDate={moment().subtract(1, 'days').toDate()}
                        format={DATE_FORMATS.MM_DD_YYYY}
                        onChange={(_, dateString) => {
                          onChange(dateString);
                        }}
                        value={value ? moment(value) : undefined}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={name}
                        render={({ message }) => <ErrorText>{message}</ErrorText>}
                      />
                    </InputSeparator>
                  );
                }}
              />
            </>
          )}

          {buildingsWatch?.type !== BUILDING_TYPE.TEMPORARY_RESIDENCY.id && (
            <Controller
              control={control}
              name="monthlyRent"
              render={({ field: { name, ...rest } }) => (
                <InputSeparator>
                  <SettingsModalBoldText>Monthly Rent</SettingsModalBoldText>
                  <SettingsModalInput
                    {...rest}
                    // error={!!errors.monthlyRent}
                    placeholder={'Monthly Rent'}
                  />
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </InputSeparator>
              )}
            />
          )}
        </>
      )}
    </>
  );
};

const AddNewBuildingLink = styled.p`
  color: ${Palette.BLACK};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  margin: 0;
  text-decoration: underline;
  cursor: pointer;
`;

const StyledDatePicker = styled(CInput)`
  height: 56px;
  background-color: ${Palette.SOLITUDE};
  color: ${Palette.BLACK_50};
  border: none;
  border-radius: 10px;
  width: 100%;
  padding: 0px 24px;
  font-size: 16px;
  font-weight: 600;
  font-family: ${Fonts.OPEN_SANS_FONT};

  outline: none;
  box-shadow: none !important;

  :focus {
    background-color: ${Palette.SOLITUDE};
  }
`;
