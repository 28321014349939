import { createAction } from '@reduxjs/toolkit';
import {
  GET_THERMOSTAT,
  GET_THERMOSTATS,
  UPDATE_ECOBEE_THERMOSTAT,
  UPDATE_THERMOSTAT
} from '../constants';

export const getThermostat = createAction(GET_THERMOSTAT);
export const searchThermostats = createAction(GET_THERMOSTATS);
export const updateThermostat = createAction(UPDATE_THERMOSTAT);
export const updateEcobeeThermostat = createAction(UPDATE_ECOBEE_THERMOSTAT);

export default {
  getThermostat,
  searchThermostats,
  updateThermostat,
  updateEcobeeThermostat
};
