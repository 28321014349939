import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Palette } from '../utils/palette';
import { Fonts } from '../utils';

export const TextArea = forwardRef(({
  height,
  width,
  fontSize,
  fontWeight,
  fontFamily,
  background,
  placeholder,
  type,
  value,
  onChange,
  error,
  cssOverride,
  disabled,
  ...props
}, ref) => {
  return (
    <StyledTextArea
      height={height}
      width={width}
      fontSize={fontSize}
      fontWeight={fontWeight}
      background={background}
      placeholder={placeholder}
      fontFamily={fontFamily}
      type={type}
      value={value}
      onChange={onChange}
      error={error}
      cssOverride={cssOverride}
      disabled={disabled}
      ref={ref}
      {...props}
    />
  );
});

export const StyledTextArea = styled.textarea`
  background: ${({ background }) => background || Palette.SOLITUDE};
  color: ${Palette.BLACK_50};
  border: none;
  border-radius: 10px;
  height: ${({ height }) => height || '200px'};
  width: ${({ width }) => width || '100%'};
  padding: 0px 24px;
  font-size: ${({ fontSize }) => fontSize || '16px'};
  font-weight: ${({ fontWeight }) => fontWeight || '600'};
  font-family: ${({ fontFamily }) => fontFamily || Fonts.OPEN_SANS_FONT};

  padding-top: 1rem;

  :focus {
    background-color: ${Palette.WHITE};
    color: ${Palette.BLACK};
    outline: none;

    border: ${Palette.BORDER_OUTLINE} solid 0.5px !important;

    ::placeholder {
      color: ${Palette.BLACK};
    }
  }

  :disabled {
    opacity: 50%;
  }

  ${({ error }) => error && `border: ${Palette.ERROR} solid 2px;`}

  ${({ cssOverride }) => cssOverride};
`;
