import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { baseURL } from '../utils/axios';
import { UserType } from '../views/users/constant';
import {
  filterConfig,
  IconOption,
  inputSelectCustomStyles,
  StyledAsyncPaginate
} from './CustomSearchBar';

const UserAsyncDropDown = ({
  noUser = true,
  value,
  setValue,
  type = 0,
  buildingId = null,
  disabled = false,
  residentOnly = false,
  onChange,
  only_residents = false,
  skip_deleted = false,
  skip_coresidents = false,
  style = {},
  error,
  placeholder,
  scrollFormDown = () => {},
  createCustomLabel = null
}) => {
  const { t } = useTranslation();

  const { building_id } = useSelector((state) => state.auth);
  if (parseInt(buildingId) === null) {
    buildingId = building_id;
  }

  const [prevSearch, setPrevSearch] = useState({
    buildingId: -1,
    query: '',
    page: 0
  });

  const isSameSearch = (query, page) => {
    return (
      prevSearch.buildingId === building_id &&
      query === prevSearch.query &&
      page === prevSearch.page
    );
  };

  useEffect(() => {
    setPrevSearch((prev) => ({
      ...prev,
      buildingId: -1
    }));
  }, [buildingId]);

  const loadOptions = async (searchQuery, loadedOptions, additional) => {
    const { page } = additional;

    if (isSameSearch(searchQuery, page)) {
      return {
        options: [],
        hasMore: false
      };
    } else {
      setPrevSearch({
        page: page,
        query: searchQuery,
        buildingId: buildingId
      });
    }

    let url = `${baseURL}/users?&building=${parseInt(buildingId)}&type=${parseInt(type)}`;

    if (searchQuery) {
      url += `&q=${searchQuery}`;
    } else {
      url += `&page=${page}`;
    }

    if (only_residents) {
      url += `&only_residents=true`;
    }

    if (skip_deleted === true) {
      url += `&skip_deleted=true`;
    }

    if (skip_coresidents === true) {
      url += `&skip_coresidents=true`;
    }

    url += '&format=short';

    if (buildingId !== null) {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('idToken')}`,
          'org-location': window.localStorage.data_center
        }
      });
      const responseJSON = await response.json();

      let restructureData = [];
      page < 2 && noUser === true && restructureData.push({ value: 0, label: t('No user') });

      if (residentOnly) {
        responseJSON
          .filter(
            (user) =>
              parseInt(user.type_id) === UserType.RESIDENT && !Boolean(parseInt(user.unit_id))
          )
          .map((user) => {
            return restructureData.push({
              value: user.id,
              label: typeof createCustomLabel === 'function' ? createCustomLabel(user) : `${user.first_name} ${user.last_name}`
            });
          });
      } else {
        responseJSON.map((user) => {
          return restructureData.push({
            value: user.id,
            label: typeof createCustomLabel === 'function' ? createCustomLabel(user) : `${user.first_name} ${user.last_name}`
          });
        });
      }
      return {
        options: restructureData,
        hasMore: responseJSON !== null && responseJSON.length >= 20,
        additional: {
          page: searchQuery ? 2 : page + 1
        }
      };
    }
    return {
      options: [],
      hasMore: false
    };
  };

  const handleOnChange = (item) => {
    if (onChange) {
      onChange(item);
    } else {
      setValue(item);
    }
  };

  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    if (scrollTop == 0) scrollFormDown();
    return true;
  };

  return (
    <div style={style}>
      <StyledAsyncPaginate
        menuShouldScrollIntoView={true}
        captureMenuScroll={false}
        menuShouldBlockScroll={false}
        filterOption={filterConfig}
        debounceTimeout={500}
        key={`product-select-${buildingId}`}
        name="product"
        className="react-select-container"
        classNamePrefix="react-select"
        value={value}
        loadOptionsOnMenuOpen={true}
        shouldLoadMore={shouldLoadMore}
        loadOptions={loadOptions}
        isDisabled={buildingId < 1 || disabled}
        onChange={handleOnChange}
        placeholder={placeholder ? placeholder : t('Select Resident')}
        styles={inputSelectCustomStyles({ height: '56px' })}
        components={{
          IndicatorSeparator: () => null,
          Option: IconOption
        }}
        error={error}
        additional={{
          page: 1
        }}
      />
    </div>
  );
};

export default memo(UserAsyncDropDown);
