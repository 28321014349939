import { CLEAR_DELIVERY, CLEAR_ERROR, UPDATE_DELIVERY, CREATE_DELIVERY, UPLOAD_DELIVERY_IMAGE } from "../constants";
import { requestSuccess, requestPending, requestFail } from "../../utils/status";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  deliveriesList: [],
  status: '',
  delivery: {},
  error: null,
  pagination: {
    currentPage: 1,
    perPage: null,
    total: null
  },
};

export default createReducer(initialState, {
  [CLEAR_DELIVERY]: (state) => ({
    ...state,
    delivery: {}
  }),

  [CLEAR_ERROR]: (state) => ({
    ...state,
    error: null
  }),

  [requestSuccess(UPDATE_DELIVERY)]: (state) => ({
    ...state,
    status: requestSuccess(UPDATE_DELIVERY)
  }),
  
  [requestPending(UPDATE_DELIVERY)]: (state) => ({
    ...state,
    status: requestPending(UPDATE_DELIVERY)
  }),

  [requestFail(UPDATE_DELIVERY)]: (state) => ({
    ...state,
    status: requestFail(UPDATE_DELIVERY)
  }),

  [requestSuccess(CREATE_DELIVERY)]: (state) => ({
    ...state,
    error: '',
    status: requestSuccess(CREATE_DELIVERY)
  }),
  
  [requestPending(CREATE_DELIVERY)]: (state) => ({
    ...state,
    status: requestPending(CREATE_DELIVERY)
  }),

  [requestFail(CREATE_DELIVERY)]: (state, { payload }) => ({
    ...state,
    error: payload?.data.message,
    status: requestFail(CREATE_DELIVERY)
  }),

  [requestSuccess(UPLOAD_DELIVERY_IMAGE)]: (state) => ({
    ...state,
    error: '',
    status: requestSuccess(UPLOAD_DELIVERY_IMAGE)
  }),

  [requestPending(UPLOAD_DELIVERY_IMAGE)]: (state) => ({
    ...state,
    status: requestPending(UPLOAD_DELIVERY_IMAGE)
  }),

  [requestFail(UPLOAD_DELIVERY_IMAGE)]: (state, { payload }) => ({
    ...state,
    error: payload?.data,
    status: requestFail(UPLOAD_DELIVERY_IMAGE)
  })
});
