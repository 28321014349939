import { takeLatest } from 'redux-saga/effects';
import {
  GET_SERVICE,
  GET_SERVICES,
  ADD_COMMENT,
  SERVICE_CATEGORY,
  UPDATE_SERVICE,
  CREATE_SERVICE,
  GET_SERVICE_COMMENTS,
  SEARCH_REQUEST,
  GET_AMENITY_TYPES,
  COMPLETE_SERVICE,
  SYNC_YARDI_REQUESTS,
  GET_SERVICE_CATEGORY,
  GET_SERVICE_CATEGORIES
} from '../constants';
import apiCall, { HTTP_METHOD } from '../../utils/apiCall';
import { RequestStatus, convertServiceStatusToId } from '../../views/services/constant';

const getCategories = apiCall({
  type: SERVICE_CATEGORY,
  method: 'get',
  path: '/metadata?name=service-categories'
});

const getServiceRequestCategories = apiCall({
  type: GET_SERVICE_CATEGORIES,
  method: 'get',
  path: ({ orgId }) => {
    return `/orgs/${orgId}/service-request-categories`;
  }
});

const getAmenityTypes = apiCall({
  type: GET_AMENITY_TYPES,
  method: 'get',
  path: '/metadata?name=amenity-types'
});

const createService = apiCall({
  type: CREATE_SERVICE,
  method: 'post',
  path: '/requests'
});

const getServices = apiCall({
  type: GET_SERVICES,
  method: 'get',
  path: ({ id, page = 1, orgId, status = convertServiceStatusToId(RequestStatus.OPEN) }) => {
    let url = `/requests?building=${id}&page=${page}&status=${status}`;
    if (orgId) {
      url += `&org=${orgId}`;
    }
    return url;
  }
});

const completeService = apiCall({
  TYPE: COMPLETE_SERVICE,
  method: 'post',
  path: ({ id }) => `/requests/${id}/complete`
});

const updateService = apiCall({
  type: UPDATE_SERVICE,
  method: 'put',
  path: ({ id }) => `/requests/${id}`
});

const getService = apiCall({
  type: GET_SERVICE,
  method: 'get',
  path: ({ id }) => `/requests/${id}`
});

const addComment = apiCall({
  type: ADD_COMMENT,
  method: 'post',
  path: ({ id }) => `/requests/${id}/comment`
});

const getServiceComments = apiCall({
  type: GET_SERVICE_COMMENTS,
  method: 'get',
  path: ({ id, commentId }) => `/requests/${id}/comments?last=${commentId}`
});

const searchRequests = apiCall({
  type: SEARCH_REQUEST,
  method: 'get',
  newUrl: true,
  path: ({
    query,
    page = 1,
    perpage = 20,
    search_col = null,
    building = 0,
    org = 0,
    status = convertServiceStatusToId(RequestStatus.OPEN)
  }) => {
    let url = `/search?entity=request&q=${query}&page=${page}&per_page=${perpage}&status=${status}`;
    if (building > 0) {
      url += `&building=${building}`;
    }
    if (!building && org > 0) {
      url += `&org=${org}`;
    }
    if (search_col) {
      url += `&search_col=${search_col}`;
    }
    return url;
  }
});

const syncYardiRequests = apiCall({
  type: SYNC_YARDI_REQUESTS,
  method: HTTP_METHOD.post,
  path: '/proc/sync-yardi-service-requests'
});

export default function* servicesSaga() {
  yield takeLatest(GET_SERVICES, getServices);
  yield takeLatest(SEARCH_REQUEST, searchRequests);
  yield takeLatest(GET_SERVICE, getService);
  yield takeLatest(ADD_COMMENT, addComment);
  yield takeLatest(SERVICE_CATEGORY, getCategories);
  yield takeLatest(GET_SERVICE_CATEGORIES, getServiceRequestCategories);
  yield takeLatest(GET_AMENITY_TYPES, getAmenityTypes);

  yield takeLatest(COMPLETE_SERVICE, completeService);
  yield takeLatest(UPDATE_SERVICE, updateService);
  yield takeLatest(CREATE_SERVICE, createService);
  yield takeLatest(GET_SERVICE_COMMENTS, getServiceComments);
  yield takeLatest(SYNC_YARDI_REQUESTS, syncYardiRequests);
}
