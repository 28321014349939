import { createReducer } from "@reduxjs/toolkit";
import {
  GET_COMMON_ACCESS,
  CLEAR_ERROR,
  SET_COMMON_ACCESS,
  GET_BUILDING_CAMERAS
} from "../constants";
import {
  requestFail,
  requestPending,
  requestSuccess
} from "../../utils/status";

const initialState = {
  status: "",
  error: null,
  data: []
  // id: null,
  // display_name: "",
  // type_id: null,
  // camera_id: null,
  // video_url: '',
  // video_app: '',
  // video_stream: ''
};

export default createReducer(initialState, {
  [CLEAR_ERROR]: (state) => ({
    ...state,
    error: null
  }),

  [requestSuccess(GET_COMMON_ACCESS)]: (state, { payload }) => {
    return {
      ...state,
      status: requestSuccess(GET_COMMON_ACCESS),
      data: payload,
      error: null
    };
  },

  [requestPending(GET_COMMON_ACCESS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_COMMON_ACCESS)
  }),

  [requestFail(GET_COMMON_ACCESS)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_COMMON_ACCESS),
    error: payload.data,
    data: []
  }),

  [SET_COMMON_ACCESS]: (state, { payload }) => ({
    ...state,
    data: payload
  }),

  [requestSuccess(GET_BUILDING_CAMERAS)]: (state, { payload }) => {
    return {
      ...state,
      status: requestSuccess(GET_BUILDING_CAMERAS),
      data: payload.map(camera => ({
        id: camera?.id,
        display_name: camera?.name,
        photo: "",
        type_id: 2,
        camera_id: camera?.id,
        video_url: camera?.webrtc_url,
        video_app: camera?.webrtc_app,
        video_stream: camera?.stream_name
      })),
      error: null
    };
  },

  [requestPending(GET_BUILDING_CAMERAS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_BUILDING_CAMERAS)
  }),

  [requestFail(GET_BUILDING_CAMERAS)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_BUILDING_CAMERAS),
    error: payload.data,
    data: []
  }),
});
