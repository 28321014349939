import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  InputSeparator,
  SettingsModalBoldText,
  SettingsModalInput
} from '../../settings/SettingsModals';
import { closeActiveModal, openModal } from '../../../helper/modals';
import {
  DATA_CENTERS,
  LOCK_PROVIDERS,
  ORGANIZATION_MODES,
  ORGANIZATION_PROVIDERS
} from '../../../utils/mappings';
import * as Yup from 'yup';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { nameRegx, orgNameRegx } from '../../../helper/Regx';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '../../../components/ErrorMessage';
import { updateOrganizationData } from '../../../redux/actions/addOrganization';
import { ErrorText } from '../../../components/utils/AvatarCropper';
import { Select, SelectItem } from '../../../components/ui/Select';
import {
  ModalButtons,
  ModalHeader,
  StyledModal,
  StyledModalBody
} from '../../../components/Modals';

const AddOrganizationModalStep1 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { modal } = useSelector((state) => state.headerModal);
  const addOrganization = useSelector((state) => state.addOrganization);

  const schema = Yup.object().shape({
    name: Yup.string()
      .required(t('Organization Name is required'))
      .matches(orgNameRegx, t('Incorrect Format, only Alphabets allowed'))
      .max(50, t('Not more than 50 characters')),
    email: Yup.string().email(t('Invalid email address')).required(t('Email is required')),
    contact: Yup.string()
      .required(t('Contact is required'))
      .matches(nameRegx, t('Only alphabets allowed'))
      .max(50, t('Not more than 50 characters')),
    dataCenter: Yup.mixed()
      .required(t('Data Center is required'))
      .oneOf(DATA_CENTERS.map((v) => v.id)),
    lockProvider: Yup.number()
      .required(t('Lock Provider is required'))
      .oneOf(LOCK_PROVIDERS.map((v) => v.id)),
    yaleAccessToken: Yup.string()
      .when('lockProvider', {
        is: 1,
        then: Yup.string().required(t('Yale Access Token is required')),
        otherwise: Yup.string().nullable(),
      }),
    yaleRefreshToken: Yup.string()
      .when('lockProvider', {
        is: 1,
        then: Yup.string().required(t('Yale Refresh Token is required')),
        otherwise: Yup.string().nullable(),
      }),
    residentProvider: Yup.mixed()
      .required(t('Resident Provider is required'))
      .oneOf(ORGANIZATION_PROVIDERS.map((v) => v.id)),
    residentMode: Yup.mixed()
      .required(t('Resident Mode is required'))
      .oneOf(ORGANIZATION_MODES.map((v) => v.id)),

    propertyProvider: Yup.mixed()
      .required(t('Property Provider is required'))
      .oneOf(ORGANIZATION_PROVIDERS.map((v) => v.id)),
    propertyMode: Yup.mixed()
      .required(t('Property Mode is required'))
      .oneOf(ORGANIZATION_MODES.map((v) => v.id)),

    serviceProvider: Yup.mixed()
      .required(t('Service Provider is required'))
      .oneOf(ORGANIZATION_PROVIDERS.map((v) => v.id)),
    serviceMode: Yup.mixed()
      .required(t('Service Mode is required'))
      .oneOf(ORGANIZATION_MODES.map((v) => v.id))
  });


  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      name: addOrganization.name,
      email: addOrganization.email,
      contact: addOrganization.contact,
      dataCenter: addOrganization.dataCenter,
      residentProvider: addOrganization.residentProvider,
      residentMode: addOrganization.residentMode,
      propertyProvider: addOrganization.propertyProvider,
      propertyMode: addOrganization.propertyMode,
      serviceProvider: addOrganization.serviceProvider,
      serviceMode: addOrganization.serviceMode,
      lockProvider: addOrganization.options.lockProvider,
    }
  });
  const residentProvider = useWatch({
    control,
    name: 'residentProvider'
  });
  const propertyProvider = useWatch({
    control,
    name: 'propertyProvider'
  });
  const serviceProvider = useWatch({
    control,
    name: 'serviceProvider'
  });
  const lockProvider = useWatch({
    control,
    name: 'lockProvider'
  })

  const onSubmit = (data) => {
    dispatch(
      updateOrganizationData({
        name: data.name,
        email: data.email,
        contact: data.contact,
        dataCenter: data.dataCenter,
        residentProvider: data.residentProvider,
        residentMode: data.residentMode,
        propertyProvider: data.propertyProvider,
        propertyMode: data.propertyMode,
        serviceProvider: data.serviceProvider,
        serviceMode: data.serviceMode,
        options: {
          ...addOrganization.options,
          lockProvider: data.lockProvider,
        },
        providerAccessToken: data.yaleAccessToken,
        providerRefreshToken: data.yaleRefreshToken,
      })
    );
    openModal('AddOrganizationModalStep2');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledModal
        show={modal === 'AddOrganizationModalStep1'}
        closeOnBackdrop={false}
        onClose={closeActiveModal}>
        <ModalHeader title={t('Add Organization')} />

        <StyledModalBody>
          <InputSeparator>
            <SettingsModalBoldText>{t('Name')}</SettingsModalBoldText>
            <Controller
              defaultValue={addOrganization.name}
              control={control}
              name="name"
              render={({ field: { name, ...rest } }) => (
                <>
                  <SettingsModalInput {...rest} error={!!errors.name} placeholder={t('Name')} />
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </>
              )}
            />
          </InputSeparator>

          <InputSeparator>
            <SettingsModalBoldText>{t('Email')}</SettingsModalBoldText>
            <Controller
              control={control}
              defaultValue={addOrganization.email}
              name="email"
              render={({ field: { name, ...rest } }) => (
                <>
                  <SettingsModalInput {...rest} error={!!errors.email} placeholder={t('Email')} />
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </>
              )}
            />
          </InputSeparator>

          <InputSeparator>
            <SettingsModalBoldText>{t('Contact')}</SettingsModalBoldText>
            <Controller
              control={control}
              defaultValue={addOrganization.contact}
              name="contact"
              render={({ field: { name, ...rest } }) => (
                <>
                  <SettingsModalInput
                    {...rest}
                    name={name}
                    error={!!errors.contact}
                    placeholder={t('Contact')}
                  />
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </>
              )}
            />
          </InputSeparator>

          <InputSeparator>
            <SettingsModalBoldText>{t('Data Center')}</SettingsModalBoldText>
            <Controller
              control={control}
              defaultValue={addOrganization.dataCenter}
              name="dataCenter"
              render={({ field: { onChange, value, name } }) => (
                <>
                  <Select
                    onChange={onChange}
                    defaultValue={value}
                    value={value}
                    placeholder={t('Data Center')}
                    error={!!errors.dataCenter}>
                    {DATA_CENTERS.map((provider) => (
                      <SelectItem key={provider.id} value={provider.id}>
                        {provider.value}
                      </SelectItem>
                    ))}
                  </Select>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </>
              )}
            />
          </InputSeparator>

          <InputSeparator>
            <SettingsModalBoldText>{t('Lock Provider')}</SettingsModalBoldText>
            <Controller
              control={control}
              defaultValue={addOrganization.options.lockProvider}
              name="lockProvider"
              render={({ field: { onChange, value, name } }) => (
                <>
                  <Select
                    onChange={onChange}
                    defaultValue={value}
                    placeholder={t('Please Select')}
                    error={!!errors.lockProvider}>
                    {LOCK_PROVIDERS.map((provider) => (
                      <SelectItem key={provider.id} value={provider.id}>
                        {provider.value}
                      </SelectItem>
                    ))}
                  </Select>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </>
              )}
            />
          </InputSeparator>

          {
            lockProvider === 1 && (
              <>
                <InputSeparator>
                  <SettingsModalBoldText>{t('Yale Access Token')}</SettingsModalBoldText>
                  <Controller
                    control={control}
                    defaultValue={addOrganization.yaleAccessToken}
                    name="yaleAccessToken"
                    render={({ field: { name, ...rest } }) => (
                      <>
                        <SettingsModalInput
                          {...rest}
                          name={name}
                          error={!!errors.yaleAccessToken}
                          placeholder={t('Yale Access Token')}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={name}
                          render={({ message }) => <ErrorText>{message}</ErrorText>}
                        />
                      </>
                    )}
                  />
                </InputSeparator>
                <InputSeparator>
                  <SettingsModalBoldText>{t('Yale Refresh Token')}</SettingsModalBoldText>
                  <Controller
                    control={control}
                    defaultValue={addOrganization.yaleRefreshToken}
                    name="yaleRefreshToken"
                    render={({ field: { name, ...rest } }) => (
                      <>
                        <SettingsModalInput
                          {...rest}
                          name={name}
                          error={!!errors.yaleRefreshToken}
                          placeholder={t('Yale Refresh Token')}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={name}
                          render={({ message }) => <ErrorText>{message}</ErrorText>}
                        />
                      </>
                    )}
                  />
                </InputSeparator>
              </>
            )
          }

          <InputSeparator>
            <SettingsModalBoldText>{t('Resident Provider')}</SettingsModalBoldText>
            <Controller
              control={control}
              defaultValue={addOrganization.residentProvider}
              name="residentProvider"
              render={({ field: { onChange, value, name } }) => (
                <>
                  <Select
                    onChange={(e) => {
                      setValue('residentMode', 0);
                      onChange(e);
                    }}
                    defaultValue={value}
                    placeholder={t('Please Select')}
                    error={!!errors.residentProvider}>
                    {ORGANIZATION_PROVIDERS.map((provider) => (
                      <SelectItem key={provider.id} value={provider.id}>
                        {provider.value}
                      </SelectItem>
                    ))}
                  </Select>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </>
              )}
            />
          </InputSeparator>

          <InputSeparator>
            <SettingsModalBoldText>{t('Resident Mode')}</SettingsModalBoldText>
            <Controller
              control={control}
              defaultValue={addOrganization.residentMode}
              name="residentMode"
              render={({ field: { onChange, value, name } }) => (
                <>
                  <Select
                    onChange={onChange}
                    disabled={!residentProvider}
                    value={value}
                    placeholder={t('Please Select')}
                    error={!!errors.residentMode}>
                    {ORGANIZATION_MODES.map((provider) => (
                      <SelectItem key={provider.id} value={provider.id}>
                        {provider.value}
                      </SelectItem>
                    ))}
                  </Select>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </>
              )}
            />
          </InputSeparator>

          <InputSeparator>
            <SettingsModalBoldText>{t('Property Provider')}</SettingsModalBoldText>
            <Controller
              control={control}
              defaultValue={addOrganization.propertyProvider}
              name="propertyProvider"
              render={({ field: { onChange, value, name } }) => (
                <>
                  <Select
                    onChange={(e) => {
                      setValue('propertyMode', 0);
                      onChange(e);
                    }}
                    defaultValue={value}
                    value={value}
                    placeholder={t('Please Select')}
                    error={!!errors.propertyProvider}>
                    {ORGANIZATION_PROVIDERS.map((provider) => (
                      <SelectItem key={provider.id} value={provider.id}>
                        {provider.value}
                      </SelectItem>
                    ))}
                  </Select>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </>
              )}
            />
          </InputSeparator>

          <InputSeparator>
            <SettingsModalBoldText>{t('Property Mode')}</SettingsModalBoldText>
            <Controller
              control={control}
              defaultValue={addOrganization.propertyMode}
              name="propertyMode"
              render={({ field: { onChange, value, name } }) => (
                <>
                  <Select
                    onChange={onChange}
                    disabled={!propertyProvider}
                    defaultValue={value}
                    value={value}
                    placeholder={t('Please Select')}
                    error={!!errors.propertyMode}>
                    {ORGANIZATION_MODES.map((provider) => (
                      <SelectItem key={provider.id} value={provider.id}>
                        {provider.value}
                      </SelectItem>
                    ))}
                  </Select>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </>
              )}
            />
          </InputSeparator>

          <InputSeparator>
            <SettingsModalBoldText>{t('Service Provider')}</SettingsModalBoldText>
            <Controller
              control={control}
              defaultValue={addOrganization.propertyProvider}
              name="serviceProvider"
              render={({ field: { onChange, value, name } }) => (
                <>
                  <Select
                    onChange={(e) => {
                      setValue('serviceMode', 0);
                      onChange(e);
                    }}
                    defaultValue={value}
                    value={value}
                    placeholder={t('Please Select')}
                    error={!!errors.serviceProvider}>
                    {ORGANIZATION_PROVIDERS.map((provider) => (
                      <SelectItem key={provider.id} value={provider.id}>
                        {provider.value}
                      </SelectItem>
                    ))}
                  </Select>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </>
              )}
            />
          </InputSeparator>

          <InputSeparator>
            <SettingsModalBoldText>{t('Service Mode')}</SettingsModalBoldText>
            <Controller
              control={control}
              defaultValue={addOrganization.propertyProvider}
              name="serviceMode"
              render={({ field: { onChange, value, name } }) => (
                <>
                  <Select
                    onChange={onChange}
                    disabled={!serviceProvider}
                    defaultValue={value}
                    value={value}
                    placeholder={t('Please Select')}
                    error={!!errors.serviceMode}>
                    {ORGANIZATION_MODES.map((provider) => (
                      <SelectItem key={provider.id} value={provider.id}>
                        {provider.value}
                      </SelectItem>
                    ))}
                  </Select>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </>
              )}
            />
          </InputSeparator>
        </StyledModalBody>
        <ModalButtons
          leftButtonText={t('Cancel')}
          leftButtonClick={closeActiveModal}
          rightButtonText={t('Next')}
        />
      </StyledModal>
    </form>
  );
};

export default AddOrganizationModalStep1;
