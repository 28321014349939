import { call, put } from 'redux-saga/effects';
import { get } from 'lodash-es';
import axios, { baseURL } from './axios';
import axiosnew from './axios-new';
import { VERIFY_ACCOUNT } from '../redux/constants';
import { requestFail, requestPending, requestSuccess } from './status';
import { setCsvUrl } from '../redux/actions/reports';

export const HTTP_METHOD = {
  get: 'get',
  post: 'post',
  put: 'put',
  delete: 'delete'
};

export const GRATAFY_URL = process.env.REACT_APP_GRATAFY_URL;

export default ({
  setCsv = false,
  type,
  method,
  path,
  success,
  isFormData,
  isBlob,
  isFile,
  newUrl,
  action,
  gratafyURL
}) =>
  function* (action) {
    const {
      body,
      params,
      success: successPayload,
      fail: failPayload,
      finally: finallyPayload
    } = action.payload || {};
    const idToken = localStorage.getItem('idToken');

    let header = {};
    let data;

    let pagination = {
      'pagination-current-page': 0,
      'pagination-from': 1,
      'pagination-per-page': 0,
      'pagination-to': 0,
      'pagination-total': 0
    };

    if (!isFormData) {
      header = {
        'Content-Type': 'application/json'
      };
    }

    if (isFile) {
      header = {
        'Content-Type': 'multipart/form-data'
      };
    }

    header['Access-Control-Allow-Origin'] = '*';

    header['Authorization'] = `Bearer ${idToken}`;

    try {
      yield put({
        type: requestPending(type)
      });

      const pathValue = gratafyURL
        ? `${gratafyURL}${path(action.payload)}`
        : typeof path === 'function'
        ? path(action.payload)
        : path;
      let options = {
        url: pathValue,
        method: method,
        headers: header,
        data: body,
        params
      };

      if (gratafyURL && gratafyURL.includes('cloudfunctions.net')) {
        options.headers = {};
      }

      if (isBlob) {
        options.responseType = 'blob';
      }
      let res;
      res = yield call(axios.request, options);

      if (setCsv === true) {
        let csvUrl = `${
          typeof path === 'function' ? path({ ...action.payload, page: 1, per_page: 999999 }) : path
        }`;
        let newUrl = baseURL + csvUrl;

        yield put(
          setCsvUrl({
            payload: newUrl
          })
        );
      }

      if (path === '/login' && res.data.error_code > 0) {
        if (res.data.error_code === 101) {
          yield put({
            type: VERIFY_ACCOUNT,
            payload: { page: '3', ...res.data }
          });
        } else if (res.data.error_code === 100) {
          yield put({
            type: VERIFY_ACCOUNT,
            payload: { page: '0', ...res.data }
          });
        } else {
          yield put({
            type: VERIFY_ACCOUNT,
            payload: { page: '4', ...res.data }
          });
        }
      } else {
        if (res?.headers?.['pagination-current-page']) {
          pagination['pagination-current-page'] = res?.headers['pagination-current-page'];
          pagination['pagination-from'] = res?.headers['pagination-from'];
          pagination['pagination-per-page'] = res?.headers['pagination-per-page'];
          pagination['pagination-to'] = res?.headers['pagination-to'];
          pagination['pagination-total'] = res?.headers['pagination-total'];

          data = {
            data: res.data,
            pagination: pagination
          };
        } else {
          data = res.data;
        }
        yield put({
          type: requestSuccess(type),
          payload: data
        });

        successPayload && successPayload(res);
        success && success(res, action);
      }
    } catch (err) {
      const errRes = get(err, 'response', err);

      yield put({
        type: requestFail(type),
        payload: errRes
      });

      failPayload && failPayload(errRes);
    } finally {
      finallyPayload && finallyPayload();
    }
  };
