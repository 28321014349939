import { createAction } from '@reduxjs/toolkit';
import {
  CREATE_LICENSE_PLATE,
  DELETE_LICENSE_PLATE,
  GET_LICENSE_PLATE,
  UPDATE_LICENSE_PLATE
} from '../constants';

export const getLicensePlate = createAction(GET_LICENSE_PLATE);
export const createLicensePlate = createAction(CREATE_LICENSE_PLATE);
export const updateLicensePlate = createAction(UPDATE_LICENSE_PLATE);
export const deleteLicensePlate = createAction(DELETE_LICENSE_PLATE);

export default {
  getLicensePlate,
  createLicensePlate,
  updateLicensePlate,
  deleteLicensePlate
};
