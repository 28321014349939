import { takeLatest } from 'redux-saga/effects';
import apiCall, { GRATAFY_URL } from '../../utils/apiCall';
import {
  GET_THERMOSTAT,
  GET_THERMOSTATS,
  UPDATE_ECOBEE_THERMOSTAT,
  UPDATE_THERMOSTAT
} from '../constants';

const searchThermostat = apiCall({
  type: GET_THERMOSTATS,
  method: 'GET',
  path: ({ orgID, buildingID, unitID, all }) => {
    const searchParams = new URLSearchParams();
    searchParams.append('o', orgID);
    searchParams.append('b', buildingID);

    if (unitID) {
      searchParams.append('u', unitID);
    }

    if (all) {
      searchParams.append('all', 'true');
    }

    return `/admin/device/thermostats?${searchParams.toString()}`;
  },
  gratafyURL: GRATAFY_URL
});

const updateThermostat = apiCall({
  type: UPDATE_THERMOSTAT,
  method: 'POST',
  path: () => `/admin/device/thermostats/temperature`,
  gratafyURL: GRATAFY_URL
});

const updateEcobeeThermostat = apiCall({
  type: UPDATE_ECOBEE_THERMOSTAT,
  method: 'POST',
  path: ({ ecobeeID }) => `/admin/device/thermostats/${ecobeeID}/hvacMode`,
  gratafyURL: GRATAFY_URL
});

const getThermostat = apiCall({
  type: GET_THERMOSTAT,
  method: 'GET',
  path: ({ deviceId }) => `/admin/device/thermostats/${deviceId}/`,
  gratafyURL: GRATAFY_URL
});

export default function* thermostatSaga() {
  yield takeLatest(GET_THERMOSTAT, getThermostat);
  yield takeLatest(GET_THERMOSTATS, searchThermostat);
  yield takeLatest(UPDATE_THERMOSTAT, updateThermostat);
  yield takeLatest(UPDATE_ECOBEE_THERMOSTAT, updateEcobeeThermostat);
}
