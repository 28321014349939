import React, { useMemo, useRef } from 'react';
import { CCol, CFormGroup, CModalFooter, CRow, CTextarea } from '@coreui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Tree } from 'antd';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { businessId, defaultImgCDN } from '../../helper';
import { changeOpenedModal } from '../../redux/actions/headerModal';
import { addOffer, clearOfferError, getOffers } from '../../redux/actions/offers';
import { addAvatar, setUserSearch } from '../../redux/actions/user';
import { OFFER_CATEGORY_MAPPING } from '../../utils/mappings';
import { useCallback } from 'react';
import { FeatureSwitch } from './styles';
import { setToast } from '../../redux/actions/window';
import LoadingAvatar from '../../components/LoadingAvatar';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { LOADING_BLUE } from '../../utils/colorCode';
import locale from 'antd/es/date-picker/locale/fr_FR';
import localeEn from 'antd/es/date-picker/locale/en_US';
import i18next from 'i18next';
import AvatarCropper, { ErrorText } from '../../components/utils/AvatarCropper';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import pica from 'pica';
import { offerImageValidations, MediaKeys } from './utils';
import { ModalHeader, StyledModal, StyledModalBody } from '../../components/Modals';
import {
  InputSeparator,
  SettingsModalBoldText,
  SettingsModalInput
} from '../settings/SettingsModals';
import { CustomDatePicker } from '../../components/CustomSearchBar';
import { Select, SelectItem } from '../../components/ui/Select';
import { StyledTextArea, TextArea } from '../../components/ui/TextArea';
import { Button } from '../../components/ui/Button';
import { StyledTree } from '../../components/ui/StyledTree';
import { searchTable, setSearchPagination, setSearchState } from '../../redux/actions/search';
import { SEARCH_TABLES } from '../../helper/constants';

const { DirectoryTree } = Tree;

const loadingIcon = <LoadingOutlined spin />;

const AddOffer = () => {
  const { t } = useTranslation();
  const regMatch =
    /^https:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

  const validationSchema = Yup.object().shape({
    building_ids: Yup.array()
      .of(Yup.number().min(1))
      .typeError(t('At least one building is required'))
      .test(
        'validBuildings',
        t('At least one building is required'),
        (value, context) => value?.length > 0
      ),
    category_id: Yup.string()
      .required(t('Category is required'))
      .test('is-required', t('Category is required'), (value, context) => {
        if (value <= 0) return false;
        return true;
      }),
    title: Yup.string()
      .max(100, t('Not more than 100 characters'))
      .required(t('Title is required')),

    content: Yup.string()
      .max(1000, t('Not more than 1000 characters'))
      .required(t('Content is required')),

    additional_content: Yup.string()
      .when('is_featured', {
        is: (val) => val === 'true',
        then: (schema) => schema.notRequired(), //schema.required(t("Additional media content is required")),
        otherwise: (schema) => schema.notRequired()
      })
      .max(500, t('Not more than 500 characters')),
    // TEMP: uncomment block start
    // banner_title: Yup.string()
    //   .max(42, t("Not more than 42 chracters"))
    //   .when('is_featured', {
    //     is: 'true',
    //     then: (schema) => schema.required('Card title is required'),
    //   }),
    // banner_description: Yup.string()
    //   .max(130, t("Not more than 130 characters"))
    //   .when('is_featured', {
    //     is: 'true',
    //     then: (schema) => schema.required('Card description is required'),
    //   }),
    // banner_button_text: Yup.string()
    //   .max(20, t("Not more than 20 characters"))
    //   .when('is_featured', {
    //     is: 'true',
    //     then: (schema) => schema.required('Button text is required'),
    //   }),
    // banner_background_color: Yup.string()
    //   .when('is_featured', {
    //     is: 'true',
    //     then: (schema) => schema.required('Card background color is required'),
    //   }),
    // TEMP: uncomment block end
    expire_date: Yup.string().required(t('Required')).nullable(),
    offered_by: Yup.string()
      .max(100, t('Not more than 100 characters'))
      .required(t('Offered by is required')),
    redeem_url: Yup.string().matches(regMatch, t('HTTPS required')),

    redeem_text: Yup.string().max(100, t('Not more than 100 characters')),
    is_featured: Yup.string().required(t('Required'))
  });
  const dispatch = useDispatch();
  const { cachedOrgBuildings, loadingCachedBuildings } = useSelector((state) => state.building);

  const searchState = useSelector((state) => state.search);
  const { Organizations, organization } = useSelector((state) => state.Organization);

  const { error } = useSelector((state) => state.offers);
  const { modal } = useSelector((state) => state.headerModal);

  const [checkedKeys, setCheckedKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const [myTree, setMyTree] = useState([]);
  const { globalOrgId } = useSelector((state) => state.Organization);

  const imgInitState = {
    image: null,
    name: ''
  };

  const [mediaAvatar, setMediaAvatar] = useState(imgInitState);
  const [additionalMediaAvatar, setAdditionalMediaAvatar] = useState(imgInitState);
  const [logoAvatar, setLogoAvatar] = useState(imgInitState);
  const [cardLogo, setCardLogo] = useState(imgInitState);

  const mediaAvatarRef = useRef(null);
  const additionalMediaAvatarRef = useRef(null);
  const logoAvatarRef = useRef(null);
  const cardLogoRef = useRef(null);

  const createInitialTree = useCallback(() => {
    const object = {
      title: t('All organizations'),
      key: 'All organizations',
      checkable: 'false'
    };

    let filteredOrgs = Organizations.map((org) => {
      const orgBuildings = cachedOrgBuildings[org.id.toString()];
      let children = [];
      if (orgBuildings && orgBuildings.length > 0) {
        children = orgBuildings.map((building) => ({
          title: building.name,
          key: building.id,
          isLeaf: true
        }));
      }
      const noBuildings = children.length === 0;
      if (noBuildings) {
        children.push({
          title: 'No building in this organization',
          key: 'no-building' + org.id,
          disabled: true,
          isLeaf: true,
          checkable: false
        });
      }
      return {
        title: org.name,
        key: `org-${org.id}`,
        children: children,
        disableCheckbox: noBuildings && !orgBuildings
      };
    });

    object.children = [...filteredOrgs];

    return object;
  }, [Organizations, cachedOrgBuildings]);

  useEffect(() => {
    if (!loadingCachedBuildings.isLoading) {
      const val = createInitialTree();

      if (val) {
        setMyTree([val]);
      }
    }
  }, [cachedOrgBuildings, loadingCachedBuildings, createInitialTree, dispatch]);

  let initialValues = {
    offer_tree: [],
    building_ids: [],
    category_id: '',
    title: '',
    content: '',
    additional_content: '',
    expire_date: '',
    offered_by: '',
    redeem_url: '',
    redeem_text: '',
    is_featured: false,
    business_id: '',
    banner_title: '',
    banner_description: '',
    banner_button_text: 'Learn More',
    banner_background_color: '#ffffff'
  };

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    formState: { errors, isDirty, isValid, isSubmitted }
  } = useForm({
    defaultValues: {
      media: '',
      logo: '',
      additional_media: '',
      banner_button_text: 'Learn More'
    },
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues
  });

  const submitAddOffer = (values, filenames) => {
    const expire_date = moment(values.expire_date).utc().toISOString();

    let business_id = parseInt(businessId);

    let newValues = {
      ...values,
      is_featured: values.is_featured === 'true' ? true : false,
      category_id: Number(values.category_id)
    };

    const featuredFields = {
      banner_background_color: values.banner_background_color,
      banner_button_text: values.banner_button_text,
      banner_description: values.banner_description,
      banner_logo: filenames[MediaKeys.BANNER_LOGO] || defaultImgCDN,
      banner_title: values.banner_title
    };

    const offerData = {
      ...newValues,
      expire_date,
      media: filenames[MediaKeys.MEDIA] || defaultImgCDN,
      additional_media: filenames[MediaKeys.EXTRA_MEDIA] || defaultImgCDN,
      business_id,
      logo: filenames[MediaKeys.LOGO] || defaultImgCDN,
      website: 'http://me.com',
      availability: 'always',
      location: 'ontario',
      lat: 0.0,
      long: 0.0,
      coupon_code: 'X100',
      type: 1,
      method: 2,
      data: featuredFields,
      active: true,
      is_viewable_by_residents: true
    };

    dispatch(
      addOffer({
        body: offerData,
        success: () => {
          dispatch(
            getOffers({
              page: 1,
              // buildingId: parseInt(values.building_id),
              orgId: globalOrgId
            })
          );
          dispatch(
            searchTable({
              entity: SEARCH_TABLES.OFFERS,
              orgID: organization.id,
              body: {
                search: searchState.search,
                minCreationDate: searchState.minCreationDate,
                maxCreationDate: searchState.maxCreationDate,
                filters: searchState.filters,
                ordering: searchState.ordering,
                pagination: searchState.pagination
              },
              success: (res) => {
                dispatch(
                  setSearchState({
                    currentData: res.data.data ?? [],
                    currentPaginationData: res.data.pagination
                  })
                );

                if (!res.data.data) {
                  dispatch(setUserSearch({ page: 1 }));
                  dispatch(
                    setSearchPagination({
                      page: 1,
                      limit: 20
                    })
                  );
                }
              }
            })
          );
          onClose();
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Deal has been successfully created!')
            })
          );
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Deal creation failed!'),
              modal: 'errorToast'
            })
          );
        }
      })
    );
  };

  const onClose = () => {
    setLogoAvatar(imgInitState);
    setAdditionalMediaAvatar(imgInitState);
    setMediaAvatar(imgInitState);
    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );
    dispatch(clearOfferError());
  };

  const getUpdatedMediaDetails = useCallback(
    (type) => {
      const refs = {
        [MediaKeys.LOGO]: logoAvatarRef,
        [MediaKeys.MEDIA]: mediaAvatarRef,
        [MediaKeys.EXTRA_MEDIA]: additionalMediaAvatarRef,
        [MediaKeys.BANNER_LOGO]: cardLogoRef
      };

      const refsAvatars = {
        [MediaKeys.LOGO]: logoAvatar,
        [MediaKeys.MEDIA]: mediaAvatar,
        [MediaKeys.EXTRA_MEDIA]: additionalMediaAvatar,
        [MediaKeys.BANNER_LOGO]: cardLogo
      };

      return {
        ref: refs[type],
        refAvatar: refsAvatars[type]
      };
    },
    [
      mediaAvatarRef,
      logoAvatarRef,
      additionalMediaAvatarRef,
      cardLogoRef,
      mediaAvatar,
      logoAvatar,
      additionalMediaAvatar,
      cardLogo
    ]
  );

  const dispatchImage = (type, imageBlob, name, values, filenames, callbacks) => {
    const imgData = new FormData();
    imgData.append('image', imageBlob, name);
    dispatch(
      addAvatar({
        body: imgData,
        success: (res) => {
          // execute next item in callbacks flow
          filenames[type] = res.data.filename;
          callbacks[0](values, filenames, callbacks.slice(1));
        },
        fails: () => {
          setLoading(false);
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Avatar update failed!'),
              modal: 'errorToast'
            })
          );
        }
      })
    );
  };
  // type is either 'media' or 'logo' or 'additional_media'
  const submitAvatar = (type) => (values, filenames, callbacks) => {
    const { ref, refAvatar } = getUpdatedMediaDetails(type);

    if (ref.current) {
      // send cropped canvas
      const canvas = ref.current.getImage();
      const offScreenCanvas = document.createElement('canvas');
      offScreenCanvas.width = canvas.width;
      offScreenCanvas.height = canvas.height;

      pica()
        .resize(canvas, offScreenCanvas)
        .then((img) =>
          img.toBlob((blob) => {
            dispatchImage(type, blob, refAvatar.name, values, filenames, callbacks);
          })
        );
    } else {
      dispatchImage(type, refAvatar.image, refAvatar.name, values, filenames, callbacks);
    }
  };

  const onSubmit = (values) => {
    if (Boolean(errors?.media)) return;

    setLoading(true);
    const filenames = {
      [MediaKeys.LOGO]: null,
      [MediaKeys.MEDIA]: null,
      // TEMP: uncomment next line
      // [MediaKeys.BANNER_LOGO]: null
      // TEMP: remove next line
      [MediaKeys.EXTRA_MEDIA]: null
    };
    const submitMediaAvatar = submitAvatar(MediaKeys.MEDIA);
    // TEMP: remove next line
    const submitAdditionalMedia = submitAvatar(MediaKeys.EXTRA_MEDIA);
    // TEMP: uncomment next line
    // const submitBannerLogo = submitAvatar(MediaKeys.BANNER_LOGO);
    const submitLogoAvatar = submitAvatar(MediaKeys.LOGO);
    // callbacks execution flow
    const callbacks = [];
    if (mediaAvatar.image) {
      callbacks.push(submitMediaAvatar);
    }
    // TEMP: remove block start
    if (additionalMediaAvatar.image) {
      callbacks.push(submitAdditionalMedia);
    }
    // TEMP: remove block end
    // TEMP: uncomment block start
    // if (cardLogo.image) {
    //   callbacks.push(submitBannerLogo);
    // }
    // TEMP: uncomment block end
    if (logoAvatarRef.current && logoAvatar.image) {
      callbacks.push(submitLogoAvatar);
    }
    // minimum one call in callbacks
    callbacks.push(submitAddOffer);

    callbacks[0](values, filenames, callbacks.slice(1));
  };

  const onCheck = async (checkedKeysValue) => {
    setCheckedKeys(checkedKeysValue);
    let newIds = [...new Set(checkedKeysValue)];
    let buildingIds = [];

    for (let i = 0; i < newIds.length; i++) {
      if (typeof newIds[i] === 'number') {
        buildingIds.push(newIds[i]);
      }
    }

    setValue('building_ids', buildingIds, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    });
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  };

  const isMediaInserted = (type) => Boolean(getUpdatedMediaDetails(type).refAvatar.image);

  useEffect(() => {
    if (
      isDirty &&
      (!isMediaInserted(MediaKeys.LOGO) ||
        !isMediaInserted(MediaKeys.MEDIA) ||
        // TEMP: remove next line
        (watch('is_featured') && !isMediaInserted(MediaKeys.EXTRA_MEDIA)))
      // TEMP: uncomment next line
      // (watch("is_featured") && !isMediaInserted(MediaKeys.BANNER_LOGO)))
    ) {
      setError('media', { type: 'custom', message: 'Missing one of the required media.' });
    } else {
      clearErrors('media');
    }
  }, [
    isDirty,
    isSubmitted,
    watch('is_featured'),
    isMediaInserted(MediaKeys.LOGO),
    isMediaInserted(MediaKeys.MEDIA),
    // TEMP: uncomment next line
    // isMediaInserted(MediaKeys.BANNER_LOGO)
    // TEMP: comment next line
    isMediaInserted(MediaKeys.EXTRA_MEDIA)
  ]);

  const cardLogoUrl = useMemo(() => {
    const { ref, refAvatar } = getUpdatedMediaDetails(MediaKeys.BANNER_LOGO);
    if (cardLogo.image) {
      return URL.createObjectURL(cardLogo.image);
    }
    return null;
  }, [cardLogo]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledModal
        show={modal === 'AddOffer'}
        onClose={() => {
          onClose();
          // resetForm();
        }}
        closeOnBackdrop={false}>
        <ModalHeader title={t('Add new deal')} />

        <StyledModalBody>
          <CFormGroup>
            <SettingsModalBoldText>{t('Media Image')}</SettingsModalBoldText>
            <AvatarCropper
              name={'media-add'}
              avatar={defaultImgCDN}
              newAvatarState={{
                newAvatar: mediaAvatar,
                setNewAvatar: setMediaAvatar
              }}
              editorRef={mediaAvatarRef}
              uploaderLength={150}
              withPreview
              previewLength={100}
              validationSet={offerImageValidations}
              mediaKey={MediaKeys.MEDIA}
              subtitle={'428x144 image for Deal Details banner'}
            />

            {isSubmitted && !isMediaInserted(MediaKeys.MEDIA) && (
              <ErrorText>Media is required</ErrorText>
            )}
          </CFormGroup>

          <InputSeparator>
            <SettingsModalBoldText htmlFor="tree">
              {t('Select orgs/buildings')}
            </SettingsModalBoldText>

            <Controller
              control={control}
              name="building_ids"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState
              }) => {
                return loadingCachedBuildings.isLoading ? (
                  <Spin indicator={loadingIcon} />
                ) : (
                  <StyledTree
                    onChange={onChange}
                    value={value}
                    id="tree"
                    disableTopLevel
                    onCheck={onCheck}
                    checkable={true}
                    checkedKeys={checkedKeys}
                    treeData={myTree}
                    icon={false}
                    checkStrictly={false}
                    multiple={true}
                    error={errors.building_ids ? true : false}
                  />
                );
              }}
            />

            {errors.building_ids && <ErrorText>{errors.building_ids.message}</ErrorText>}
          </InputSeparator>

          <InputSeparator>
            <SettingsModalBoldText htmlFor="Category_ID">{t('Category')}</SettingsModalBoldText>

            <Controller
              control={control}
              name="category_id"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState
              }) => (
                <Select
                  onBlur={onBlur}
                  onChange={onChange}
                  custom
                  // name="category_id"
                  id="category_id"
                  placeholder="Please Select"
                  // valid={!errors.category_id && touched.category_id}
                  error={errors.category_id ? true : false}>
                  <SelectItem value={0}>{t('Select option')}</SelectItem>
                  {OFFER_CATEGORY_MAPPING.map((item) => (
                    <SelectItem value={item.id} key={item.id}>
                      {item.value}
                    </SelectItem>
                  ))}
                </Select>
              )}
            />
            {errors.category_id && <ErrorText>{errors.category_id.message}</ErrorText>}
          </InputSeparator>

          <CRow>
            <CCol>
              <InputSeparator>
                <SettingsModalBoldText htmlFor="title">{t('Title')}</SettingsModalBoldText>

                <Controller
                  control={control}
                  name="title"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState
                  }) => (
                    <TextArea
                      cssOverride={'min-height: 56px; max-height: 200px'}
                      height={'56px'}
                      type="text"
                      id="title"
                      name="title"
                      placeholder={t('Title...')}
                      autoComplete="text"
                      valid={Boolean(!errors.title && watch(name))}
                      error={Boolean(errors.title)}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
                {errors.title && <ErrorText>{errors.title.message}</ErrorText>}
              </InputSeparator>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <InputSeparator>
                <SettingsModalBoldText htmlFor="content">
                  {t('Media Content')}
                </SettingsModalBoldText>

                <Controller
                  control={control}
                  name="content"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState
                  }) => (
                    <TextArea
                      cssOverride={'min-height: 56px; max-height: 350px'}
                      height={'150px'}
                      type="text"
                      id="content"
                      name="content"
                      placeholder={t('Content...')}
                      autoComplete="text"
                      valid={Boolean(!errors.content && watch(name))}
                      rows="5"
                      error={Boolean(errors.content)}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
                {errors.content && <ErrorText>{errors.content.message}</ErrorText>}
              </InputSeparator>
            </CCol>
          </CRow>

          <CRow>
            <CCol>
              <InputSeparator>
                <SettingsModalBoldText htmlFor="expire_date">
                  {t('Expire date')}
                </SettingsModalBoldText>

                <Controller
                  control={control}
                  name="expire_date"
                  status="error"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState
                  }) => (
                    <CustomDatePicker
                      height="56px"
                      locale={i18next.resolvedLanguage === 'fr' ? locale : localeEn}
                      onChange={onChange}
                      value={value}
                      onBlur={onBlur}
                      id="expire_date"
                      placeholder={t('Expire date ...')}
                      error={!!errors.expire_date}
                      format="YYYY-MM-DD"
                      disabledDate={disabledDate}
                      style={{ width: '100%' }}
                      className={!!errors.expire_date && 'input-error'}
                    />
                  )}
                />
                {errors.expire_date && <ErrorText>{errors.expire_date.message}</ErrorText>}
              </InputSeparator>
            </CCol>
          </CRow>

          <CRow>
            <CCol>
              <InputSeparator>
                <SettingsModalBoldText htmlFor="expire_date">
                  {t('Offered by')}
                </SettingsModalBoldText>
                <Controller
                  control={control}
                  name="offered_by"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState
                  }) => (
                    <SettingsModalInput
                      type="text"
                      id="offered_by"
                      name="offered_by"
                      placeholder={t('Offered by')}
                      onChange={onChange}
                      onBlur={onBlur}
                      // valid={!errors.offered_by && touched.offered_by}
                      error={errors.offered_by ? true : false}
                      // value={values.offered_by}
                      autoComplete="off"
                    />
                  )}
                />
                {errors.offered_by && <ErrorText>{errors.offered_by.message}</ErrorText>}
              </InputSeparator>
            </CCol>
          </CRow>

          <CRow>
            <CCol>
              <InputSeparator>
                <SettingsModalBoldText htmlFor="redeem_url">
                  {t('Redeem URL')}
                </SettingsModalBoldText>
                <Controller
                  control={control}
                  name="redeem_url"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState
                  }) => (
                    <SettingsModalInput
                      type="text"
                      id="redeem_url"
                      name="redeem_url"
                      placeholder={t('Website URL...')}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={errors.redeem_url ? true : false}
                      autoComplete="off"
                    />
                  )}
                />
                {errors.redeem_url && <ErrorText>{errors.redeem_url.message}</ErrorText>}
              </InputSeparator>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <InputSeparator>
                <SettingsModalBoldText htmlFor="redeem_text">
                  {t('Redeem text')}
                </SettingsModalBoldText>
                <Controller
                  control={control}
                  name="redeem_text"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState
                  }) => (
                    <SettingsModalInput
                      type="text"
                      id="redeem_text"
                      name="redeem_text"
                      placeholder={t('Redeem text...')}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={errors.redeem_text ? true : false}
                      autoComplete="off"
                    />
                  )}
                />
                {errors.redeem_text && <ErrorText>{errors.redeem_text.message}</ErrorText>}
              </InputSeparator>
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <InputSeparator>
                <SettingsModalBoldText htmlFor="is_featured">
                  {t('Is featured?')}
                </SettingsModalBoldText>

                <Controller
                  control={control}
                  name="is_featured"
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState
                  }) => (
                    <FeatureSwitch
                      onChange={onChange}
                      value={value}
                      shape="pill"
                      id="is_featured"
                      color="primary"
                    />
                  )}
                />
              </InputSeparator>
            </CCol>
          </CRow>

          {watch('is_featured') && (
            <>
              {/* TEMP: uncomment next block start */}
              {/* <CFormGroup>
                    <CLabel>{t("Card Logo")}</CLabel>
                    <AvatarCropper
                      name={"banner_logo"}
                      avatar={defaultImgCDN}
                      newAvatarState={{
                        newAvatar: cardLogo,
                        setNewAvatar: setCardLogo
                      }}
                      editorRef={cardLogoRef}
                      uploaderLength={150}
                      withPreview
                      previewLength={100}
                      validationSet={offerImageValidations}
                      mediaKey={MediaKeys.BANNER_LOGO}
                      subtitle={"125 x 125 image for Feature Banner Logo"}
                    />
                    <span
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      {isSubmitted && !isMediaInserted(MediaKeys.BANNER_LOGO) ? "Logo is required" : ""}
                    </span>
                  </CFormGroup>
                  <p className="text-danger d-block w-100 m-0">
                    {error && Object.keys(error).length ? error?.message : ""}
                  </p>
                  <CRow>
                    <CCol>
                      <CFormGroup>
                        <CLabel>{t("Card Title")}</CLabel>
                        <Controller
                          control={control}
                          name="banner_title"
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState
                          }) => (
                            <CInput
                              type="text"
                              id="banner_title"
                              name="banner_title"
                              placeholder={t("Carousel card title")}
                              onChange={onChange}
                              onBlur={onBlur}
                              invalid={errors.banner_title ? true : false}
                              autoComplete="off"
                            />
                          )}
                        />
                        <CInvalidFeedback>
                          {errors.banner_title && errors.banner_title.message}
                        </CInvalidFeedback>
                      </CFormGroup>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <CFormGroup>
                        <CLabel>{t("Card Description")}</CLabel>
                        <Controller
                          control={control}
                          name="banner_description"
                          render={({
                            field: { onChange, onBlur, value, name, ref, content },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState
                          }) => (
                            <CInput
                              type="text"
                              id="banner_description"
                              name="banner_description"
                              placeholder={t("Carousel card description of your deal")}
                              onChange={onChange}
                              onBlur={onBlur}
                              invalid={errors.banner_description ? true : false}
                              autoComplete="off"
                            />
                          )}
                        />
                        <CInvalidFeedback>
                          {errors.banner_description && errors.banner_description.message}
                        </CInvalidFeedback>
                      </CFormGroup>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <CFormGroup>
                        <CLabel>{t("Card Button Text")}</CLabel>
                        <Controller
                          control={control}
                          name="banner_button_text"
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState
                          }) => (
                            <CInput
                              type="text"
                              id="banner_button_text"
                              name="banner_button_text"
                              placeholder={t("Carousel card button text")}
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              invalid={errors.banner_button_text ? true : false}
                              autoComplete="off"
                            />
                          )}
                        />
                        <CInvalidFeedback>
                          {errors.banner_button_text && errors.banner_button_text.message}
                        </CInvalidFeedback>
                      </CFormGroup>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <CLabel>{t("Carousel Card Preview")}</CLabel>
                      <CarouselCardSkeleton 
                        cardTitle={watch('banner_title')}
                        cardDescription={watch('banner_description')}
                        cardButtonText={watch('banner_button_text')}
                        cardBackgroundColor={watch('banner_background_color')}
                        cardLogo={cardLogoUrl}
                        />
                    </CCol>
                  </CRow>
                  <CRow style={{marginTop: '20px', marginBottom: '20px'}}>
                    <CCol>
                      <TwitterPicker width={'100%'} color={watch('banner_background_color')} onChangeComplete={(color) => setValue('banner_background_color', color.hex)}/>
                    </CCol>
                  </CRow> */}
              {/* TEMP: uncomment next block end */}
              {/* TEMP: remove code block start */}
              <CRow className="mt-4">
                <CCol>
                  <CFormGroup>
                    <SettingsModalBoldText className="d-block" htmlFor="additional-media">
                      {t('Additional Media')}
                    </SettingsModalBoldText>

                    <AvatarCropper
                      name={'additional-media-edit'}
                      avatar={defaultImgCDN}
                      newAvatarState={{
                        newAvatar: additionalMediaAvatar,
                        setNewAvatar: setAdditionalMediaAvatar
                      }}
                      editorRef={additionalMediaAvatarRef}
                      isEdit
                      uploaderLength={150}
                      withPreview
                      previewLength={100}
                      validationSet={offerImageValidations}
                      mediaKey={MediaKeys.EXTRA_MEDIA}
                      subtitle={'366x160 image for the Grata Plus carousel'}
                    />
                    <span className="invalid-feedback" style={{ display: 'block' }}>
                      {isSubmitted &&
                        watch('is_featured') &&
                        !isMediaInserted(MediaKeys.EXTRA_MEDIA) && (
                          <ErrorText>Additional media is required</ErrorText>
                        )}
                    </span>
                  </CFormGroup>
                </CCol>
              </CRow>
              {/* TEMP: remove code block end */}
              <CRow>
                <CCol>
                  <InputSeparator>
                    <SettingsModalBoldText htmlFor="content">
                      {t('Additional Media Content')}
                    </SettingsModalBoldText>

                    <Controller
                      control={control}
                      name="additional_content"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState
                      }) => (
                        <StyledTextArea
                          type="text"
                          id="additional-content"
                          name="additional_content"
                          placeholder={t('Content...')}
                          autoComplete="text"
                          valid={Boolean(!errors.additional_content && watch(name))}
                          rows="5"
                          invalid={Boolean(errors.additional_content)}
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />
                    {errors.additional_content && (
                      <ErrorText>{errors.additional_content.message}</ErrorText>
                    )}
                  </InputSeparator>
                </CCol>
              </CRow>
            </>
          )}
          <CFormGroup>
            <SettingsModalBoldText>{t('Logo*')}</SettingsModalBoldText>
            <AvatarCropper
              name={'logo-edit'}
              avatar={defaultImgCDN}
              newAvatarState={{
                newAvatar: logoAvatar,
                setNewAvatar: setLogoAvatar
              }}
              editorRef={logoAvatarRef}
              uploaderLength={150}
              withPreview
              previewLength={100}
              validationSet={offerImageValidations}
              mediaKey={MediaKeys.LOGO}
            />
            <span className="invalid-feedback" style={{ display: 'block' }}>
              {isSubmitted && !isMediaInserted(MediaKeys.LOGO) && (
                <ErrorText>Logo is required</ErrorText>
              )}
            </span>
          </CFormGroup>
          {error && Object.keys(error).length && <ErrorText>{error?.message}</ErrorText>}
        </StyledModalBody>
        <CModalFooter>
          <Button
            width={'125px'}
            onClick={() => {
              onClose();
            }}
            text={t('Cancel')}
          />

          {loading ? (
            <LoadingAvatar color={LOADING_BLUE} />
          ) : (
            <Button
              isPrimary
              width={'125px'}
              type="submit"
              disabled={!isDirty || (isSubmitted && Boolean(errors?.media))}
              text={t('Add')}
            />
          )}
        </CModalFooter>
      </StyledModal>
    </form>
  );
};

export default AddOffer;
