import React, { useEffect, useState, useRef, useMemo } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { changeOpenedModal } from '../redux/actions/headerModal';
import { setUnitsCSVFile } from '../redux/actions/unit';
import { setToast } from '../redux/actions/window';
import * as XLSX from 'xlsx/xlsx.mjs';
import _ from 'lodash';
import { TablePageButton } from './tables/TableComponents';
import { AVAILABLE_TABS } from '../views/properties/Unit&Amenities';
import {useGetConfigs} from '../hooks/useGetConfigs';

const modalName = 'AddUnitsFromCSV';
const acceptedFileFormat = ['csv'];
const acceptedUnitHeadersNoSuite = ['unit_num', 'floor', 'bedrooms', 'bathrooms', 'area'];
const acceptedUnitHeadersWithSuite = ['unit_num', 'floor', 'bedrooms', 'bathrooms', 'area', 'suite_name'];
const acceptedSuiteHeaders = ['name', 'area'];

const ImportUnitsCSVButton = ({ chosenTab }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {config} = useGetConfigs();

  const [file, setFile] = useState(null);
  const fileReader = new FileReader();
  const inputRef = useRef(null);

  const handleOnChange = (e) => {
    if (e.target.files.length) {
      const fileObj = e.target.files[0];
      const fileName = fileObj.name;
      const fileExtension = fileName.slice(fileName.lastIndexOf('.') + 1);
      if (acceptedFileFormat.includes(fileExtension)) {
        setFile(fileObj);
      } else {
        dispatch(
          setToast({
            toastShow: true,
            toastMessage: t('Please select a valid CSV file'),
            modal: 'errorToast'
          })
        );
      }

      e.target.value = null;
    }
  };

  const handleOnClick = (e) => {
    setFile(null);
    inputRef.current.click();
  };

  useEffect(() => {
    if (file) {
      fileReader.onload = function(event) {
        const workbook = XLSX.read(event.target.result, { type: 'array' });
        let sheetName = workbook.SheetNames[0];
        let sheet = workbook.Sheets[sheetName];

        let headers = [];

        for (let cell in sheet) {
          // Check if the cell is in the first row
          if (/[A-Z]+1$/.test(cell)) {
            headers.push(sheet[cell].v);
          }
          const cellValue = sheet[cell]?.v;
          if (Boolean(cellValue) && typeof cellValue === 'string') {
            if (/^\s+|\s+$/.test(cellValue))
              return dispatch(
                setToast({
                  toastShow: true,
                  toastMessage: t(
                    'Some values contain leading or trailing spaces, which should be removed!'
                  ),
                  modal: 'errorToast'
                })
              );
          }
        }

        const acceptedHeaders = chosenTab === AVAILABLE_TABS.SUITE ? acceptedSuiteHeaders : config?.suite_enabled ? acceptedUnitHeadersWithSuite : acceptedUnitHeadersNoSuite;

        if (!_.isEqual(headers, acceptedHeaders))
          return dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('File headers do not match.'),
              modal: 'errorToast'
            })
          );
        //check if there are any empty cells
        for (let i = 2; i <= sheet['!ref'].split(':')[1].match(/\d+/g)[0]; i++) {
          let cellsInRow = Object.keys(sheet).filter((cell) => {
            const match = cell.match(/\d+$/);
            return match && match[0] == i;
          });

          if (cellsInRow.length === 0) {
            return dispatch(
              setToast({
                toastShow: true,
                toastMessage: t('Empty row detected at ' + i + '.'),
                modal: 'errorToast'
              })
            );
          }
        }

        dispatch(setUnitsCSVFile(file));
        dispatch(
          changeOpenedModal({
            modal: modalName,
            data: { chosenTab }
          })
        );
      };

      fileReader.readAsArrayBuffer(file);
    }
  }, [file]);

  return (
    <>
      <form className="h-100">
        <input
          id="UnitsCSVFileInput"
          ref={inputRef}
          type={'file'}
          accept={'.csv'}
          onChange={handleOnChange}
          onClick={handleOnClick}
          className="d-none"
        />

        <TablePageButton
          upload
          onClick={(e) => {
            e.preventDefault();
            handleOnClick(e);
          }}
        />
      </form>
    </>
  );
};

export default ImportUnitsCSVButton;
