import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeOpenedModal } from '../../redux/actions/headerModal';
import LoadingAvatar from '../../components/LoadingAvatar';
import { useTranslation } from 'react-i18next';
import { clearBuilding } from '../../redux/actions/building';
import {
  clearService,
  getServiceRequestCategories,
  updateService
} from '../../redux/actions/services';
import { LOADING_BLUE } from '../../utils/colorCode';
import { RequestStatus } from './constant';
import { getContactWithFromId, getPriorityFromId } from '../../helper';
import * as Yup from 'yup';
import { HeaderText, StyledModalBody, StyledModalTitle } from '../../components/Modals';
import { ButtonBodyVariant } from '../../components/ui/ButtonVariant';
import { Fonts, Palette } from '../../components/utils';
import styled, { css } from 'styled-components';
import { Flex } from '../../components/ui/Flex';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '../../components/ui/Form';
import { useForm } from 'react-hook-form';
import { Separator } from '../../components/ui/Separator';
import {
  SettingsItem,
  SettingsItemContent,
  SettingsItemSubTitle,
  SettingsItemTitle
} from '../settings/SettingsItem';
import { FaBuilding, FaUser } from 'react-icons/fa';
import { HiChatBubbleOvalLeft } from 'react-icons/hi2';
import { MdContactPage, MdDescription } from 'react-icons/md';
import { PiWrenchFill } from 'react-icons/pi';
import { BiSolidBellRing } from 'react-icons/bi';
import { Select, SelectItem } from '../../components/ui/Select';
import UserAsyncDropDown from '../../components/UserAsyncDropDown';
import { closeActiveModal, showToast } from '../../helper/modals';
import { SEARCH_TABLES } from '../../helper/constants';
import { searchTable, setSearchPagination, setSearchState } from '../../redux/actions/search';
import { setUserSearch } from '../../redux/actions/user';
import { COMPLETE, IN_PROGRESS, OPEN } from '../../utils/mappings';
import { yupResolver } from '@hookform/resolvers/yup';
import ServiceDetailModal from './serviceDetailSubComponents/ServiceDetailModal';
import ServiceDetailChatModal from './serviceDetailSubComponents/ServiceDetailChatModal';

const STATUS_MAP = [OPEN, IN_PROGRESS, COMPLETE];
const settingsItemTitleAltStyles = {
  color: Palette.BLACK_50,
  fontSize: '10px',
  lineHeight: '16px',
  textTransform: 'uppercase'
};

const ServiceDetail = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modal = useSelector((state) => state.headerModal.modal);
  const status = useSelector((state) => state.services.status);
  const error = useSelector((state) => state.services.error);
  const categories = useSelector((state) => state.services.categories);
  const searchState = useSelector((state) => state.search);

  const service = useSelector((state) => state.services.service);
  const globalOrgId = useSelector((state) => state.Organization.globalOrgId);

  const [tabIndex, setTabIndex] = useState(0);
  const isDisabled =
    service.status === RequestStatus.ARCHIVED || service.status === RequestStatus.COMPLETE;

  const schema = Yup.object().shape({
    category: Yup.number().required(t('Category is required')),
    status: Yup.number().required(t('Status is required')),
    technician: Yup.mixed().when('status', {
      is: IN_PROGRESS.id,
      then: Yup.object()
        .required(t('Technician is required'))
        .shape({
          value: Yup.number().required(t('Technician ID is required')),
          label: Yup.string().required(t('Technician name is required'))
        })
        .default(undefined)
        .typeError(t('Technician must be selected when status is In Progress')),
      otherwise: Yup.mixed()
        .notRequired()
        .nullable()
        .transform(() => {
          return undefined;
        })
    })
  });
  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      category: undefined,
      technician: undefined,
      status: undefined
    }
  });

  const onSubmit = (values) => {
    const body = {
      user_id: parseInt(service.user_id),
      building_id: parseInt(service.building_id),
      subject: service.subject,
      description: service.description,
      media: service.media,
      time_slot: service.time_slot ? service.time_slot : null,
      contact_type: service.contact_type,
      priority: service.priority,
      allow_access: service.allow_access,
      assigned_to: values.technician?.value || 0,
      category_id: values.category,
      schedule_date: service.schedule_date,
      status_id: values.status,
      data: service.data,
      contact_info: service.contact_info
    };

    dispatch(
      updateService({
        id: service.id,
        body: body,
        success: () => {
          dispatch(
            searchTable({
              entity: SEARCH_TABLES.WORK_REQUESTS,
              orgID: globalOrgId,
              body: {
                search: searchState.search,
                minCreationDate: searchState.minCreationDate,
                maxCreationDate: searchState.maxCreationDate,
                filters: searchState.filters,
                ordering: searchState.ordering,
                pagination: searchState.pagination
              },
              success: (res) => {
                dispatch(
                  setSearchState({
                    currentData: res.data.data ?? [],
                    currentPaginationData: res.data.pagination
                  })
                );

                if (!res.data.data) {
                  dispatch(setUserSearch({ page: 1 }));
                  dispatch(
                    setSearchPagination({
                      page: 1,
                      limit: 20
                    })
                  );
                }
              }
            })
          );
          showToast(t('Service request details have been successfully updated!'));
          closeActiveModal();
        },
        fail: (res) => {
          console.log(res);
          showToast(t('Service request update failed!'), true);
        }
      })
    );
  };

  const onClose = () => {
    dispatch(clearService());
    dispatch(clearBuilding());
    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );
  };

  useEffect(() => {
    if (service && !form.formState.isDirty) {
      form.reset({
        category: service.category_id,
        technician:
          service.assigned_to && service.assigned_to_name
            ? { value: service.assigned_to, label: service.assigned_to_name }
            : undefined,
        status: service.status_id
      });
    }

    dispatch(getServiceRequestCategories({ orgId: globalOrgId }));
  }, [service, form.reset, form.formState.isDirty]);

  return tabIndex === 0 ? (
    <Form {...form}>
      <form name="createServiceReqForm" onSubmit={form.handleSubmit(onSubmit)}>
        <ServiceDetailModal
          show={modal === 'serviceDetail'}
          padding
          side
          closeOnBackdrop
          onClose={onClose}>
          <Flex justify="space-between" alignItems="center">
            <Flex direction="column">
              <RefText>{service?.reference}</RefText>
              <StyledModalTitle>{service?.subject}</StyledModalTitle>
            </Flex>

            <ChatBoxButton onClick={() => setTabIndex(1)} />
          </Flex>

          <StyledModalBody>
            {status === 'GET_SERVICE_PENDING' ? (
              <LoadingAvatar color={LOADING_BLUE} />
            ) : status === 'GET_SERVICE_FAIL' ? (
              <Flex alignItems="center" justify="center">
                <ErrorText>{error}</ErrorText>
              </Flex>
            ) : (
              <Flex direction="column" gap="16px">
                <FormField
                  control={form.control}
                  name="status"
                  render={({ field }) => (
                    <FormItem alt>
                      <FormLabel alt>{t('Ticket Status')}</FormLabel>
                      <FormControl>
                        <Select
                          {...field}
                          disabled={isDisabled}
                          placeholder={t('Please Select')}
                          onChange={(e) => {
                            if (e !== IN_PROGRESS.id) {
                              form.setValue('technician', undefined);
                            }
                            field.onChange(e);
                          }}>
                          {STATUS_MAP.map((status) => (
                            <SelectItem key={status.id} value={status.id}>
                              {status.value}
                            </SelectItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />

                <Separator orientation="horizontal" />

                <Flex direction="column" gap="20px">
                  <HeaderText>{t('Resident details')}</HeaderText>

                  <Flex justify="space-between" gap="19px">
                    <SettingsItem>
                      <FaUser size={18} color={Palette.HAZY_BLUE} className="flex-shrink-0" />
                      <SettingsItemContent>
                        <SettingsItemTitle cssOverride={settingsItemTitleAltStyles}>
                          {t('Resident')}
                        </SettingsItemTitle>
                        <SettingsItemSubTitle>
                          {service.tenant ? service.tenant.name : t('User not found')}
                        </SettingsItemSubTitle>
                      </SettingsItemContent>
                    </SettingsItem>

                    <SettingsItem>
                      <FaBuilding size={18} color={Palette.HAZY_BLUE} className="flex-shrink-0" />
                      <SettingsItemContent>
                        <SettingsItemTitle cssOverride={settingsItemTitleAltStyles}>
                          {t('Building')}
                        </SettingsItemTitle>
                        <SettingsItemSubTitle>
                          {service.tenant ? service.tenant.building : t('Building not found')}
                        </SettingsItemSubTitle>
                        <SettingsItemSubTitle>
                          {service.tenant
                            ? `Floor ${service.tenant.floor} Unit ${service.tenant.unit_num}`
                            : t('Unit not found')}
                        </SettingsItemSubTitle>
                      </SettingsItemContent>
                    </SettingsItem>
                  </Flex>
                </Flex>

                <Separator orientation="horizontal" />

                <Flex direction="column" gap="20px">
                  <HeaderText>{t('Service details')}</HeaderText>

                  <Flex direction="column" gap="16px">
                    <GridTest>
                      <SettingsItem>
                        <MdDescription
                          size={18}
                          color={Palette.HAZY_BLUE}
                          className="flex-shrink-0"
                        />
                        <SettingsItemContent>
                          <SettingsItemTitle cssOverride={settingsItemTitleAltStyles}>
                            {t('Contact method')}
                          </SettingsItemTitle>
                          <SettingsItemSubTitle>
                            {getContactWithFromId(service.contact_type)}
                          </SettingsItemSubTitle>
                        </SettingsItemContent>
                      </SettingsItem>

                      <SettingsItem cssOverride={{ justifySelf: 'end' }}>
                        <PiWrenchFill
                          size={18}
                          color={Palette.HAZY_BLUE}
                          className="flex-shrink-0"
                        />
                        <SettingsItemContent>
                          <SettingsItemTitle cssOverride={settingsItemTitleAltStyles}>
                            {t('Unit Access')}
                          </SettingsItemTitle>
                          <SettingsItemSubTitle>
                            {service.allow_access ? t('Allowed') : t('Not allowed')}
                          </SettingsItemSubTitle>
                        </SettingsItemContent>
                      </SettingsItem>

                      <SettingsItem>
                        <BiSolidBellRing
                          size={18}
                          color={Palette.HAZY_BLUE}
                          className="flex-shrink-0"
                        />
                        <SettingsItemContent>
                          <SettingsItemTitle cssOverride={settingsItemTitleAltStyles}>
                            {t('Urgency')}
                          </SettingsItemTitle>
                          <SettingsItemSubTitle>
                            {getPriorityFromId(service.priority)}
                          </SettingsItemSubTitle>
                        </SettingsItemContent>
                      </SettingsItem>
                    </GridTest>
                    <SettingsItem>
                      <MdContactPage
                        size={18}
                        color={Palette.HAZY_BLUE}
                        className="flex-shrink-0"
                      />
                      <SettingsItemContent>
                        <SettingsItemTitle cssOverride={settingsItemTitleAltStyles}>
                          {t('Description')}
                        </SettingsItemTitle>
                        <SettingsItemSubTitle>{service.description}</SettingsItemSubTitle>
                      </SettingsItemContent>
                    </SettingsItem>
                  </Flex>

                  {service?.media !== '' && (
                    <div>
                      <PhotosTitle>Photos</PhotosTitle>
                      <PhotosContainer>
                        <a href={service.media} target="_blank">
                          <img src={service.media} alt={service.subject} />
                        </a>
                      </PhotosContainer>
                    </div>
                  )}

                  <FormField
                    control={form.control}
                    name="technician"
                    render={({ field }) => {
                      const statusValue = form.getValues('status');
                      return (
                        <FormItem alt>
                          <FormLabel alt>{t('Assigned technician')}</FormLabel>
                          <FormControl>
                            <UserAsyncDropDown
                              skip_deleted={true}
                              type={2}
                              placeholder={t('Assign to')}
                              disabled={statusValue !== IN_PROGRESS.id || isDisabled}
                              buildingId={service.building_id}
                              {...field}
                            />
                          </FormControl>

                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />

                  <FormField
                    control={form.control}
                    name="category"
                    render={({ field }) => (
                      <FormItem alt>
                        <FormLabel alt>{t('Category')}</FormLabel>
                        <FormControl>
                          <Select
                            custom
                            disabled={isDisabled}
                            placeholder={'Please select'}
                            {...field}>
                            {categories?.map((item) => (
                              <SelectItem key={item.ID} value={item.ID}>
                                {item.Name}/{item.SubCategory}
                              </SelectItem>
                            ))}
                          </Select>
                        </FormControl>

                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </Flex>
              </Flex>
            )}
          </StyledModalBody>
          <ButtonBodyVariant disabled={!form.formState.isDirty || form.formState.isSubmitting}>
            {t('Update')}
          </ButtonBodyVariant>
        </ServiceDetailModal>
      </form>
    </Form>
  ) : (
    <ServiceDetailChatModal
      onClose={onClose}
      onClickBack={() => setTabIndex(0)}
      isDisabled={isDisabled}
    />
  );
};

const RefText = styled.p`
  color: ${Palette.BLACK};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin: 0;
`;

const GridTest = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 24px 0;
  justify-content: space-around;
  align-content: space-around;
`;

const iconStyles = css`
  color: ${Palette.HAZY_BLUE};

  :hover {
    color: ${Palette.BLACK};
    cursor: pointer;
  }
`;

const ChatBoxButton = styled(HiChatBubbleOvalLeft)`
  width: 23px;
  height: 21.3px;
  flex-shrink: 0;
  ${iconStyles}
`;

const ErrorText = styled.p`
  color: ${Palette.ERROR};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 16px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin: 0;
`;

const PhotosTitle = styled.p`
  margin-bottom: 16px;
  color: ${Palette.BLACK};
  font-size: 16px;
  font-weight: 600;
`;

const PhotosContainer = styled.div`
  --photo-width: 100px;
  display: grid;
  grid-template-columns: repeat(auto-fit, var(--photo-width));
  gap: 24px;

  & img {
    background-color: ${Palette.STROKE_GREY};
    width: 100%;
    height: var(--photo-width);
    object-fit: cover;
    border-radius: 5px;
  }
`;

export default ServiceDetail;
