import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  ADD_UNIT,
  EDIT_UNIT,
  GET_UNITS,
  GET_DOOR_PIN,
  GET_UNIT,
  GET_DD_UNITS,
  REMOVE_UNIT,
  NOTIFY_UNIT_PIN,
  ADD_UNITS_FROM_CSV,
  GET_SUITE,
  GET_CONFLICTING_DATES,
  GET_RESERVATIONS,
  SEND_USER_UNIT_PIN,
  GET_UNIT_RESIDENTS
} from '../constants';
import apiCall, { HTTP_METHOD } from '../../utils/apiCall';

const addUnit = apiCall({
  type: ADD_UNIT,
  method: HTTP_METHOD.post,
  path: 'units'
});

const editUnit = apiCall({
  type: EDIT_UNIT,
  method: HTTP_METHOD.put,
  path: ({ id }) => `/units/${id}`
});

const getDDUnits = apiCall({
  type: GET_DD_UNITS,
  method: HTTP_METHOD.get,
  path: ({ id }) => `/buildings/${id}/units?format=short`
});

const getUnits = apiCall({
  type: GET_UNITS,
  method: HTTP_METHOD.get,
  path: ({ id, page, sort, sortOrder, perPage }) => {
    let url = `/buildings/${id}/units?b=1&page=${page}`;
    if (sort) {
      url += `&sort=${sort}`;
    }
    if (sortOrder) {
      url += `&sort_order=${sortOrder}`;
    }
    if (perPage) {
      url += `&per_page=${perPage}`;
    }
    return url;
  }
});

const getUnit = apiCall({
  type: GET_UNIT,
  method: HTTP_METHOD.get,
  path: ({ id, page = 30, day, month, year }) => {
    let url = `/units/${id}?per_page=${page}`;
    if (day) {
      url += `&day=${day}`;
    }
    if (month) {
      url += `&month=${month}`;
    }
    if (year) {
      url += `&year=${year}`;
    }
    return url;
  }
  // path: ({ id, day, month, yearr }) => `/units/${id}`
});

const getUnitResidents = apiCall({
  type: GET_UNIT_RESIDENTS,
  method: HTTP_METHOD.get,
  path: ({ unitID }) => `/units/${unitID}/residents`
});

const getSuite = apiCall({
  type: GET_SUITE,
  method: HTTP_METHOD.get,
  path: ({ id }) => `/suites/${id}`
});

const getDoorPin = apiCall({
  type: GET_DOOR_PIN,
  method: HTTP_METHOD.get,
  path: ({ id }) => `devices/${id}/codes`
});

const removeUnit = apiCall({
  type: REMOVE_UNIT,
  method: HTTP_METHOD.delete,
  path: ({ id }) => `/units/${id}`
});

const notifyUnitPin = apiCall({
  type: NOTIFY_UNIT_PIN,
  method: HTTP_METHOD.post,
  path: ({ unitId, orgId }) => `/units/${unitId}/notify?org=${orgId}`
});

const sendUserUnitPin = apiCall({
  type: SEND_USER_UNIT_PIN,
  method: HTTP_METHOD.post,
  path: ({ userID }) => `/users/${userID}/notify/pin`
});

const getConflictingDates = apiCall({
  type: GET_CONFLICTING_DATES,
  method: HTTP_METHOD.get,
  path: ({ unitId, moveInDate, moveOutDate, userID }) =>
    `/conflict-dates?unit_id=${unitId}&start_date=${moveInDate}&end_date=${moveOutDate}&user_id=${userID}`
});

const getReservations = apiCall({
  type: GET_RESERVATIONS,
  method: HTTP_METHOD.get,
  path: ({ unitId, moveInDate, moveOutDate, userID }) =>
    `/reservations?unit_id=${unitId}&start_date=${moveInDate}&end_date=${moveOutDate}&user_id=${userID}`
});

const addUnitsFromCSV = apiCall({
  type: ADD_UNITS_FROM_CSV,
  method: 'post',
  path: '/docs',
  isFormData: true,
  isFile: true
});

export default function* unitSaga() {
  yield takeLatest(REMOVE_UNIT, removeUnit);
  yield takeLatest(GET_UNIT_RESIDENTS, getUnitResidents);
  yield takeLatest(GET_DD_UNITS, getDDUnits);
  yield takeLatest(GET_RESERVATIONS, getReservations);
  yield takeLatest(GET_CONFLICTING_DATES, getConflictingDates);
  yield takeLatest(ADD_UNIT, addUnit);
  yield takeLatest(EDIT_UNIT, editUnit);
  yield takeLatest(GET_DOOR_PIN, getDoorPin);
  yield takeLatest(GET_UNITS, getUnits);
  yield takeLatest(GET_UNIT, getUnit);
  yield takeLatest(GET_SUITE, getSuite);
  yield takeEvery(SEND_USER_UNIT_PIN, sendUserUnitPin);
  yield takeEvery(NOTIFY_UNIT_PIN, notifyUnitPin);
  yield takeEvery(ADD_UNITS_FROM_CSV, addUnitsFromCSV);
}
