import { createReducer } from '@reduxjs/toolkit';
import { GET_THERMOSTATS, UPDATE_ECOBEE_THERMOSTAT } from '../constants';
import { requestFail, requestPending, requestSuccess } from '../../utils/status';

const initialState = {
  status: '',
  updateEcobeeThermostatStatus: '',
  thermostatData: [],
  search: '',
  filters: {},
  ordering: {},
  pagination: {
    page: 1,
    limit: 20
  }
};

export default createReducer(initialState, {
  [requestSuccess(GET_THERMOSTATS)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(GET_THERMOSTATS),
    thermostatData: payload
  }),

  [requestPending(GET_THERMOSTATS)]: (state) => ({
    ...state,
    status: requestPending(GET_THERMOSTATS)
  }),

  [requestFail(GET_THERMOSTATS)]: (state) => ({
    ...state,
    status: requestFail(GET_THERMOSTATS),
    thermostatData: null
  }),

  [requestSuccess(UPDATE_ECOBEE_THERMOSTAT)]: (state, { payload }) => ({
    ...state,
    updateEcobeeThermostatStatus: requestSuccess(UPDATE_ECOBEE_THERMOSTAT)
  }),

  [requestPending(UPDATE_ECOBEE_THERMOSTAT)]: (state) => ({
    ...state,
    updateEcobeeThermostatStatus: requestPending(UPDATE_ECOBEE_THERMOSTAT)
  }),

  [requestFail(UPDATE_ECOBEE_THERMOSTAT)]: (state) => ({
    ...state,
    updateEcobeeThermostatStatus: requestFail(UPDATE_ECOBEE_THERMOSTAT)
  })
});
