import React, { useMemo } from 'react';
import { ModalHeader, StyledModal, StyledModalBody } from '../../components/Modals';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Flex } from '../../components/ui/Flex';
import { ButtonBodyVariant } from '../../components/ui/ButtonVariant';
import styled from 'styled-components';
import { setToast } from '../../redux/actions/window';
import { Fonts, Palette } from '../../components/utils';
import { acknowledgeLeak, searchLeakDetectors } from '../../redux/actions/leakDetector';
import moment from 'moment';
import { DATE_FORMATS } from '../users/constant';
import { Separator } from '../../components/ui/Separator';
import { closeActiveModal } from '../../helper/modals';
import { IGNORED_LEAK_DETECTOR_EVENTS } from '../../helper/constants';

export const LeakDetectorsDetailsModal = () => {
  const { t } = useTranslation();
  const { modal, data } = useSelector((state) => state.headerModal);
  const globalBuildingId = useSelector((state) => state.building.globalBuildingId);
  const globalOrgId = useSelector((state) => state.Organization.globalOrgId);
  const dispatch = useDispatch();

  const activeAlarms = useMemo(
    () => data.currentAlarms.filter((alarm) => !IGNORED_LEAK_DETECTOR_EVENTS.includes(alarm.type)),
    [data.currentAlarms]
  );

  const canAcknowledge = () => activeAlarms.length > 0;

  const handleSubmit = () => {
    dispatch(
      acknowledgeLeak({
        leakDetectorID: data.deviceID,
        success: () => {
          closeActiveModal();
          dispatch(
            searchLeakDetectors({
              orgID: globalOrgId,
              buildingID: globalBuildingId,
              all: true,
              fail: () => {
                dispatch(
                  setToast({
                    toastShow: true,
                    toastMessage: t('Failed to get leak detectors'),
                    modal: 'errorToast'
                  })
                );
              }
            })
          );
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Acknowledged leak detector')
            })
          );
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Failed to acknowledge leak detector'),
              modal: 'errorToast'
            })
          );
        }
      })
    );
  };

  return (
    <StyledModal show={modal === 'leakDetectorDetailsModal'}>
      <ModalHeader title={`${data.unitNum || data.suiteNum} ${t('Events')}`} />
      <StyledModalBody>
        <Flex direction="column" gap="16px">
          {activeAlarms.length > 0 ? (
            activeAlarms.map((alarm, index) => (
              <>
                <Flex direction="column" gap="8px" key={index}>
                  <AlarmText>
                    {t('Event')}: {t(alarm.type)}
                  </AlarmText>
                  {alarm.ack ? (
                    <>
                      <AlarmText>
                        {t('Acknowledged')}: {t('AcknowledgedYes')}
                      </AlarmText>
                      <AlarmText>
                        {t('Acknowledged By')}: {alarm.ack_user_email}
                      </AlarmText>
                    </>
                  ) : (
                    <AlarmText>
                      {t('Acknowledged')}: {t('AcknowledgedNo')}
                    </AlarmText>
                  )}

                  <AlarmText>
                    {t('Date Occurred')}:{' '}
                    {moment(alarm.created).format(DATE_FORMATS.MM_DD_YYYY_h_mm_A)}
                  </AlarmText>
                  <p>{alarm.alarm}</p>
                </Flex>
                {index !== activeAlarms.length - 1 && <Separator orientation="horizontal" />}
              </>
            ))
          ) : (
            <AlarmText>{t('No alerts')}</AlarmText>
          )}
        </Flex>
      </StyledModalBody>
      <ButtonBodyVariant onClick={handleSubmit} disabled={!canAcknowledge()}>
        {t('Acknowledge')}
      </ButtonBodyVariant>
    </StyledModal>
  );
};

export const TitleText = styled.p`
  color: ${Palette.BLACK};
  font-family: ${Fonts.RED_HAT_FONT};
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.5rem;
  text-transform: capitalize;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  margin: 0;
`;

export const AlarmText = styled.p`
  color: ${Palette.BLACK};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  margin: 0;
`;

export default LeakDetectorsDetailsModal;
