// This version of image uploader uses React Dropzone and is meant to be used with react hook form

import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { FaCircleCheck } from 'react-icons/fa6';
import { CgClose } from "react-icons/cg";

import { compressImgFile, ImagesOptions } from '../helper';
import { MediaKeys } from '../views/offers/utils';
import { Palette } from './utils/palette';
import { Flex } from './ui/Flex';
import ImageIcon from './icons/ImageIcon';
import PlusIcon from './icons/PlusIcon';
import { formatMbFileSize } from '../utils/utils';
import { FormMessage } from './ui/Form';

const ImageUploadWithPreviewV2 = ({
  children,
  form,
  controllerProps,
  fieldName, 
  acceptedImageFormats,
  mediaKey = ''
}) => {
  const { setValue, trigger } = form; 
  // For storing the preview of the uploaded image
  const [preview, setPreview] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  
  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file) return;
    setIsUploading(true);
    let compressedImageBlob;
    if (mediaKey === MediaKeys.MEDIA || mediaKey === MediaKeys.BANNER_LOGO) {
      compressedImageBlob = await compressImgFile(
        file,
        ImagesOptions(3)[mediaKey].DIMENSION.WIDTH,
        ImagesOptions(3)[mediaKey].DIMENSION.HEIGHT
      );
    } else {
      compressedImageBlob = await compressImgFile(file);
    }
  
    setPreview({
      image: compressedImageBlob,
      previewURL: URL.createObjectURL(compressedImageBlob),
      fileName: file.name,
    });
    setValue(fieldName, { blob: compressedImageBlob, fileName: file.name });
    setIsUploading(false);
    await trigger(fieldName);
  }, [fieldName, setValue]);

  const removeImage = async () => {
    setPreview(null);
    setValue(fieldName, null);
    await trigger(fieldName);
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': acceptedImageFormats
    },
    multiple: false,
    disabled: isUploading
  });

  return (
    <>
      <ImageDropzoneContainer {...getRootProps()} {...controllerProps}>
        <input {...getInputProps()} />

        {preview ? (
          <PreviewImage src={preview.previewURL} alt={preview.fileName} />
        ) : (
              <ImageUploadContainer direction='column' gapX='29px' alignItems='center'>
                <CircleContainer>
                <ImageIconContainer>
                  <ImageIcon />
                  <PlusIconWrapper>
                    <PlusIcon />
                  </PlusIconWrapper>
                </ImageIconContainer>
                </CircleContainer>
                {children}
              </ImageUploadContainer>
            ) 
        }
      </ImageDropzoneContainer>
      <FormMessage />
      {preview && (
        <PreviewInfo justify="space-between" alignItems="center" gapY="1rem">
          <Flex gapY="0.625rem" alignItems="center">
            <PreviewInfoImageIcon />
            <div>
              <p>{preview.fileName}</p>
              <PreviewImageSize>{formatMbFileSize(preview.image.size)}MB</PreviewImageSize>
            </div>
          </Flex>
          <Flex gapY="1.1875rem" alignItems="center">
            <p>100%</p>
            <CheckIcon />
            <ClearPreviewButton onClick={removeImage}>
              <CgClose />
            </ClearPreviewButton>
          </Flex>
        </PreviewInfo>
      )}
    </>
  )
};

export default ImageUploadWithPreviewV2;

const ImageDropzoneContainer = styled.div`
  border: 1px dashed rgba(19, 49, 85, 0.3);
  border-radius: 30px;
  overflow: hidden;
`;

const PreviewImage = styled.img`
  width: 100%;
  object-fit: cover;
`;

const ImageUploadContainer = styled(Flex)`
  padding: 2.4375rem;
`;

const CircleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 9.5rem;
  height: 9.5rem;
  background-color: ${Palette.SOLITUDE};
  border-radius: 50%;
`;

const ImageIconContainer = styled.div`
  position: relative;
`;

const PlusIconWrapper = styled.div`
  position: absolute;
  width: 27px;
  height: 27px;
  background-color: #fff;
  border-radius: 50%;
  bottom: -3px;
  right: -6px;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const PreviewInfo = styled(Flex)`
  padding: 0.625rem 1rem;
  border-radius: 10px;
  border: 1px solid ${Palette.STROKE_GREY};
  font-weight: 600;
  color: ${Palette.BLACK};

  p {
    margin-bottom: 0;
  }
`;

const PreviewInfoImageIcon = styled(ImageIcon)`
  width: 2.75rem;
  height: 2.75rem;
`;

const PreviewImageSize = styled.p`
  font-size: 0.75rem;
  color: ${Palette.BLACK_50};
`;

const CheckIcon = styled(FaCircleCheck)`
  width: 1.5rem;
  height: 1.5rem;
  fill: ${Palette.ACTIVE_GREEN};
`;

const ClearPreviewButton = styled.button`
  display: flex;
  padding: 0;
  border: none;
  background-color: transparent;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: ${Palette.BLACK};
  }
`;
