import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeActiveModal, openModal } from '../../helper/modals';
import { ModalHeader, StyledModal, StyledModalBody } from '../../components/Modals';
import { Flex } from '../../components/ui/Flex';
import styled from 'styled-components';
import { Fonts, Palette } from '../../components/utils';
import { ButtonBodyVariant } from '../../components/ui/ButtonVariant';
import { getUnitResidents } from '../../redux/actions/unit';
import { requestPending } from '../../utils/status';
import { Skeleton } from '../../components/ui/Skeleton';
import { getUser } from '../../redux/actions/user';
import { setToast } from '../../redux/actions/window';
import { getRoomUnitTerminology } from '../../utils/utils';
import {useGetConfigs} from '../../hooks/useGetConfigs';

const LicensePlateDetailsModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { modal, data } = useSelector((state) => state.headerModal);
  const { config } = useGetConfigs();
  const unitResidentsStatus = useSelector((state) => state.unit.unitResidentsStatus);
  const unitResidentsError = useSelector((state) => state.unit.unitResidentsError);
  const unitResidents = useSelector((state) => state.unit.unitResidents);

  const showUserDetail = (id) =>
    dispatch(
      getUser({
        id,
        success: () => openModal('UserDetail'),
        fail: () =>
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Failed to get user!'),
              modal: 'errorToast'
            })
          )
      })
    );

  useEffect(() => {
    dispatch(getUnitResidents({ unitID: data.unit_id }));
  }, [data.unit_id]);

  return (
    <StyledModal
      show={modal === 'LicensePlateDetailsModal'}
      closeOnBackdrop
      onClose={closeActiveModal}>
      <ModalHeader title={t('License plate details')} />

      <StyledModalBody>
        <Flex direction="column" gap="16px">
          <Flex direction="column" gap="16px">
            <TitleText>{t('License plate')}</TitleText>
            <Text>{data.license_plate}</Text>
          </Flex>

          <Flex direction="column" gap="16px">
            <TitleText>{t('Building name')}</TitleText>
            <Text>{data.building_name}</Text>
          </Flex>

          <Flex direction="column" gap="16px">
            <TitleText>{getRoomUnitTerminology(config)}</TitleText>
            <Text>{data.unit_num}</Text>
          </Flex>

          <Flex direction="column" gap="16px">
            <TitleText>{t('Residents')}</TitleText>
            {unitResidentsStatus === requestPending('GET_UNIT_RESIDENTS') ? (
              <Skeleton width={'100%'} cssOverride={'border-radius: 15px; height: 24px'} />
            ) : unitResidentsError ? (
              <Text>Failed to fetch users</Text>
            ) : (
              <Flex direction="column">
                {unitResidents.map((resident) => (
                  <Text key={resident.ID} clickable onClick={() => showUserDetail(resident.ID)}>
                    {resident.FirstName} {resident.LastName}
                  </Text>
                ))}
              </Flex>
            )}
          </Flex>
        </Flex>
      </StyledModalBody>
      <ButtonBodyVariant
        onClick={() => openModal('AddOrEditLicensePlateModal', { isEditing: true, ...data })}>
        {t('Edit')}
      </ButtonBodyVariant>
    </StyledModal>
  );
};

export const TitleText = styled.p`
  color: ${Palette.BLACK};
  font-family: ${Fonts.RED_HAT_FONT};
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.5rem;
  text-transform: capitalize;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  margin: 0;
`;

export const Text = styled.p`
  display: inline;
  width: fit-content;
  color: ${Palette.BLACK};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;

  ${({ clickable }) =>
    clickable &&
    `
      cursor: pointer;
      text-decoration: underline;
    `}
`;

export default LicensePlateDetailsModal;
