import React from "react";

const ImageIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="59" height="59" viewBox="0 0 59 59" fill="none" {...props}>
      <path d="M46.7083 7.375H12.2917C9.57627 7.375 7.375 9.57627 7.375 12.2917V46.7083C7.375 49.4237 9.57627 51.625 12.2917 51.625H46.7083C49.4237 51.625 51.625 49.4237 51.625 46.7083V12.2917C51.625 9.57627 49.4237 7.375 46.7083 7.375Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20.896 24.5833C22.9325 24.5833 24.5835 22.9323 24.5835 20.8958C24.5835 18.8592 22.9325 17.2083 20.896 17.2083C18.8594 17.2083 17.2085 18.8592 17.2085 20.8958C17.2085 22.9323 18.8594 24.5833 20.896 24.5833Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M51.6248 36.8749L39.3332 24.5833L12.2915 51.6249" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default ImageIcon;
