import { createReducer } from '@reduxjs/toolkit';
import {
  GET_SERVICES,
  GET_SERVICE,
  ADD_COMMENT,
  GET_COMMENT_ID,
  ADD_COMMENT_DYNAMIC,
  FILTER_SERVICES,
  SERVICE_CATEGORY,
  GET_AMENITY_TYPES,
  SET_CURRENT_AMENITY_TYPE,
  UPDATE_SERVICE,
  CREATE_SERVICE,
  CLEAR_ERROR,
  GET_SERVICE_COMMENTS,
  SEARCH_REQUEST,
  COMPLETE_SERVICE,
  CLEAR_SERVICE,
  SET_ASSIGNED_OPERATOR_PROCESS,
  RESET_SERVICE_PAGINATION,
  CLEAR_SERVICE_LIST,
  SET_SERVICE_STATUS_FILTER,
  SET_SERVICE_SEARCH,
  SYNC_YARDI_REQUESTS,
  GET_SERVICE_CATEGORY,
  GET_SERVICE_CATEGORIES
} from '../constants';
import { requestSuccess, requestPending, requestFail } from '../../utils/status';
import { USER_TYPE_MAP, ROLE_MAP } from '../../utils/mappings';
import { RequestStatus, convertServiceStatusToId } from '../../views/services/constant';
import { UserRole } from '../../views/users/constant';
import { categoryTypes } from '../../views/services/categories';

const initialState = {
  servicesList: [],
  status: '',
  serviceCategoriesStatus: '',
  serviceStatus: '',
  service: {},
  comments: [],
  commentId: null,
  newCreatedCmntId: null,
  categories: [],
  error: '',
  search: '',
  amenityTypes: [],
  assignOperatorProcess: '',
  currentAmenityType: 0,
  pagination: {
    currentPage: 1,
    perPage: null,
    total: null
  },
  searchStatus: convertServiceStatusToId(RequestStatus.OPEN)
};

export default createReducer(initialState, {
  [SET_CURRENT_AMENITY_TYPE]: (state, { payload }) => {
    return {
      ...state,
      currentAmenityType: payload.amenityId
    };
  },

  [CLEAR_SERVICE]: (state) => ({
    ...state,
    service: {}
  }),

  [SET_SERVICE_SEARCH]: (state, { payload }) => ({
    ...state,
    search: payload
  }),

  [CLEAR_ERROR]: (state) => ({
    ...state,
    error: null
  }),

  [GET_COMMENT_ID]: (state, { payload }) => ({
    ...state,
    commentId: payload
  }),

  [FILTER_SERVICES]: (state, { payload }) => ({
    ...state,
    servicesList: payload
  }),

  [ADD_COMMENT_DYNAMIC]: (state, { payload }) => {
    const date = new Date().toISOString();
    const residentType = USER_TYPE_MAP.filter(
      (item) => String(item.id) === String(localStorage.getItem('type_id'))
    );
    const role = ROLE_MAP.filter(
      (item) => String(item.id) === String(localStorage.getItem('roleId'))
    );

    return {
      ...state,
      comments: [
        ...state.comments,
        {
          id: state.newCreatedCmntId,
          first_name: localStorage.getItem('firstname'),
          last_name: localStorage.getItem('lastname'),
          user_type:
            Boolean(role[0]?.value) && role[0]?.id === UserRole.USER
              ? residentType[0].value
              : role[0].value,
          create_date: date,
          avatar: localStorage.getItem('avatar'),
          comment: payload
        }
      ]
    };
  },

  [SET_ASSIGNED_OPERATOR_PROCESS]: (state, { payload }) => {
    return {
      ...state,
      assignOperatorProcess: payload
    };
  },
  [RESET_SERVICE_PAGINATION]: (state, {}) => {
    return {
      ...state,
      pagination: {
        currentPage: 1,
        perPage: null,
        total: null
      }
    };
  },
  [SET_SERVICE_STATUS_FILTER]: (state, { payload }) => {
    return {
      ...state,
      searchStatus: payload.status
    };
  },
  [requestSuccess(SEARCH_REQUEST)]: (state, { payload }) => ({
    ...state,
    servicesList: payload.data,
    status: requestSuccess(SEARCH_REQUEST),
    pagination: {
      currentPage: payload.current_page,
      perPage: payload.per_page,
      total: payload.total
    },
    error: ''
  }),

  [requestPending(SEARCH_REQUEST)]: (state, { payload }) => ({
    ...state,
    status: requestPending(SEARCH_REQUEST)
  }),

  [requestFail(SEARCH_REQUEST)]: (state, { payload }) => ({
    ...state,
    servicesList: [],
    status: requestFail(SEARCH_REQUEST),
    error: payload?.data?.message
  }),

  [requestSuccess(SYNC_YARDI_REQUESTS)]: (state) => ({
    ...state,
    status: requestSuccess(SYNC_YARDI_REQUESTS),
    error: ''
  }),

  [requestPending(SYNC_YARDI_REQUESTS)]: (state) => ({
    ...state,
    status: requestPending(SYNC_YARDI_REQUESTS)
  }),

  [requestFail(SYNC_YARDI_REQUESTS)]: (state, { payload }) => ({
    ...state,
    status: requestFail(SYNC_YARDI_REQUESTS),
    error: payload?.data?.message
  }),

  [requestSuccess(GET_SERVICE_COMMENTS)]: (state, { payload }) => ({
    ...state,
    error: '',
    service: { ...state.service, comments: [...state.service.comments, ...payload] },
    comments: [...state.comments, ...payload],
    status: requestSuccess(GET_SERVICE_COMMENTS)
  }),

  [requestPending(GET_SERVICE_COMMENTS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_SERVICE_COMMENTS)
  }),

  [requestFail(GET_SERVICE_COMMENTS)]: (state, { payload }) => ({
    ...state,
    error: payload?.data?.message,
    status: requestFail(GET_SERVICE_COMMENTS)
  }),

  [requestSuccess(CREATE_SERVICE)]: (state, { payload }) => ({
    ...state,
    error: '',
    status: requestSuccess(CREATE_SERVICE)
  }),
  [requestPending(CREATE_SERVICE)]: (state, { payload }) => ({
    ...state,
    status: requestPending(CREATE_SERVICE)
  }),

  [requestFail(CREATE_SERVICE)]: (state, { payload }) => ({
    ...state,
    error: payload?.data.message,
    status: requestFail(CREATE_SERVICE)
  }),

  [requestSuccess(UPDATE_SERVICE)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(UPDATE_SERVICE)
  }),
  [requestPending(UPDATE_SERVICE)]: (state, { payload }) => ({
    ...state,
    status: requestPending(UPDATE_SERVICE)
  }),

  [requestFail(UPDATE_SERVICE)]: (state, { payload }) => ({
    ...state,
    status: requestFail(UPDATE_SERVICE)
  }),

  [requestSuccess(COMPLETE_SERVICE)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(COMPLETE_SERVICE)
  }),
  [requestPending(COMPLETE_SERVICE)]: (state, { payload }) => ({
    ...state,
    status: requestPending(COMPLETE_SERVICE)
  }),

  [requestFail(COMPLETE_SERVICE)]: (state, { payload }) => ({
    ...state,
    status: requestFail(COMPLETE_SERVICE)
  }),

  [requestSuccess(GET_SERVICES)]: (state, { payload }) => {
    return {
      ...state,
      servicesList: payload?.data,
      pagination: {
        currentPage: payload?.pagination['pagination-current-page'],
        perPage: payload?.pagination['pagination-per-page'],
        total: payload?.pagination['pagination-total']
      },

      status: requestSuccess(GET_SERVICES)
    };
  },

  [requestPending(GET_SERVICES)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_SERVICES)
  }),

  [requestFail(GET_SERVICES)]: (state, { payload }) => ({
    ...state,
    servicesList: [],
    status: requestFail(GET_SERVICES)
  }),

  [requestSuccess(GET_SERVICE)]: (state, { payload }) => ({
    ...state,
    service: payload,
    comments: payload.comments,
    status: requestSuccess(GET_SERVICE),
    serviceStatus: requestSuccess(GET_SERVICE)
  }),

  [requestPending(GET_SERVICE)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_SERVICE),
    serviceStatus: requestPending(GET_SERVICE)
  }),

  [requestFail(GET_SERVICE)]: (state, { payload }) => ({
    ...state,
    service: {},
    comments: [],
    status: requestFail(GET_SERVICE),
    serviceStatus: requestFail(GET_SERVICE),
    error: payload?.data?.message
  }),

  [requestSuccess(ADD_COMMENT)]: (state, { payload }) => ({
    ...state,
    newCreatedCmntId: payload.new_id,
    status: requestSuccess(ADD_COMMENT)
  }),

  [requestFail(ADD_COMMENT)]: (state, { payload }) => ({
    ...state,
    status: requestFail(ADD_COMMENT)
  }),

  [requestSuccess(GET_SERVICE_CATEGORIES)]: (state, { payload }) => ({
    ...state,
    categories: payload?.filter((item) => item?.ID !== categoryTypes.SECURITY),
    serviceCategoriesStatus: requestSuccess(GET_SERVICE_CATEGORIES)
  }),

  [requestPending(GET_SERVICE_CATEGORIES)]: (state) => ({
    ...state,
    serviceCategoriesStatus: requestPending(GET_SERVICE_CATEGORIES)
  }),

  [requestFail(GET_SERVICE_CATEGORIES)]: (state) => ({
    ...state,
    categories: [],
    serviceCategoriesStatus: requestFail(GET_SERVICE_CATEGORIES)
  }),

  [requestSuccess(SERVICE_CATEGORY)]: (state, { payload }) => ({
    ...state,
    categories: payload?.filter((item) => item?.ID !== categoryTypes.SECURITY),
    status: requestSuccess(SERVICE_CATEGORY)
  }),

  [requestFail(SERVICE_CATEGORY)]: (state, { payload }) => ({
    ...state,
    categories: [],
    status: requestFail(SERVICE_CATEGORY)
  }),

  [requestSuccess(GET_AMENITY_TYPES)]: (state, { payload }) => ({
    ...state,
    amenityTypes:
      payload?.map((data) => ({ ...data, ID: data?.id, Description: data?.description })) || [],
    status: requestSuccess(GET_AMENITY_TYPES)
  }),

  [requestFail(GET_AMENITY_TYPES)]: (state, { payload }) => ({
    ...state,
    amenityTypes: [],
    status: requestFail(GET_AMENITY_TYPES)
  }),
  [CLEAR_SERVICE_LIST]: (state) => ({
    ...state,
    servicesList: []
  })
});
