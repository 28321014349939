import React from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StyledModal, StyledModalBody, ModalHeader } from '../../../components/Modals';
import AddEditDeliveryForm from './AddEditDeliveryForm';

const AddEditDelivery = () => {
  const { t } = useTranslation();
  const modal = useSelector((state) => state.headerModal.modal);
  const deliveryData = useSelector((state) => state.headerModal.data);

  return (
    <StyledModal show={modal === 'addEditDelivery'} width="548px">
      <ModalHeader title={deliveryData ? t('Edit Delivery') : t('New Delivery')} />
      <StyledModalBody>
        <AddEditDeliveryForm delivery={deliveryData} />
      </StyledModalBody>
    </StyledModal>
  );
}

export default AddEditDelivery;
