import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setToast } from '../../../../redux/actions/window';
import { Formik } from 'formik';
import { CForm, CFormGroup, CCol } from '@coreui/react';
import * as Yup from 'yup';
import LoadingAvatar from '../../../../components/LoadingAvatar';
import { changeOpenedModal } from '../../../../redux/actions/headerModal';
import ModalFooter from '../../../../components/ModalFooter';
import { useTranslation } from 'react-i18next';
import { LOADING_BLUE } from '../../../../utils/colorCode';
import {
  InputSeparator,
  SettingsModalBoldText,
  SettingsModalInput
} from '../../../settings/SettingsModals';
import { ModalHeader, StyledModal, StyledModalBody } from '../../../../components/Modals';
import { editSuite } from '../../../../redux/actions/suites';
import { getBuildingSuites } from '../../../../redux/actions/building';
import { set } from 'lodash';

const EditSuite = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const unitLabels = {
    building: t('Building'),
    name: t('Unit number'),
    area: t('Area'),
  };
  const { modal } = useSelector((state) => state.headerModal);
  const { error, status } = useSelector((state) => state.unit);
  const suite = useSelector((state) => state.suites.suite);
  const { building, suitesPagination } = useSelector((state) => state.building);
  const searchText = useSelector((state) => state.suites.searchText);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validationSchema = function(values) {
    return Yup.object().shape({
      name: Yup.string()
        .matches(/^([a-zA-Z0-9\s]+)$/, t('Incorrect Format and only alphanumeric values'))
        .required(t('Unit Number is required')),
      area: Yup.number()
        .moreThan(-1, t("Can't be negative!"))
        .max(999999, t('Not more than 6 characters'))
        .typeError(t('Only numbers'))
        .notRequired(),
    });
  };

  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);

      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  };

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR]
      };
    }, {});
  };

  const onSubmit = (values) => {
    setIsSubmitting(true);
    const payload = {
      owned: 0,
      name: values.name,
      area: values?.area || 0,
    };

    dispatch(
      editSuite({
        id: values.id,
        body: payload,
        success: () => {
          dispatch(getBuildingSuites({
            orgId: building?.org_id,
            buildingId: building?.id,
            page: suitesPagination?.currentPage,
            searchText,
            success: () => {
              onClose();
              dispatch(
                setToast({
                  toastShow: true,
                  toastMessage: t('Unit info has been successfully updated!')
                })
              );
              setIsSubmitting(false);
            },
          }));
        },
        fail: (res) => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: res?.data?.message || t('Unit Updating Failed!'),
              modal: 'errorToast'
            })
          );
          setIsSubmitting(false);
        },
      })
    );
  };

  const onClose = () => {
    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={suite}
        validate={validate(validationSchema)}
        onSubmit={onSubmit}>
        {({
          dirty,
          resetForm,
          values,
          isValid,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue
        }) => (
          <CForm onSubmit={handleSubmit} noValidate name="EditUnitForm">
            <StyledModal
              show={modal === 'editSuite'}
              onClose={() => {
                resetForm();
                onClose();
              }}
              closeOnBackdrop={false}>
              <ModalHeader title={t('Edit Suite')} />
              <SettingsModalBoldText cssOverride={'color: gray; margin-top: -20px'}>{building.name}</SettingsModalBoldText>
              {values && Object.keys(values).length ? (
                <StyledModalBody>
                  <CFormGroup row>
                    <CCol>
                      <CFormGroup>
                        <InputSeparator>
                          <SettingsModalBoldText>Name</SettingsModalBoldText>
                          <SettingsModalInput
                            label={unitLabels.name}
                            type="string"
                            id="name"
                            name="name"
                            placeholder={t('Unit Num...')}
                            valid={!errors.name && touched.name}
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            error={errors.name}
                            includedSearchIcon={false}
                            cssOverride={{
                              padding: '0px 10px 0px 20px',
                              height: '56px'
                            }}
                          />
                        </InputSeparator>
                      </CFormGroup>
                    </CCol>
                  </CFormGroup>
                  <CFormGroup row>
                    <CCol>
                      <CFormGroup>
                        <InputSeparator>
                          <SettingsModalBoldText>Area</SettingsModalBoldText>
                          <SettingsModalInput
                            onKeyDown={(e) =>
                              ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
                            }
                            label={unitLabels.area}
                            type="number"
                            id="area"
                            name="area"
                            placeholder={t('Area...')}
                            valid={!errors.area}
                            invalid={touched.area && !!errors.area}
                            onChange={(e) =>
                              e.target.value === 0 || e.target.value === ''
                                ? setFieldValue('area', undefined)
                                : setFieldValue('area', parseInt(e.target.value))
                            }
                            onBlur={handleBlur}
                            value={values.area}
                            error={errors.area}
                          />
                        </InputSeparator>
                      </CFormGroup>
                    </CCol>
                  </CFormGroup>
                </StyledModalBody>
              ) : (
                <LoadingAvatar color={LOADING_BLUE} />
              )}

              <ModalFooter
                text={t('Edit')}
                error={error}
                handleCancel={handleCancel}
                onClick={() => { }}
                status={status === 'EDIT_UNIT_PENDING' ? true : false}
                style={
                  (dirty) &&
                    isValid &&
                    !isSubmitting
                    ? { cursor: 'pointer' }
                    : { cursor: 'not-allowed' }
                }
                disabled={
                  !((dirty) && isValid) ||
                  isSubmitting
                }
                className={
                  (dirty) && isValid
                    ? 'btn btn-primary'
                    : 'btn'
                }
              />
            </StyledModal>
          </CForm>
        )}
      </Formik>
    </>
  );
};

export default EditSuite;
