import { createAction } from "@reduxjs/toolkit";
import {
  CLEAR_DELIVERY, 
  CLEAR_ERROR, 
  UPDATE_DELIVERY, 
  CREATE_DELIVERY, 
  UPLOAD_DELIVERY_IMAGE
} from "../constants";

export const clearDelivery = createAction(CLEAR_DELIVERY);
export const clearDeliveryError = createAction(CLEAR_ERROR);
export const updateDelivery = createAction(UPDATE_DELIVERY);
export const createDelivery = createAction(CREATE_DELIVERY);
export const uploadDeliveryImage = createAction(UPLOAD_DELIVERY_IMAGE);

export default { 
  clearDelivery, 
  clearDeliveryError, 
  updateDelivery,
  createDelivery,
  uploadDeliveryImage
};
