import React, {useEffect, useMemo, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Palette } from '../../../components/utils';
import { MdLabelImportant } from 'react-icons/md';
import {
  InputSeparator,
  SettingsModalBoldText,
  SettingsModalInput
} from '../../settings/SettingsModals';
import { closeActiveModal, openModal } from '../../../helper/modals';
import {
  ToggleItem,
  ToggleItemComponentContainer,
  ToggleItemDescription,
  ToggleItemTextContainer,
  ToggleItemTitle
} from '../../manage-users/ManageUserComponents';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '../../../components/ErrorMessage';
import { ErrorText } from '../../../components/utils/AvatarCropper';
import { FeatureSwitch } from '../../offers/styles';
import {
  ModalButtons,
  ModalHeader,
  StyledModal,
  StyledModalBody
} from '../../../components/Modals';
import { range } from 'lodash';
import { Select, SelectItem } from '../../../components/ui/Select';
import {addBuilding, 
  incrementBuildingEventCount, 
  setCachedBuildings, 
  setGlobalBuilding, 
  setGlobalBuildingId, 
  setGlobalBuildingStatus, 
  setGlobalSelectedBuilding, 
  setLoadingCachedBuildings, 
  setNewBuilding,
  getBuildings,
  clearNewBuilding
} from '../../../redux/actions/building';
import {UserRole} from '../../users/constant';
import {changeOpenedModal} from '../../../redux/actions/headerModal';
import {searchTable, setSearchPagination, setSearchState} from '../../../redux/actions/search';
import {setUserSearch} from '../../../redux/actions/user';
import {setToast} from '../../../redux/actions/window';
import {SET_GLOBAL_BUILDING} from '../../../redux/constants';
import {requestPending} from '../../../utils/status';

const USER_PIN_LENGTH_DROPDOWN_OPTIONS = range(4, 11);

const ConfigFieldsMapping = {
  enablePost: "enable_posts",
  enableOffers: "enable_offers",
  enableBooking: "enable_booking",
  enableLockScreen: "enable_lock_screen",
  enableServiceRequest: "enable_service_requests",
  userPinLength: "user_pin_len",
  enableReadOnlyUsers: "readonly_users",
  enableShowUserPinResident: "show_user_pin_resident",
  enableShowSuperPinAdmin: "show_user_pin_admin",
  showUserPinToAdmin: "show_user_pin_to_admin",
  serviceRequestURL: "service_request_url",
  enableEdit: "org_allow_edits",
  lockProvider: "lock_provider",
  enableSecurity: "security_enabled",
  enableMultiLock: "suite_enabled",
  enableGlobalMasterPin: "enable_global_master_pin",
  enableCustomServiceRequestCategories: "enable_custom_service_request_categories",
  enableDeliveries: "enable_deliveries",
  enableLicensePlates: "enable_license_plates",
  enableSecurityCameras: "enable_security_cameras",
  enableThermostats: "enable_thermostats",
  enableLeakDetectors: "enable_leak_detectors",
  enableAkuvox: "enable_akuvox",
  enableAddGuests: "enable_add_guests",
};

const AddBuildingStep2 = () => {
  const { t } = useTranslation();
  const { modal } = useSelector((state) => state.headerModal);
  const { orgId, roleId } = useSelector((state) => state.auth);
  const { globalOrgId, organization } = useSelector((state) => state.Organization);
  const { newBuilding } = useSelector((state) => state.building);
  const searchState = useSelector((state) => state.search);
  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const schema = Yup.object().shape({
    enablePost: Yup.boolean().required(t('Posts Options is required')),
    enableOffers: Yup.boolean().required(t('Offers Options is required')),
    enableBooking: Yup.boolean().required(t('Bookings is required')),
    enableLockScreen: Yup.boolean().required(t('Lock Screen Option is required')),
    enableServiceRequest: Yup.boolean().required(t('Service Request Option is required')),
    enableReadOnlyUsers: Yup.boolean().required(t('Read Only Users Option is required')),
    enableShowSuperPinAdmin: Yup.boolean().required(t('Show Super Pin Admin Option is required')),
    enableEdit: Yup.boolean().required(t('Edit Option is required')),
    enableShowUserPinResident: Yup.boolean().required(
      t('Show User Pin Resident Option is required')
    ),
    enableSecurity: Yup.boolean().required(t('Enable Security Option is required')),
    enableMultiLock: Yup.boolean().required(t('Enable Multi Lock Option is required')),
    enableThermostats: Yup.boolean().required(t('Enable Thermostats Option is required')),
    enableLeakDetectors: Yup.boolean().required(t('Enable Leak Detectors Option is required')),
    enableCustomServiceRequestCategories: Yup.boolean().required(
      t('Enable Custom Service Request Categories Option is required')
    ),
    enableDeliveries: Yup.boolean().required(t('Enable Deliveries Option is required')),
    enableAkuvox: Yup.boolean().required(t('Enable Akuvox Option is required')),
    enableSecurityCameras: Yup.boolean().required(t('Enable Security Cameras Option is required')),
    showUserPinToAdmin: Yup.boolean().required(t('Show User Pin To Admin Option is required')),
    enableAddGuests: Yup.boolean().required(t('Enable Add Guests Option is required')),
    serviceRequestURL: Yup.string().url('Invalid URL'),

    userPinLength: Yup.number()
      .required('User Pin Length is required')
      .typeError('User Pin must be a number')
      .min(4, t('Minimum Pin Length is 4'))
      .max(10, t('Maximum Pin Length is 10'))
  });

  const buildingConfig = useMemo(()=>{
    if (Boolean(newBuilding?.config)) {
      return newBuilding.config;
    } else {
      return organization?.config;
    }
  },[
    newBuilding,
    organization
  ]);

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({ 
    resolver: yupResolver(schema), 
    mode: 'onSubmit',
    defaultValues: { 
      enablePost: buildingConfig?.enable_posts,
      enableOffers: buildingConfig?.enable_offers,
      enableBooking: buildingConfig?.enable_booking,
      enableLockScreen: buildingConfig?.enable_lock_screen,
      enableServiceRequest: buildingConfig?.enable_service_requests,
      enableReadOnlyUsers: buildingConfig?.readonly_users,
      enableShowSuperPinAdmin: buildingConfig?.show_user_pin_admin,
      enableEdit: buildingConfig?.org_allow_edits,
      enableMultiLock: buildingConfig?.suite_enabled,
      enableThermostats: buildingConfig?.enable_thermostats,
      enableLeakDetectors: buildingConfig?.enable_leak_detectors,
      enableCustomServiceRequestCategories: buildingConfig?.enable_posts,
      enableDeliveries: buildingConfig?.enable_deliveries,
      enableAkuvox: buildingConfig?.enable_akuvox,
      enableSecurityCameras: buildingConfig?.enable_security_cameras,
      enableShowUserPinResident: buildingConfig?.show_user_pin_resident,
      showUserPinToAdmin: buildingConfig?.show_user_pin_admin,
      enableSecurity: buildingConfig?.security_enabled,
      userPinLength: buildingConfig?.user_pin_len,
      serviceRequestURL: buildingConfig?.service_request_url,
      lockProvider: buildingConfig?.lock_provider,
      enableGlobalMasterPin: buildingConfig?.enable_global_master_pin,
      enableCustomServiceRequestCategories: buildingConfig?.enable_custom_service_request_categories,
      enableLicensePlates: buildingConfig?.enable_license_plates,
      enableAddGuests: buildingConfig?.enable_add_guests,
    }
  });

  const onSubmit = (data) => {
    setIsSubmitting(true);

    const buildingData = {
      ...newBuilding,
      config: {
        enable_posts: data.enablePost,
        enable_offers: data.enableOffers,
        enable_booking: data.enableBooking,
        enable_lock_screen: data.enableLockScreen,
        enable_service_requests: data.enableServiceRequest,
        user_pin_len: data.userPinLength,
        readonly_users: data.enableReadOnlyUsers,
        show_user_pin_resident: data.enableShowUserPinResident,
        show_user_pin_admin: data.enableShowSuperPinAdmin,
        show_user_pin_to_admin: data.showUserPinToAdmin,
        service_request_url: data.serviceRequestURL,
        org_allow_edits: data.enableEdit,
        lock_provider: data.lockProvider,
        security_enabled: data.enableSecurity,
        suite_enabled: data.enableMultiLock,
        enable_global_master_pin: data.enableGlobalMasterPin,
        enable_custom_service_request_categories: data.enableCustomServiceRequestCategories,
        enable_deliveries: data.enableDeliveries,
        enable_license_plates: data.enableLicensePlates,
        enable_security_cameras: data.enableSecurityCameras,
        enable_thermostats: data.enableThermostats,
        enable_leak_detectors: data.enableLeakDetectors,
        enable_akuvox: data.enableAkuvox,
        enable_add_guests: data.enableAddGuests,
      }
    }

    dispatch(setNewBuilding(buildingData))

    dispatch(
      addBuilding({
        body: buildingData,
        success: (res) => {
          dispatch(incrementBuildingEventCount());
          if (roleId === UserRole.SUPER_ADMIN) {
            dispatch(setLoadingCachedBuildings({ orgId, isLoading: true }));
            dispatch(
              setCachedBuildings({
                id: orgId,
                success: () => {
                  dispatch(setLoadingCachedBuildings({ orgId, isLoading: false }));
                }
              })
            );
          }

          const valueOrgId = parseInt(buildingData.org_id, 10);

          if (globalOrgId === valueOrgId && roleId === UserRole.SUPER_ADMIN) {
            dispatch(
              setGlobalBuildingStatus({
                status: requestPending(SET_GLOBAL_BUILDING)
              })
            );
            dispatch(
              setGlobalBuilding({
                id: globalOrgId
              })
            );
            dispatch(
              setGlobalBuildingId({
                id: res.data.id
              })
            );
            dispatch(setGlobalSelectedBuilding(res.data));
          } else if (parseInt(orgId, 10) === valueOrgId && roleId === UserRole.ADMIN) {
            dispatch(
              setGlobalBuildingStatus({
                status: requestPending(SET_GLOBAL_BUILDING)
              })
            );
            dispatch(
              setGlobalBuilding({
                id: orgId
              })
            );
          }
          dispatch(
            setGlobalBuildingId({
              id: res.data.id
            })
          );
          dispatch(setGlobalSelectedBuilding(res.data));

          dispatch(
            changeOpenedModal({
              modal: ''
            })
          );
          dispatch(
            searchTable({
              entity: 'buildings',
              orgID: globalOrgId,
              body: {
                search: searchState.search,
                minCreationDate: searchState.minCreationDate,
                maxCreationDate: searchState.maxCreationDate,
                category: searchState.category,
                building_id: searchState.building_id,

                filters: searchState.filters,
                ordering: searchState.ordering,
                pagination: searchState.pagination
              },
              success: (res) => {
                dispatch(
                  setSearchState({
                    currentData: res.data.data ?? [],
                    currentPaginationData: res.data.pagination
                  })
                );

                if (!res.data.data) {
                  dispatch(setUserSearch({ page: 1 }));
                  dispatch(
                    setSearchPagination({
                      page: 1,
                      limit: 20
                    })
                  );
                }
              }
            })
          );
          dispatch(
            getBuildings({
              id: buildingData.org_id,
              page: 1,
              success: (buildings) => {
                if (globalOrgId) {
                  // dispatch(setGlobalBuildingId({ globalBuildingId: buildings.data.id }));
                }
              }
            })
          );
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Building has been successfully created!')
            })
          );

          dispatch(clearNewBuilding({}));
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Building creating Failed!'),
              modal: 'errorToast'
            })
          );
        },
        finally: () => {
          setIsSubmitting(false);
        }
      })
    );
  };
  
  const handleOnChange = (event, inputName, callback) =>{
    let newValue;
    if(typeof event === "number") {
      newValue = event;
    } else if (event.target.type === "checkbox") {
      newValue = event.target.checked;
    } else {
      newValue = event.target.value;
    }

    callback(event);

    const fieldName = ConfigFieldsMapping[inputName];
    if (!fieldName) return;

    dispatch(
      setNewBuilding({
        ...newBuilding,
        config: {
          ...newBuilding.config,
          [fieldName]: newValue,
        }
      })
    )
  };

  return (
    <form>
      <StyledModal
        show={modal === 'addBuildingStep2'}
        closeOnBackdrop={false}
        onClose={closeActiveModal}>
        <ModalHeader title={t('Add Building')} />

        <StyledModalBody>
          <Controller
            control={control}
            name="enablePost"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Enable Post')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Enable Post')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={(event) => handleOnChange(event, name, onChange)}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />
          <Controller
            control={control}
            name="enableOffers"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Enable Offers')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Enable Offers')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={(event) => handleOnChange(event, name, onChange)}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />
          <Controller
            control={control}
            name="enableBooking"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Enable Booking')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Enable Booking')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={(event) => handleOnChange(event, name, onChange)}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />
          <Controller
            control={control}
            name="enableLockScreen"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Enable Lock Screen')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Enable Lock Screen')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={(event) => handleOnChange(event, name, onChange)}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />
          <Controller
            control={control}
            name="enableServiceRequest"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Enable Service Requests')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Enable Service Requests')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={(event) => handleOnChange(event, name, onChange)}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />
          <Controller
            control={control}
            name="enableReadOnlyUsers"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Read Only Users')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Read Only Users')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={(event) => handleOnChange(event, name, onChange)}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />
          <Controller
            control={control}
            name="enableShowSuperPinAdmin"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Show Super PIN (Admin)')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Show Super PIN (Admin)')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={(event) => handleOnChange(event, name, onChange)}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />
          <Controller
            control={control}
            name="enableEdit"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Allow Edit')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Allow Edit')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={(event) => handleOnChange(event, name, onChange)}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />
          <Controller
            control={control}
            name="enableShowUserPinResident"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Show User Pin (Resident)')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Show User Pin (Resident)')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={(event) => handleOnChange(event, name, onChange)}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />
          <Controller
            control={control}
            name="enableSecurity"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Enable Security')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Enable Security')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={(event) => handleOnChange(event, name, onChange)}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />
          <Controller
            control={control}
            name="enableThermostats"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Enable Thermostats')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Enable Thermostats')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={(event) => handleOnChange(event, name, onChange)}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />
          <Controller
            control={control}
            name="enableLeakDetectors"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Enable Leak Detectors')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Enable Leak Detectors')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={(event) => handleOnChange(event, name, onChange)}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />

          <Controller
            control={control}
            name="enableCustomServiceRequestCategories"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Enable Custom Service Request Categories')}</ToggleItemTitle>
                  <ToggleItemDescription>
                    {t('Enable Custom Service Request Categories')}
                  </ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={(event) => handleOnChange(event, name, onChange)}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />

          <Controller
            control={control}
            name="enableDeliveries"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Enable Deliveries')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Enable Deliveries')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={(event) => handleOnChange(event, name, onChange)}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />

          <Controller
            control={control}
            name="enableAkuvox"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Enable Akuvox')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Enable Akuvox')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={(event) => handleOnChange(event, name, onChange)}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />

          <Controller
            control={control}
            name="enableSecurityCameras"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Enable Security Cameras')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Enable Security Cameras')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={(event) => handleOnChange(event, name, onChange)}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />

          <Controller
            control={control}
            name="showUserPinToAdmin"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Show User Pin To Admin')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Show User Pin To Admin')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={(event) => handleOnChange(event, name, onChange)}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />
          <Controller
            control={control}
            name="enableMultiLock"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Enable Multi Lock')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Enable Multi Lock')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={(event) => handleOnChange(event, name, onChange)}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />

          <Controller
            control={control}
            name="enableLicensePlates"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Enable License Plates')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Enable License Plates')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={(event) => handleOnChange(event, name, onChange)}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />

          <Controller
            control={control}
            name="enableAddGuests"
            render={({ field: { onChange, value, name } }) => (
              <ToggleItem>
                <ToggleItemTextContainer>
                  <ToggleItemTitle>{t('Enable Add Guests')}</ToggleItemTitle>
                  <ToggleItemDescription>{t('Enable Add Guests')}</ToggleItemDescription>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </ToggleItemTextContainer>
                <ToggleItemComponentContainer>
                  <FeatureSwitch
                    onChange={onChange}
                    checked={value}
                    value={value}
                    shape="pill"
                    color="primary"
                  />
                </ToggleItemComponentContainer>
              </ToggleItem>
            )}
          />

          <InputSeparator>
            <SettingsModalBoldText>{t('Service Request URL')}</SettingsModalBoldText>
            <Controller
              control={control}
              name="serviceRequestURL"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <>
                  <SettingsModalInput
                    onBlur={onBlur}
                    onChange={(event) => handleOnChange(event, name, onChange)}
                    value={value}
                    inputRef={ref}
                    type="text"
                    name={name}
                    error={!!errors.serviceRequestURL}
                    placeholder={t('Service Request URL')}
                    autoComplete="off"
                  />
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </>
              )}
            />
          </InputSeparator>
          <InputSeparator>
            <SettingsModalBoldText>{t('User PIN Length')}</SettingsModalBoldText>
            <Controller
              control={control}
              name="userPinLength"
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <>
                  <Select
                    onChange={(event) => handleOnChange(event, name, onChange)}
                    defaultValue={value}
                    value={value}
                    placeholder={t('Please Select')}
                    error={!!errors.userPinLength}>
                    {USER_PIN_LENGTH_DROPDOWN_OPTIONS.map((pinNum) => (
                      <SelectItem key={pinNum} value={pinNum}>
                        {pinNum}
                      </SelectItem>
                    ))}
                  </Select>
                  <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                  />
                </>
              )}
            />
          </InputSeparator>
          <div className="flex align-items-center" style={{ gap: '20px' }}>
            <MdLabelImportant size={55} color={Palette.HAZY_BLUE} />
            <ToggleItemDescription>
              {t('PIN length must be a minimum of 4 and a maximum of 10 characters long.')}
            </ToggleItemDescription>
          </div>
        </StyledModalBody>
        <ModalButtons
          leftButtonText={t('Back')}
          leftButtonClick={() => openModal('addBuilding')}
          rightButtonText={t('Add')}
          rightButtonClick={handleSubmit(onSubmit)}
          disabled={isSubmitting}
        />
      </StyledModal>
    </form>
  );
};

export default AddBuildingStep2;
