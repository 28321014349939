import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import moment from 'moment';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { HiOutlineArrowLeft } from 'react-icons/hi2';

import { Form, FormControl, FormField, FormItem, FormMessage } from '../../../components/ui/Form';
import ServiceDetailModal from './ServiceDetailModal';
import { Flex } from '../../../components/ui/Flex';
import { StyledModalBody, StyledModalTitle } from '../../../components/Modals';
import { TextArea } from '../../../components/ui/TextArea';
import { Fonts, Palette } from '../../../components/utils';
import { addComment, addCommentDynamic, getServiceComments } from '../../../redux/actions/services';
import { setToast } from '../../../redux/actions/window';
import { IoSendSharp } from 'react-icons/io5';

const ServiceDetailChatModal = ({ onClose, onClickBack, isDisabled }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const userId = useSelector((state) => state.auth.user_id);
  const modal = useSelector((state) => state.headerModal.modal);
  const { commentId, service } = useSelector((state) => state.services);

  const chatLogContainerRef = useRef();
  const chatTextAreaRef = useRef();

  const schema = Yup.object().shape({
    message: Yup.string().required(t('Message is required'))
  });

  const form = useForm({ resolver: yupResolver(schema), defaultValues: { message: '' } });

  // this is to scroll the chat log container to the bottom when it is first rendered
  // setTimeout is used to ensure that the scrolling logic runs after the DOM is fully rendered
  useEffect(() => {
    const timer = setTimeout(() => {
      if (service.comments?.length > 0) {
        chatLogContainerRef.current.scrollTop = chatLogContainerRef.current.scrollHeight;
      }
    }, 0);

    return () => clearTimeout(timer);
  }, [service.comments.length]);

  useEffect(() => {
    const interval = setInterval(getLatestComments, 15000);

    return () => {
      clearInterval(interval);
    };
  });

  const getLatestComments = () => {
    const lastCommentId = service.comments[service.comments.length - 1]?.id;
    dispatch(getServiceComments({ id: service.id, commentId: lastCommentId || 0 }));
  };

  const submitOnEnter = async (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      const messageIsValid = await form.trigger('message');
      if (messageIsValid) {
        form.handleSubmit(onSubmit)();
      }
    }
  };

  const onSubmit = (values) => {
    dispatch(
      addComment({
        id: commentId.commentId,
        body: {
          comment: values.message
        },
        success: () => {
          dispatch(addCommentDynamic(values.message));
          getLatestComments();
          form.reset();
          resizeChatTextarea();
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Message not sent!'),
              modal: 'errorToast'
            })
          );
        }
      })
    );
  };

  // Resize chat box text area automatically to fit the text
  const resizeChatTextarea = () => {
    const chatTextArea = chatTextAreaRef.current;
    if (chatTextArea) {
      chatTextArea.style.height = 'auto';
      chatTextArea.style.height = `${chatTextArea.scrollHeight}px`;
    }
  };

  return (
    <ServiceDetailModal show={modal === 'serviceDetail'} side closeOnBackdrop onClose={onClose}>
      <ServiceRequestStyledModalBody>
        <ServiceRequestStyledModalHeader>
          <BackButtonIcon size={24} onClick={onClickBack} />
          <StyledModalTitle>{t('Chat')}</StyledModalTitle>
        </ServiceRequestStyledModalHeader>
        <ServiceRequestStyledModalContent innerRef={chatLogContainerRef}>
          {service.comments?.length > 0 ? (
            service.comments.map((message) => {
              const messageType = message.user_id == userId ? 'sent' : 'seen';
              return (
                <ChatBubble key={message.id} type={messageType}>
                  <Flex alignItems="center" justify="end" gap="4px">
                    <ChatBubbleUserNameText>
                      {message.first_name}{' '}
                      {message.last_name && `${message.last_name?.charAt(0).toUpperCase()}.`}
                    </ChatBubbleUserNameText>
                    <ChatBubbleUserRoleText>{message.user_type}</ChatBubbleUserRoleText>
                    <ChatBubbleUserRoleText>
                      {moment(message.create_date).format('MMM do, YYYY - hh:mm A')}
                    </ChatBubbleUserRoleText>
                  </Flex>
                  <ChatBubbleBody type={messageType}>
                    <ChatBubbleBodyText type={messageType}>{message.comment}</ChatBubbleBodyText>
                  </ChatBubbleBody>
                </ChatBubble>
              );
            })
          ) : (
            <NoCommentsText>{isDisabled ? t('Ticket closed') : t('No messages')}</NoCommentsText>
          )}
        </ServiceRequestStyledModalContent>
        <ChatBoxSendMessage>
          <Form {...form}>
            <form name="serviceReqChatForm" onSubmit={form.handleSubmit(onSubmit)}>
              <FormField
                control={form.control}
                name="message"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <ChatBoxSendMessageContainer>
                        <ChatBoxSendMessageTextArea
                          {...field}
                          ref={(e) => {
                            field.ref(e);
                            chatTextAreaRef.current = e
                          }}
                          onInput={resizeChatTextarea}
                          placeholder={t('Send a message')}
                          disabled={isDisabled || form.formState.isSubmitting}
                          onKeyDown={submitOnEnter}
                        />
                        <SendButton
                          disabled={isDisabled || form.formState.isSubmitting}
                          aria-label="Send message">
                          <IoSendSharp />
                        </SendButton>
                      </ChatBoxSendMessageContainer>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
        </ChatBoxSendMessage>
      </ServiceRequestStyledModalBody>
    </ServiceDetailModal>
  );
};

export default ServiceDetailChatModal;

const paddedStyles = css`
  padding: 20px 24px 20px 24px !important;
`;

const iconStyles = css`
  color: ${Palette.HAZY_BLUE};

  :hover:not(:disabled) {
    color: ${Palette.BLACK};
    cursor: pointer;
  }

  :disabled {
    opacity: 0.5;
  }
`;

const ServiceRequestStyledModalHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  ${paddedStyles}
`;

const ServiceRequestStyledModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;
`;

const ServiceRequestStyledModalContent = styled(StyledModalBody)`
  height: 100vh;
  flex-grow: 1;
  ${paddedStyles}
`;

const BackButtonIcon = styled(HiOutlineArrowLeft)`
  ${iconStyles}
`;

const ChatBubbleUserNameText = styled.p`
  color: ${Palette.BLACK};
  font-family: ${Fonts.OPEN_SANS_FONT};
  text-align: center;
  font-size: 8px;
  font-weight: 700;
  line-height: normal;
  margin: 0;
`;

const ChatBubbleUserRoleText = styled.p`
  color: ${Palette.BLACK_50};
  font-family: ${Fonts.OPEN_SANS_FONT};
  text-align: center;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  margin: 0;
`;

// use a prop to pass in the 'type' (color)
// depending on who's viewing it, it should display as blue or grey

const ChatBubble = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;

  ${({ type }) =>
    type === 'sent'
      ? css`
          padding-left: 30px;
        `
      : css`
          padding-right: 30px;
        `}
`;

const ChatBubbleBody = styled.div`
  display: flex;
  padding: 8px 16px;
  justify-content: left;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px 10px 10px 0px;

  ${({ type }) =>
    type === 'sent'
      ? css`
          background: ${Palette.HAZY_BLUE};
        `
      : css`
          background: ${Palette.SOLITUDE};
        `}
`;

const ChatBubbleBodyText = styled.p`
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 12px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  /* Preserve whitespace and newlines */
  white-space: pre-wrap;

  ${({ type }) =>
    type === 'sent'
      ? css`
          color: ${Palette.WHITE};
        `
      : css`
          color: ${Palette.BLACK};
        `}
`;

const ChatBoxSendMessage = styled.div`
  display: flex;
  padding: 14px 17px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-top: 0.5px solid ${Palette.STROKE_GREY};
  ${paddedStyles}

  & form {
    width: 100%;
  }
`;

const ChatBoxSendMessageContainer = styled.div`
  position: relative;
`;

const ChatBoxSendMessageTextArea = styled(TextArea)`
  color: ${Palette.BLACK};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  min-height: 58px;
  padding-right: 50px;

  ::placeholder {
    color: ${Palette.BLACK_50};
    font-size: 14px;
    font-weight: 400;
  }

  :focus {
    background-color: ${Palette.FIELD_TEXT};
    outline: none;
    border: none !important;
  }
`;

const SendButton = styled.button`
  position: absolute;
  right: 10px;
  bottom: 20px;
  background-color: transparent;
  border: none;

  ${iconStyles}
  & svg {
    width: 22px;
    height: 22px;
  }
`;

const NoCommentsText = styled.p`
  color: ${Palette.BLACK};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 16px;
  text-align: left;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin: 0;
`;
