import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  InputSeparator,
  SettingsModalBoldText,
  SettingsModalLightText
} from '../../settings/SettingsModals';
import { useDispatch, useSelector } from 'react-redux';
import {
  ToggleItem,
  ToggleItemComponentContainer,
  ToggleItemDescription,
  ToggleItemTextContainer,
  ToggleItemTitle
} from '../ManageUserComponents';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, FormProvider, useWatch } from 'react-hook-form';
import { ErrorMessage } from '../../../components/ErrorMessage';
import { ErrorText } from '../../../components/utils/AvatarCropper';
import {
  BUILDING_TYPE,
  DATE_FORMATS,
  hasPermission,
  USER_ROLE,
  USER_TYPE
} from '../../users/constant';
import {
  ConditionalField,
} from '../AddUserTypeModalFlow/ConditionalComponents';
import { FeatureSwitch } from '../../offers/styles';
import { Select, SelectItem } from '../../../components/ui/Select';
import {
  ModalButtons,
  ModalHeader,
  StyledModal,
  StyledModalBody
} from '../../../components/Modals';
import { CustomDatePicker } from '../../../components/CustomSearchBar';
import moment from 'moment-timezone';
import { clearConflictingDatesData, getConflictingDates } from '../../../redux/actions/unit';
import { Palette } from '../../../components/utils';
import { getFullDateWithOutTime, USER_TYPES } from '../../../helper';
import {clearBuilding} from '../../../redux/actions/building';
import {changeOpenedModal} from '../../../redux/actions/headerModal';
import {reactivateUser, setUser, setUserSearch} from '../../../redux/actions/user';
import {setToast} from '../../../redux/actions/window';
import {searchTable, setSearchPagination, setSearchState} from '../../../redux/actions/search';

const ReactivateUserModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { modal } = useSelector((state) => state.headerModal);
  const { roleId } = useSelector((state) => state.auth);
  const { conflictingMoveDates } = useSelector((state) => state.unit);
  const {building: selectedBuilding} = useSelector((state) => state.building);
  const units = useSelector((state) => state.unit);
  const {user: selectedUser} = useSelector((state) => state.user);
  const searchState = useSelector((state) => state.search);

  const isTemporaryResidency = (selectedBuilding) => selectedBuilding?.type === BUILDING_TYPE.TEMPORARY_RESIDENCY.id;
  const isResident = (selectedUser) => selectedUser?.type_id === USER_TYPES.resident;
  const isCoResident = (selectedUser) => selectedUser?.type_id === USER_TYPES['co-resident'];
  const isUserRole = (selectedUser) => selectedUser?.role_id === USER_ROLE.USER.id;
  const isGuestRole = (selectedUser) => selectedUser?.role_id === USER_ROLE.GUEST.id;

  const schema = Yup.object().shape({
    pending: Yup.boolean().required(),
    
    unit: Yup.mixed().when([], {
        is: () => isTemporaryResidency(selectedBuilding) && isUserRole(selectedUser),
        then: Yup.mixed().required('Unit is required'),
        otherwise: Yup.mixed().notRequired()
    }),

    moveInDate: Yup.string().when([], {
      is: () => isTemporaryResidency(selectedBuilding) && isResident(selectedUser),
      then: Yup.string().required('Move-in date is required for hotels'),
      otherwise: Yup.string()
    }),
  
    moveOutDate: Yup.string().when([], {
      is: () => isTemporaryResidency(selectedBuilding) && isResident(selectedUser),
      then: Yup.string().required('Move-out date is required for hotels'),
      otherwise: Yup.string()
    }),
  
    reservation: Yup.mixed().when([], {
      is: () => isTemporaryResidency(selectedBuilding) && isCoResident(selectedUser),
      then: Yup.object().shape({
        id: Yup.number().required('Reservation is required')
      }).typeError('Reservation is required').required('Reservation is required'),
      otherwise: Yup.mixed().notRequired()
    }),
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
        pending: true
    },
  });

  const moveInDateWatch = useWatch({ control: methods.control, name: 'moveInDate' });
  const moveOutDateWatch = useWatch({ control: methods.control, name: 'moveOutDate' });
  const unitWatch = useWatch({ control: methods.control, name: 'unit' });

  const search = () => {
    dispatch(
        searchTable({
          entity: 'user',
          orgID: selectedUser?.org_id,
          body: {
            search: searchState.search,
            minCreationDate: searchState.minCreationDate,
            maxCreationDate: searchState.maxCreationDate,
            category: searchState.category,
            building_id: searchState.building_id,
  
            filters: searchState.filters,
            ordering: searchState.ordering,
            pagination: searchState.pagination
          },
          success: (res) => {
            dispatch(
              setSearchState({
                currentData: res.data.data ?? [],
                currentPaginationData: res.data.pagination
              })
            );
  
            if (!res.data.data) {
              dispatch(setUserSearch({ page: 1 }));
              dispatch(
                setSearchPagination({
                  page: 1,
                  limit: 20
                })
              );
            }
          }
        })
    );
  };

  const onSubmit = (data) => {
    const payload = {
        pending: !data.pending,
        unit_id: data?.unit?.id || 0,
        move_in: data?.moveInDate || "",
        move_out: data?.moveOutDate || "",
        reservation_id: data?.reservation?.id || 0,
    }

    dispatch(
        reactivateUser({
          id: selectedUser.id,
          body: payload,
          success: (res) => {
            search();
            onClose();
            dispatch(
                setToast({
                  toastShow: true,
                  toastMessage: t('User has been reactivated successfully!')
                })
            );
          },
          fail: (res) => {
            dispatch(
              setToast({
                toastShow: true,
                toastMessage: res?.data?.message || t('Failed to reactivate the user!'),
                modal: 'errorToast'
              })
            );
          }
        })
    );

  };

  const onClose = () => {
    dispatch(clearConflictingDatesData());
    dispatch(setUser({}));
    dispatch(clearBuilding());
    methods.reset();
    dispatch(
        changeOpenedModal({
          modal: ''
        })
      );
  };

  useEffect(() => {
    if (
        unitWatch?.id && 
        moveInDateWatch && 
        moveOutDateWatch && 
        selectedUser &&
        selectedBuilding?.type === BUILDING_TYPE.TEMPORARY_RESIDENCY.id
    ) {
      if (selectedUser?.type_id === USER_TYPE.CO_RESIDENT.id) {
        return;
      }

      dispatch(
        getConflictingDates({
          unitId: unitWatch.id,
          moveInDate: moveInDateWatch,
          moveOutDate: moveOutDateWatch,
          userID: selectedUser?.id || 0
        })
      );
    }
  }, [unitWatch, moveInDateWatch, moveOutDateWatch, selectedUser, selectedBuilding]);
  
  const canSubmit = useCallback(() => {
    if (!Boolean(selectedBuilding?.id) || isGuestRole(selectedUser))
        return true;
    if (conflictingMoveDates.length > 0)
        return true;

    return false;
  },
  [selectedBuilding, selectedUser, conflictingMoveDates]);

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <StyledModal show={modal === 'ReactivateUserModal'} closeOnBackdrop={false}>
        <ModalHeader title={t('Reactivate User')} onCloseCallback={() => onClose()} />
          <FormProvider {...methods}>
            <StyledModalBody
                cssOverride={{"min-height": selectedUser?.role_id > USER_ROLE.USER.id ? '100px' : '200px'}}
            >
            {
                selectedUser?.role_id <= USER_ROLE.USER.id && (
                  <>
                    <InputSeparator>
                        <Controller
                            control={methods.control}
                            name="unit"
                            render={({ field: { onChange, value, name } }) => {
                                return (
                                    <InputSeparator>
                                        <SettingsModalBoldText>
                                            {t('Unit')}
                                        </SettingsModalBoldText>
                                        <Select
                                            disabled={units.unitsForDD?.length === 0}
                                            height={'56px'}
                                            placeholder={'Select Unit Number'}
                                            onChange={(e) => {
                                                if (e === 0) {
                                                    onChange({ unitId: undefined, name: '' });
                                                    return;
                                                }
                                                const foundUnit = units.unitsForDD.find((unit) => e === unit.id);
                                                onChange(foundUnit);
                                                methods.setValue('reservation', null);
                                            }}
                                            value={value?.id || undefined}
                                            error={methods.formState.errors?.unit}>
                                            <SelectItem value={undefined}>No Unit</SelectItem>
                                            {units.unitsForDD.map((unit) => (
                                                <SelectItem key={unit.id} value={unit.id}>
                                                    {unit.unit_num}
                                                </SelectItem>
                                            ))}
                                        </Select>
                                        <ErrorMessage
                                            errors={methods.formState.errors}
                                            name={name}
                                            render={({ message }) => <ErrorText>{message}</ErrorText>}
                                        />
                                    </InputSeparator>
                                );
                            }}
                        />
                    </InputSeparator>
                  </>
                )
            }

            {
                selectedUser?.role_id <= USER_ROLE.USER.id && 
                (
                    (selectedUser?.type_id === USER_TYPE.RESIDENT.id && 
                    selectedBuilding?.type ===  BUILDING_TYPE.TEMPORARY_RESIDENCY.id) || 
                    selectedBuilding?.type ===  BUILDING_TYPE.PERMANENT_RESIDENCY.id
                ) &&
                (
                    <>
                        <InputSeparator>
                            <Controller
                                control={methods.control}
                                name="moveInDate"
                                render={({ field: { name, onChange, value, ...rest } }) => (
                                <InputSeparator>
                                    <SettingsModalBoldText>Move In Date</SettingsModalBoldText>
                                    <CustomDatePicker
                                    {...rest}
                                    width={'100%'}
                                    height={'56px'}
                                    min={new Date().toISOString().split('T')[0]}
                                    disabledDate={(current) => current < moment().startOf('day')}
                                    format={DATE_FORMATS.MM_DD_YYYY}
                                    error={methods.formState.errors?.moveInDate}
                                    onChange={(current, dateString) => {
                                        onChange(dateString);
                                        const maxCreationDate = methods.getValues('moveOutDate');
                                        if (
                                        current &&
                                        maxCreationDate &&
                                        current.isAfter(moment(maxCreationDate).subtract(1, 'd'))
                                        ) {
                                            methods.setValue('moveOutDate', undefined);
                                        }
                                    }}
                                    value={
                                        value ? moment(value) : undefined
                                    }
                                    />
                                    <ErrorMessage
                                    errors={methods.formState.errors}
                                    name={name}
                                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                                    />
                                </InputSeparator>
                                )}
                            />
                        </InputSeparator>
                        <InputSeparator>
                            <Controller
                                control={methods.control}
                                name="moveOutDate"
                                render={({ field: { name, onChange, value } }) => {
                                return (
                                    <InputSeparator>
                                    <SettingsModalBoldText>Move Out Date</SettingsModalBoldText>
                                    <CustomDatePicker
                                        width={'100%'}
                                        height={'56px'}
                                        disabledDate={(current) => {
                                        const minCreationDate = methods.getValues('moveInDate');
                                        if (minCreationDate) {
                                            return current && current.isBefore(moment(minCreationDate).add(1, 'd'));
                                        }
                
                                        if (
                                            current &&
                                            minCreationDate &&
                                            current.isBefore(moment(minCreationDate).add(1, 'd'))
                                        ) {
                                            methods.setValue('moveInDate', undefined);
                                        }
                                        }}
                                        error={methods.formState.errors?.moveOutDate}
                                        minDate={moment().subtract(1, 'days').toDate()}
                                        format={DATE_FORMATS.MM_DD_YYYY}
                                        onChange={(_, dateString) => {
                                            onChange(dateString);
                                        }}
                                        value={value ? moment(value) : undefined}
                                    />
                                    <ErrorMessage
                                        errors={methods.formState.errors}
                                        name={name}
                                        render={({ message }) => <ErrorText>{message}</ErrorText>}
                                    />
                                    </InputSeparator>
                                );
                                }}
                            />
                        </InputSeparator>
                    </>
                )
            }

            {conflictingMoveDates && conflictingMoveDates?.length > 0 && (
              <>
                <SettingsModalBoldText cssOverride={`color: ${Palette.ERROR}`}>
                  Conflicting Move Dates
                </SettingsModalBoldText>
                {conflictingMoveDates
                  .map((date, index) => {
                    return (
                      <div key={`date-${index}-${date?.move_in_date}`}>
                        <SettingsModalLightText cssOverride={`color: ${Palette.ERROR}`}>
                          {date.user}
                        </SettingsModalLightText>
                        <SettingsModalLightText cssOverride={`color: ${Palette.ERROR}`}>
                          {getFullDateWithOutTime(date.move_in_date)} -{' '}
                          {getFullDateWithOutTime(date.move_out_date)}
                        </SettingsModalLightText>
                      </div>
                    );
                  })}
              </>
            )}
                    
            {
              selectedBuilding?.type ===  BUILDING_TYPE.TEMPORARY_RESIDENCY.id &&
              unitWatch?.id && 
              selectedUser?.role_id === USER_ROLE.USER.id && 
              selectedUser?.type_id === USER_TYPE.CO_RESIDENT.id && 
              conflictingMoveDates?.length == 0 &&
              (
                <Controller
                  control={methods.control}
                  name="reservation"
                  render={({ field: { onChange, value, name, ...rest } }) => {
                    const selectedUnit = units?.unitsForDD.find(
                      (unit) => unit.id === unitWatch?.id
                    );
                    return (
                      <InputSeparator>
                        <SettingsModalBoldText>Reservation</SettingsModalBoldText>
                        <Select
                          name={name}
                          height={'56px'}
                          placeholder={'Reservation'}
                          onChange={(e) => {
                            if (e === 0) {
                              onChange(undefined);
                              return;
                            }
                            const reservation = selectedUnit?.reservations?.find(
                              (reservation) => reservation.id === e
                            );
                            onChange(reservation);
                          }}
                          error={methods.formState.errors?.reservation?.id || methods.formState.errors?.reservation}
                          value={value?.id || undefined}>
                          {selectedUnit?.reservations.length > 0 ? (
                            <>
                              {selectedUnit?.reservations.map((reservation) => (
                                <SelectItem key={reservation.id} value={reservation.id}>
                                  {reservation.user_name} -{' '}
                                  {moment
                                    .utc(reservation.check_in_date)
                                    .format(DATE_FORMATS.MMM_D_YYYY)}{' '}
                                  -{' '}
                                  {moment
                                    .utc(reservation.check_out_date)
                                    .format(DATE_FORMATS.MMM_D_YYYY)}
                                </SelectItem>
                              ))}
                            </>
                          ) : (
                            <SelectItem value={0}>No Reservation</SelectItem>
                          )}
                        </Select>
                        <ErrorMessage
                          overrideError={
                            methods.formState.errors?.reservation?.id || methods.formState.errors?.reservation
                          }
                          render={({ message }) => <ErrorText>{message}</ErrorText>}
                        />
                      </InputSeparator>
                    );
                  }}
                />
            )}

            <ConditionalField
              conditions={[
                hasPermission(roleId, USER_ROLE.SUPER_ADMIN)
              ]}
              renderInactive={() => null}
              renderActive={() => (
                <Controller
                  control={methods.control}
                  name="pending"
                  render={({ field: { onChange, value, name } }) => (
                    <ToggleItem>
                      <ToggleItemTextContainer>
                        <ToggleItemTitle>{t('Send Email')}</ToggleItemTitle>
                        <ToggleItemDescription>{t('Send Email')}</ToggleItemDescription>
                        <ErrorMessage
                          errors={methods.formState.errors}
                          name={name}
                          render={({ message }) => <ErrorText>{message}</ErrorText>}
                        />
                      </ToggleItemTextContainer>
                      <ToggleItemComponentContainer>
                        <FeatureSwitch
                          onChange={(e) => onChange(e)}
                          checked={value}
                          value={value}
                          shape="pill"
                          color="primary"
                        />
                      </ToggleItemComponentContainer>
                    </ToggleItem>
                  )}
                />
              )}
            />

          </StyledModalBody>
            <ModalButtons
                leftButtonText={t('Back')}
                leftButtonClick={()=> onClose()}
                rightButtonText={t('Activate')}
                rightDisabled={canSubmit()}
            />
        </FormProvider>
      </StyledModal>
    </form>
  );
};

export default ReactivateUserModal;
