import React, { useState, useEffect, useCallback, useMemo, memo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUnit, filterUnits, getDoorPin } from '../../../../redux/actions/unit';
import { CDropdown, CDropdownToggle, CPagination } from '@coreui/react';
import { changeOpenedModal } from '../../../../redux/actions/headerModal';
import { setToast } from '../../../../redux/actions/window';
import { ReactComponent as FullBattery } from '../../../../assets/icons/battery/full.svg';
import { ReactComponent as SeventyPercent } from '../../../../assets/icons/battery/seventyPercent.svg';
import { ReactComponent as FortyPercent } from '../../../../assets/icons/battery/fortyPercent.svg';
import { ReactComponent as TwentyPercent } from '../../../../assets/icons/battery/twentyPercent.svg';
import { ReactComponent as NotActive } from '../../../../assets/icons/battery/notActive.svg';
import DeleteModal from '../../../../components/DeleteModal';
import { useTranslation } from 'react-i18next';
import { setCsvFields } from '../../../../redux/actions/reports';
import { UserRole } from '../../../users/constant';
import { LOADING_BLUE } from '../../../../utils/colorCode';
import { getBuildingSuites } from '../../../../redux/actions/building';
import Table from '../../../../components/Table';
import styled from 'styled-components';
import {
  deleteSuite,
  getDevicesSuites,
  getSuite,
  setCSVSuites
} from '../../../../redux/actions/suites';
import { RiPencilFill } from 'react-icons/ri';
import { StyledDropdown, StyledDropdownItem } from '../../../../components/GlobalTableEditDropdown';
import { Separator } from '../../../../components/ui/Separator';
import { FaInfoCircle, FaTrash } from 'react-icons/fa';
import { AVAILABLE_TABS } from '..';
import { useGetConfigs } from '../../../../hooks/useGetConfigs';

const Suites = memo(
  ({
    csvSuites,
    firstRequest,
    activePage,
    setActivePage,
    itemsPerPage,
    setItemsPerPage,
    isLoading,
    setIsLoading
  }) => {
    const building = useSelector((state) => state.building.building);
    const suitesPagination = useSelector((state) => state.building.suitesPagination);
    const suites = useSelector((state) => state.building.suites);
    const roleId = useSelector((state) => state.auth.roleId);
    const { organization } = useSelector((state) => state.Organization);
    const { config } = useGetConfigs();
    const searchText = useSelector((state) => state.suites.searchText);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState({
      id: null,
      show: false
    });
    const [numOfPages, setNumOfPages] = useState(1);

    const canViewLockInfo =
      config?.show_user_pin_admin ||
      config?.show_user_pin_to_admin ||
      roleId === UserRole.SUPER_ADMIN;

    useEffect(() => {
      if (!firstRequest.current) {
        if (suites?.length === 0) {
          setIsLoading(false);
          return;
        }

        setIsLoading(true);
        const suiteIds = suites?.map((suite) => suite.id).join(',') || '';
        dispatch(
          getDevicesSuites({
            ids: suiteIds,
            success: (devRes) => {
              const newData = suites.map((suite) => {
                return {
                  id: suite?.id,
                  building_id: suite?.building_id,
                  org_id: suite?.org_id,
                  name: suite?.name,
                  rooms:
                    suite?.units?.length > 0
                      ? suite?.units?.map((room) => room?.unit_num).join(', ')
                      : '',
                  floor: suite?.units?.length > 0 ? suite?.units?.[0]?.floor : '',
                  area: suite?.area || 0,
                  devices:
                    devRes?.data?.[suite?.id]?.map((device) => {
                      return {
                        id: device?.id,
                        name: device?.display_name,
                        battery_level: device?.battery_level,
                        device_id: device?.device_id
                      };
                    }) || []
                };
              });

              dispatch(setCSVSuites(newData));
            },
            fail: () => {
              dispatch(setCSVSuites([]));
            },
            finally: () => {
              setIsLoading(false);
            }
          })
        );
      }
    }, [suites]);

    useEffect(() => {
      dispatch(
        setCsvFields([
          {
            key: 'name',
            label: 'Unit Number'
          },
          { key: 'floor', label: 'Floor' },
          { key: 'rooms', label: 'Rooms' },
          { key: 'battery', label: 'Battery Info' }
        ])
      );
    }, [dispatch, t]);

    const fields = useMemo(() => {
      const memoFields = [
        { key: 'name', label: t('Unit Number'), sorter: false, filter: false },
        { key: 'floor', label: t('Floor'), sorter: false, filter: false },
        { key: 'rooms', label: t('Rooms'), sorter: false, filter: false },
        {
          key: 'devices',
          label: t('Battery info'),
          sorter: false,
          filter: false,
          _style: { width: '350px' }
        },
        { key: 'lock_info', label: t('Lock info'), sorter: false, filter: false },
        { key: 'edit', label: '', sorter: false, filter: false }
      ];

      if (!canViewLockInfo) {
        memoFields.splice(4, 1);
      }

      return memoFields;
    }, [
      roleId,
      organization.config?.show_user_pin_admin,
      organization.config?.show_user_pin_to_admin
    ]);

    const handleDetails = (id) => {
      const suiteInfo = suites.find((suite) => suite.id === id);
      dispatch(
        changeOpenedModal({
          modal: 'suiteLockDetails',
          data: suiteInfo
        })
      );
    };

    const handleDelete = () => {
      dispatch(
        deleteSuite({
          id: showModal.id,
          success: () => {
            setIsLoading(true);
            if (building?.id && building?.org_id) {
              dispatch(
                getBuildingSuites({
                  orgId: building?.org_id,
                  buildingId: building?.id,
                  page: activePage,
                  per_page: itemsPerPage,
                  searchText,
                  finally: () => {
                    dispatch(
                      setToast({
                        toastShow: true,
                        toastMessage: t('Unit has been successfully deleted!')
                      })
                    );
                    setShowModal((prev) => ({
                      ...prev,
                      show: false
                    }));
                  }
                })
              );
            }
          },
          fail: () => {
            dispatch(
              setToast({
                toastShow: true,
                toastMessage: t('Unit deletion failed!'),
                modal: 'errorToast'
              })
            );
            setIsLoading(false);
          }
        })
      );
    };

    const handleGetDoorPin = (id, suiteName) => {
      id != '' &&
        dispatch(
          getDoorPin({
            id,
            data: { unit_num: suiteName, serial: id },
            success: () => {
              dispatch(
                changeOpenedModal({
                  modal: 'unitLockDetails',
                  data: { from: AVAILABLE_TABS.SUITE, suiteName }
                })
              );
            },
            fail: () => {
              dispatch(
                setToast({
                  toastShow: true,
                  toastMessage: 'failed to get unit info!',
                  modal: 'errorToast'
                })
              );
            }
          })
        );
    };

    const actionForPagination = (page) => {
      if (page) {
        setActivePage(page);
      }
    };

    const handlePaginationChange = (e) => {
      setItemsPerPage(e);
    };

    const handleSpecificColClick = (item, index, col, e) => {
      if (col !== 'lock_info' && col !== 'edit') {
        handleDetails(item?.id);
      }
    };

    const handleEdit = (id) => {
      dispatch(
        getSuite({
          id,
          success: () => {
            dispatch(
              changeOpenedModal({
                modal: 'editSuite'
              })
            );
          },
          fail: () => {
            dispatch(
              setToast({
                toastShow: true,
                toastMessage: t('failed to get unit data!'),
                modal: 'errorToast'
              })
            );
          }
        })
      );
    };

    const getBatteryIcon = useCallback((batteryLevel) => {
      if (batteryLevel >= 75) {
        return <FullBattery />;
      } else if (batteryLevel >= 40) {
        return <SeventyPercent />;
      } else if (batteryLevel >= 20) {
        return <FortyPercent />;
      } else if (batteryLevel >= 0) {
        return <TwentyPercent />;
      } else {
        return <NotActive />;
      }
    }, []);

    return (
      <>
        <Table
          noItemsView={{
            noResults: t('no filtering results available'),
            noItems: t('no items available')
          }}
          sorter={{ external: true }}
          sorterValue={null}
          onPaginationChange={handlePaginationChange}
          items={csvSuites}
          fields={fields}
          hover
          clickableRows
          onRowClick={handleSpecificColClick}
          loading={isLoading}
          onFilteredItemsChange={(val) => dispatch(filterUnits(val))}
          scopedSlots={{
            unit_num: (item) => {
              return (
                <td
                  style={{
                    fontSize: '16px'
                  }}>
                  <strong>{item['unit_num']}</strong>
                </td>
              );
            },
            floor: (item) => {
              return (
                <td
                  style={
                    item.floor === ''
                      ? {
                          color: '#C6C6C6'
                        }
                      : {}
                  }>
                  {item.floor === '' ? 'None' : item.floor}
                </td>
              );
            },
            rooms: (item, index) => {
              return (
                <td
                  style={
                    item.rooms === ''
                      ? {
                          color: '#C6C6C6'
                        }
                      : {}
                  }>
                  {item.rooms === '' ? 'None' : item.rooms}
                </td>
              );
            },
            devices: (item, index) => {
              return (
                <>
                  {item?.devices && item?.devices.length > 0 ? (
                    <td>
                      <ColumnContainer>
                        {item?.devices?.map((device, index) => {
                          return (
                            <TdContainer key={device.id}>
                              <span
                                style={{
                                  width: '100%',
                                  maxWidth: '250px',
                                  display: 'inline-block',
                                  wordWrap: 'break-word'
                                }}>
                                {device?.name}
                              </span>
                              <span
                                style={{
                                  width: '80px',
                                  marginLeft: '5px',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'flex-end',
                                  gap: '4px'
                                }}>
                                {device?.battery_level >= 0
                                  ? device?.battery_level + '%'
                                  : device?.battery_level}{' '}
                                {getBatteryIcon(device?.battery_level)}
                              </span>
                            </TdContainer>
                          );
                        })}
                      </ColumnContainer>
                    </td>
                  ) : (
                    <td style={{ color: '#C6C6C6' }}>No devices</td>
                  )}
                </>
              );
            },
            lock_info: (item) => {
              return canViewLockInfo && item?.devices && item?.devices.length > 0 ? (
                <td>
                  <ColumnContainer>
                    {item?.devices.map((device) => {
                      return (
                        <TdContainer
                          key={device.id}
                          style={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            fontSize: '16px',
                            color: LOADING_BLUE,
                            zIndex: 2
                          }}
                          onClick={() => handleGetDoorPin(device?.device_id, item.name)}>
                          {t('View')}
                        </TdContainer>
                      );
                    })}
                  </ColumnContainer>
                </td>
              ) : (
                <td
                  style={{
                    cursor: 'not-allowed',
                    textDecoration: 'none',
                    fontSize: '16px',
                    color: '#AEB0B5',
                    zIndex: 2
                  }}>
                  {t('View')}
                </td>
              );
            },
            edit: (item, index) => {
              return (
                <td className="text-right edit-width edit-container">
                  <CDropdown className="tableEditDropdown">
                    <CDropdownToggle>
                      <RiPencilFill size={24} />
                    </CDropdownToggle>
                    <StyledDropdown>
                      <StyledDropdownItem onClick={() => handleDetails(item.id)}>
                        <FaInfoCircle size={16} className="mr-2" />
                        {t('Details')}
                      </StyledDropdownItem>
                      <Separator orientation="horizontal" />
                      {(config?.org_allow_edits || parseInt(roleId) == UserRole.SUPER_ADMIN) && (
                        <StyledDropdownItem
                          onClick={() => {
                            handleEdit(item.id);
                          }}>
                          <RiPencilFill size={16} className="mr-2" />
                          {t('Edit')}
                        </StyledDropdownItem>
                      )}
                      {parseInt(roleId) > UserRole.VENDOR && (
                        <>
                          <Separator orientation="horizontal" />
                          <StyledDropdownItem
                            className="deleteOption"
                            onClick={() => {
                              setShowModal((prev) => ({
                                show: true,
                                id: item.id
                              }));
                              dispatch(
                                changeOpenedModal({
                                  modal: 'deleteModal'
                                })
                              );
                            }}>
                            <FaTrash size={16} className="mr-2" /> {t('Delete')}
                          </StyledDropdownItem>
                        </>
                      )}
                    </StyledDropdown>
                  </CDropdown>
                </td>
              );
            }
          }}
        />
        <div className={'mt-2'}>
          <CPagination
            activePage={activePage}
            pages={
              suitesPagination?.total
                ? Math.ceil(suitesPagination?.total / itemsPerPage)
                : numOfPages
            }
            onActivePageChange={(i) => actionForPagination(i)}></CPagination>
        </div>

        {showModal.show && (
          <DeleteModal
            type="Unit"
            action={handleDelete}
            setShowModal={setShowModal}
            description={'Are you sure you want to delete this unit?'}
          />
        )}
      </>
    );
  }
);

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TdContainer = styled.td`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 350px;
`;

export default Suites;
