import { takeLatest } from 'redux-saga/effects';
import apiCall from '../../utils/apiCall';
import {
  CREATE_LICENSE_PLATE,
  DELETE_LICENSE_PLATE,
  GET_LICENSE_PLATE,
  UPDATE_LICENSE_PLATE
} from '../constants';

const createLicensePlate = apiCall({
  type: CREATE_LICENSE_PLATE,
  method: 'POST',
  path: ({ orgID }) => `/orgs/${orgID}/license-plates`
});

const getLicensePlate = apiCall({
  type: GET_LICENSE_PLATE,
  method: 'GET',
  path: ({ orgID, licensePlateID }) => `/orgs/${orgID}/license-plates/${licensePlateID}`
});

const updateLicensePlate = apiCall({
  type: UPDATE_LICENSE_PLATE,
  method: 'PATCH',
  path: ({ orgID, licensePlateID }) => `/orgs/${orgID}/license-plates/${licensePlateID}`
});

const deleteLicensePlate = apiCall({
  type: DELETE_LICENSE_PLATE,
  method: 'DELETE',
  path: ({ orgID, licensePlateID }) => `/orgs/${orgID}/license-plates/${licensePlateID}`
});

export default function* licensePlatesSaga() {
  yield takeLatest(CREATE_LICENSE_PLATE, createLicensePlate);
  yield takeLatest(GET_LICENSE_PLATE, getLicensePlate);
  yield takeLatest(UPDATE_LICENSE_PLATE, updateLicensePlate);
  yield takeLatest(DELETE_LICENSE_PLATE, deleteLicensePlate);
}
