// https://ui.shadcn.com/docs/components/breadcrumb

import * as React from 'react';
import styled from 'styled-components';
import { FiMoreHorizontal } from 'react-icons/fi';
import { Fonts, Palette } from '../utils';
import { ReactComponent as BreadcrumbsIcon } from '../../assets/icons/breadcrumbs.svg';
import { ReactComponent as BreadcrumbsSeparatorIcon } from '../../assets/icons/chevron-right.svg';

const StyledBreadcrumbList = styled.ol`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.375rem;
  padding: 0;
  word-break: break-word;
  font-size: 0.875rem;
  color: ${Palette.BLACK};
  list-style-type: none;

  font-family: ${Fonts.OPEN_SANS_FONT};
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  @media (min-width: 640px) {
    gap: 0.625rem;
  }
`;

const StyledBreadcrumbItem = styled.li`
  display: inline-flex;
  align-items: center;
  gap: 0.375rem;
`;

const StyledBreadcrumbLink = styled.a`
  color: ${Palette.BLACK};

  &:hover {
    color: ${Palette.BLACK};
    cursor: pointer;
  }
`;

const StyledBreadcrumbPage = styled.span`
  font-weight: normal;
  color: ${Palette.ACTIVE_GREEN};
`;

const StyledSeparator = styled.li`
  & > svg {
    width: 24px;
    height: 24px;
  }
`;

const StyledBreadcrumbEllipsis = styled.span`
  display: flex;
  height: 2.25rem;
  width: 2.25rem;
  align-items: center;
  justify-content: center;
`;

const Breadcrumb = React.forwardRef(({ ...props }, ref) => (
  <nav ref={ref} aria-label="breadcrumb" {...props} />
));
Breadcrumb.displayName = 'Breadcrumb';

const BreadcrumbList = React.forwardRef(({ ...props }, ref) => (
  <StyledBreadcrumbList ref={ref} {...props} />
));
BreadcrumbList.displayName = 'BreadcrumbList';

const BreadcrumbItem = React.forwardRef(({ ...props }, ref) => (
  <StyledBreadcrumbItem ref={ref} {...props} />
));
BreadcrumbItem.displayName = 'BreadcrumbItem';

const BreadcrumbLink = React.forwardRef(({ ...props }, ref) => {
  return <StyledBreadcrumbLink ref={ref} {...props} />;
});
BreadcrumbLink.displayName = 'BreadcrumbLink';

const BreadcrumbPage = React.forwardRef(({ ...props }, ref) => (
  <StyledBreadcrumbPage ref={ref} role="link" aria-disabled="true" aria-current="page" {...props} />
));
BreadcrumbPage.displayName = 'BreadcrumbPage';

const BreadcrumbIcon = ({ children, ...props }) => (
  <StyledSeparator role="presentation" aria-hidden="true" {...props}>
    {children ?? <BreadcrumbsIcon />}
  </StyledSeparator>
);
BreadcrumbIcon.displayName = 'BreadcrumbIcon';

const BreadcrumbSeparator = ({ children, ...props }) => (
  <StyledSeparator role="presentation" aria-hidden="true" {...props}>
    {children ?? <BreadcrumbsSeparatorIcon />}
  </StyledSeparator>
);
BreadcrumbSeparator.displayName = 'BreadcrumbSeparator';

const BreadcrumbEllipsis = ({ ...props }) => (
  <StyledBreadcrumbEllipsis role="presentation" aria-hidden="true" {...props}>
    <FiMoreHorizontal style={{ width: '1rem', height: '1rem' }} />
    <span className="sr-only">More</span>
  </StyledBreadcrumbEllipsis>
);
BreadcrumbEllipsis.displayName = 'BreadcrumbElipssis';

export {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbPage,
  BreadcrumbIcon,
  BreadcrumbSeparator,
  BreadcrumbEllipsis
};
