import {useMemo} from "react";
import {useSelector} from "react-redux";
import { useLocation } from 'react-router-dom';
import {UserRole} from "../views/users/constant";

const RouteToConfigMap = {
  'deliveries': 'enable_deliveries',
  'security': 'enable_security_cameras',
  'leak-detectors': 'enable_leak_detectors',
  'thermostats': 'enable_thermostats',
  'parking': 'enable_license_plates',
  'parking-analytics': 'enable_license_plates',
};

export const useGetConfigs = () => {
  const {organization} = useSelector((state) => state.Organization);
  const { building, globalSelectedBuilding } = useSelector((state) => state.building);
  const { roleId } = useSelector((state) => state.auth);
  const location = useLocation();

  const config = useMemo(()=>{
    return building?.config || globalSelectedBuilding?.config || organization?.config;
  },[
    building,
    globalSelectedBuilding,
    organization
  ]);

  const isSectionEnabled = useMemo(()=>{
    if (roleId == UserRole.SUPER_ADMIN) return true;
    const currentRoute = location?.pathname?.replace("/","");
    const configName = RouteToConfigMap[currentRoute];

    if (!Boolean(configName)) return true;
    if (Boolean(building?.config)) return building?.config[configName];
    if (Boolean(globalSelectedBuilding?.config)) return globalSelectedBuilding?.config[configName];

    return true;
  },[
    building,
    globalSelectedBuilding,
    location,
  ]);

  return {
    config,
    orgConfig: organization?.config,
    isSectionEnabled,
  };
};

