import React from 'react';
import styled, { css } from 'styled-components';
import { StyledModal } from '../../../components/Modals';

const ServiceDetailModal = ({ children, ...rest }) => {
  return <StyledServiceDetailModal {...rest}>{children}</StyledServiceDetailModal>;
};

const paddedStyles = css`
  padding: 20px 24px 20px 24px !important;
`;

const StyledServiceDetailModal = styled(StyledModal)`
  height: 100vh;
  margin: 0;
  .modal-content {
    height: 100%;
    border-radius: 0px;
    padding: 0px !important;
    gap: 16px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
    width: ${({ width }) => width || '400px'};
    ${({ padding }) =>
      padding &&
      css`
        ${paddedStyles}
      `}
  }

  .modal-dialog .modal-content .modal-title {
    font-size: 25px;
    line-height: 40px;
  }
`;

export default ServiceDetailModal;
