import { createReducer } from '@reduxjs/toolkit';
import { GET_LEAK_DETECTORS } from '../constants';
import { requestFail, requestPending, requestSuccess } from '../../utils/status';

const initialState = {
  status: '',
  leakDetectorData: [],
  search: '',
  filters: {},
  ordering: {},
  pagination: {
    page: 1,
    limit: 20
  }
};

export default createReducer(initialState, {
  [requestSuccess(GET_LEAK_DETECTORS)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(GET_LEAK_DETECTORS),
    leakDetectorData: payload
  }),

  [requestPending(GET_LEAK_DETECTORS)]: (state) => ({
    ...state,
    status: requestPending(GET_LEAK_DETECTORS)
  }),

  [requestFail(GET_LEAK_DETECTORS)]: (state) => ({
    ...state,
    status: requestFail(GET_LEAK_DETECTORS),
    leakDetectorData: null
  })
});
