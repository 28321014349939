import { createReducer } from '@reduxjs/toolkit';
import { SET_CARRIERS } from '../constants';

const initialState = {
  carriersList: [],
  pagination: {
    currentPage: 1,
    pageSize: null,
    totalPages: null,
    totalResults: null
  },
};

export default createReducer(initialState, {
  [SET_CARRIERS]: (state, { payload }) => {
    return {
      ...state,
      carriersList: payload?.data,
      pagination: {
        currentPage: payload?.pagination['currentPage'],
        pageSize: payload?.pagination['pageSize'],
        totalPages: payload?.pagination['totalPages'],
        totalResults: payload?.pagination['totalResults']
      },
    };
  },
});
