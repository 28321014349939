import { takeLatest } from 'redux-saga/effects';
import apiCall, { GRATAFY_URL } from '../../utils/apiCall';
import { ACKNOWLEDGE_LEAK_DETECTOR, GET_LEAK_DETECTORS } from '../constants';

const searchLeakDetectors = apiCall({
  type: GET_LEAK_DETECTORS,
  method: 'GET',
  path: ({ orgID, buildingID, unitID, all }) => {
    const searchParams = new URLSearchParams();
    searchParams.append('o', orgID);
    searchParams.append('b', buildingID);

    if (unitID) {
      searchParams.append('u', unitID);
    }

    if (all) {
      searchParams.append('all', 'true');
    }

    return `/admin/device/waterLeakDetectors?${searchParams.toString()}`;
  },
  gratafyURL: GRATAFY_URL
});

const acknowledgeLeak = apiCall({
  type: ACKNOWLEDGE_LEAK_DETECTOR,
  method: 'POST',
  path: ({ leakDetectorID }) => {
    return `/admin/device/waterLeakDetectors/${leakDetectorID}/alarms/ack`;
  },
  gratafyURL: GRATAFY_URL
});

export default function* leakDetectorsSaga() {
  yield takeLatest(ACKNOWLEDGE_LEAK_DETECTOR, acknowledgeLeak);
  yield takeLatest(GET_LEAK_DETECTORS, searchLeakDetectors);
}
