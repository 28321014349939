import { takeLatest } from "redux-saga/effects";
import { CREATE_DELIVERY, UPDATE_DELIVERY, UPLOAD_DELIVERY_IMAGE } from "../constants";
import apiCall from "../../utils/apiCall";

const updateDelivery = apiCall({
  type: UPDATE_DELIVERY,
  method: "put",
  path: ({ orgId, deliveryId }) => `/orgs/${orgId}/deliveries/${deliveryId}`
});

const createDelivery = apiCall({
  type: CREATE_DELIVERY,
  method: "post",
  path: ({ orgId }) => `/orgs/${orgId}/deliveries`
});

const uploadDeliveryImage = apiCall({
  type: UPLOAD_DELIVERY_IMAGE,
  method: 'post',
  path: '/images',
  isFormData: true
});

export default function* deliveriesSaga() {
  yield takeLatest(UPDATE_DELIVERY, updateDelivery);
  yield takeLatest(CREATE_DELIVERY, createDelivery);
  yield takeLatest(UPLOAD_DELIVERY_IMAGE, uploadDeliveryImage);
}
