import { createAction } from '@reduxjs/toolkit';
import {
  CHANGE_PAGE,
  TEMPLATE_SELECTED,
  TEMPLATE_DATA,
  IS_MODAL_VISIBLE,
  EDIT_THIS_TEMPLATE,
  SHOW_MODAL_FOR_TEMPLATE,
  SEND_EMAIL,
  SELECT_USER,
  ALL_TEMPLATES,
  GET_ALL_TEMPLATES_DATA,
  GET_TEMPLATE,
  GET_CATEGORY_MAPPING,
  SEND_TEMPLATE,
  CHANGED_TEMPLATE_DATA,
  SET_TREE_DATA,
  SET_PARSED_TEMPLATE,
  CLEAR_TREE,
  CHANGE_SHOW_BUTTON,
  SET_RECIPIENTS_LENGTH,
  SEND_TEMPLATE_IMAGE,
  SET_SELECTIION_TEXT,
  SET_IS_FETCHING_TEMPLATES,
  SEND_DRAFT_TEMPLATE,
  SET_SELECT_USER_HELPERS,
  GET_DRAFT_DETAILS,
  UPDATE_DRAFT_DETAILS,
  DELETE_DRAFT,
  RESET_DRAFT_DETAILS,
  SET_PINNED_NEWS,
  SET_TITLE
} from '../constants';

export const changeThePage = createAction(CHANGE_PAGE);
export const changeTemplateData = createAction(CHANGED_TEMPLATE_DATA);
export const setTempalteSelected = createAction(TEMPLATE_SELECTED);
export const setTemplateData = createAction(TEMPLATE_DATA);
export const isModalVisible = createAction(IS_MODAL_VISIBLE);
export const editThisTemplate = createAction(EDIT_THIS_TEMPLATE);
export const showModalForTemplate = createAction(SHOW_MODAL_FOR_TEMPLATE);
export const sendEmail = createAction(SEND_EMAIL);
export const selectUser = createAction(SELECT_USER);
export const allTemplates = createAction(ALL_TEMPLATES);
export const getAllTemplates = createAction(GET_ALL_TEMPLATES_DATA);
export const getCategoryMaping = createAction(GET_CATEGORY_MAPPING);
export const getTemplate = createAction(GET_TEMPLATE);
export const setTreeData = createAction(SET_TREE_DATA);
export const setParsedTemplate = createAction(SET_PARSED_TEMPLATE);
export const sendTemplate = createAction(SEND_TEMPLATE);
export const clearTree = createAction(CLEAR_TREE);
export const changeShowButton = createAction(CHANGE_SHOW_BUTTON);
export const setRecipientsLength = createAction(SET_RECIPIENTS_LENGTH);
export const sendTemplateImage = createAction(SEND_TEMPLATE_IMAGE);
export const setSelectionText = createAction(SET_SELECTIION_TEXT);
export const setPinnedNews = createAction(SET_PINNED_NEWS);
export const setTitle = createAction(SET_TITLE);
export const setIsFetchingTemplates = createAction(SET_IS_FETCHING_TEMPLATES);
export const sendDraftTemplate = createAction(SEND_DRAFT_TEMPLATE);
export const setSelectUserHelpers = createAction(SET_SELECT_USER_HELPERS);
export const getDraftDetails = createAction(GET_DRAFT_DETAILS);
export const updateDraftDetails = createAction(UPDATE_DRAFT_DETAILS);
export const deleteDraft = createAction(DELETE_DRAFT);
export const resetDraftDetails = createAction(RESET_DRAFT_DETAILS);

export default {
  allTemplates,
  sendEmail,
  changeThePage,
  setPinnedNews,
  setTitle,
  setTempalteSelected,
  setTemplateData,
  isModalVisible,
  editThisTemplate,
  showModalForTemplate,
  selectUser,
  sendTemplateImage,
  setSelectionText,
  setIsFetchingTemplates,
  sendDraftTemplate,
  setSelectUserHelpers,
  getDraftDetails,
  updateDraftDetails,
  deleteDraft,
  resetDraftDetails
};
