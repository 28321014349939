import { createReducer } from '@reduxjs/toolkit';
import { resetOrganizationData, updateOrganizationData } from '../actions/addOrganization';

const initialState = {
  name: '',
  email: '',
  contact: '',
  dataCenter: 'CA',
  residentProvider: 0,
  residentMode: 0,
  propertyProvider: 0,
  propertyMode: 0,
  serviceProvider: 0,
  serviceMode: 0,
  serviceRequestURL: '',
  userPinLength: 6,
  options: {
    lockProvider: 0,
    enablePost: false,
    enableOffers: false,
    enableBooking: false,
    enableLockScreen: false,
    enableServiceRequest: false,
    enableReadOnlyUsers: false,
    enableShowSuperPinAdmin: false,
    enableSecurity: false,
    enableMultiLock: false,
    enableThermostats: false,
    enableLeakDetectors: false,
    showUserPinToAdmin: false,
    enableEdit: false,
    enableShowUserPinResident: false,
    enableLicensePlates: false,
    enableCustomServiceRequestCategories: false,
    enableDeliveries: false,
    enableAkuvox: false,
    enableSecurityCameras: false,
    enableAddGuests: false,
  },
  avatar: {
    blobURL: undefined, // Used to display on the final modal
    blobData: undefined, // The data to submit to the server (the actual user profile data)
    blobName: undefined // The image name
  }
};

export default createReducer(initialState, {
  [updateOrganizationData]: (state, { payload }) => ({
    ...state,
    ...payload
  }),

  [resetOrganizationData]: () => ({
    ...initialState
  })
});
