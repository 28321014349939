export const ORGANIZATION_PROVIDERS = [
  {
    id: 0,
    value: 'Internal'
  },
  {
    id: 1,
    value: 'Yardi'
  }
];

export const ORGANIZATION_MODES = [
  {
    id: 0,
    value: 'Internal'
  },
  {
    id: 1,
    value: 'Read'
  },
  {
    id: 2,
    value: 'Write'
  },
  {
    id: 3,
    value: 'Read/Write'
  }
];

export const DATA_CENTERS = [
  {
    id: 'US',
    value: 'United States'
  },
  {
    id: 'CA',
    value: 'Canada'
  }
];

export const LOCK_PROVIDERS = [
  {
    id: 0,
    value: 'Alfred'
  },
  {
    id: 1,
    value: 'Yale'
  }
];

export const OFFER_CATEGORY_MAPPING = [
  {
    id: 1,
    value: 'Delivery/Takeout'
  },
  {
    id: 2,
    value: 'Financial Services'
  },
  {
    id: 3,
    value: 'Grocery Delivery'
  },
  {
    id: 4,
    value: 'Health & Fitness'
  },
  {
    id: 5,
    value: 'Home'
  },
  {
    id: 6,
    value: 'Insurance'
  },
  {
    id: 7,
    value: 'Restaurants'
  },
  {
    id: 8,
    value: 'Retail'
  },
  {
    id: 9,
    value: 'Other'
  },
  {
    id: 10,
    value: 'Grata Exclusive'
  }
];

export const PRIORITY_MAPPING = [
  {
    id: 1,
    value: 'High'
  },
  {
    id: 2,
    value: 'Medium'
  },
  {
    id: 3,
    value: 'Low'
  }
];

export const CONTACT_WITH_MAP = [
  {
    id: 1,
    value: 'Email'
  },
  {
    id: 2,
    value: 'Phone'
  }
];

export const SERVICE_STATUS_MAPPING = [
  {
    id: 0,
    value: 'New'
  },
  {
    id: 13,
    value: 'In Progress'
  },
  {
    id: 5,
    value: 'Complete'
  }
];

export const USER_TYPE_MAP = [
  {
    id: 1,
    value: 'Resident'
  },
  {
    id: 2,
    value: 'Co-Resident'
  }
];

export const NOTFICATION_TYPE = [
  {
    id: 1,
    value: 'NotificationGeneral'
  },
  {
    id: 2,
    value: 'NotificationRequests'
  },
  {
    id: 3,
    value: 'NotificationSecurity'
  },
  {
    id: 4,
    value: 'NotificationOffers'
  },
  {
    id: 5,
    value: 'NotificationBookings'
  }
];

export const NEW = {
  id: 0,
  value: 'New'
};

export const OPEN = {
  id: 1,
  value: 'Open'
};

export const PENDING = {
  id: 2,
  value: 'Pending'
};

export const CANCELLED = {
  id: 3,
  value: 'Cancelled'
};

export const CLOSED = {
  id: 4,
  value: 'Closed'
};

export const COMPLETE = {
  id: 5,
  value: 'Complete'
};

export const RESERVED = {
  id: 6,
  value: 'Reserved'
};

export const BOOKED = {
  id: 7,
  value: 'Booked'
};

export const ENABLED = {
  id: 8,
  value: 'Enabled'
};

export const DISABLED = {
  id: 9,
  value: 'Disabled'
};

export const SENT = {
  id: 10,
  value: 'Sent'
};

export const PROCESSING = {
  id: 11,
  value: 'Processing'
};

export const ERROR = {
  id: 12,
  value: 'Error'
};

export const IN_PROGRESS = {
  id: 13,
  value: 'In Progress'
};

export const DELETED = {
  id: 14,
  value: 'Deleted'
};

export const FAILED = {
  id: 15,
  value: 'Failed'
};

export const INCOMPLETE = {
  id: 16,
  value: 'Incomplete'
};

export const ARCHIVED = {
  id: 17,
  value: 'Archived'
};

export const STATUS_ARRAY = [
  NEW,
  OPEN,
  PENDING,
  CANCELLED,
  CLOSED,
  COMPLETE,
  RESERVED,
  BOOKED,
  ENABLED,
  DISABLED,
  SENT,
  PROCESSING,
  ERROR,
  IN_PROGRESS,
  DELETED,
  FAILED,
  INCOMPLETE,
  ARCHIVED
];

export const STATUS_MAP = [
  {
    id: 0,
    value: 'New'
  },
  {
    id: 1,
    value: 'Open'
  },
  {
    id: 2,
    value: 'Pending'
  },
  {
    id: 3,
    value: 'Cancelled'
  },
  {
    id: 4,
    value: 'Closed'
  },
  {
    id: 5,
    value: 'Complete'
  },
  {
    id: 6,
    value: 'Reserved'
  },
  {
    id: 7,
    value: 'Booked'
  },
  {
    id: 8,
    value: 'Enabled'
  },
  {
    id: 9,
    value: 'Disabled'
  },
  {
    id: 10,
    value: 'Sent'
  },
  {
    id: 11,
    value: 'Processing'
  },
  {
    id: 12,
    value: 'Error'
  },
  {
    id: 13,
    value: 'In Progress'
  },
  {
    id: 14,
    value: 'Deleted'
  },
  {
    id: 15,
    value: 'Failed'
  },
  {
    id: 16,
    value: 'Incomplete'
  },
  {
    id: 17,
    value: 'Archived'
  }
];

export const amenityMapping = [
  {
    id: 1,
    value: 'Party Room'
  },
  {
    id: 2,
    value: 'Service Elevator'
  },
  {
    id: 3,
    value: 'Visitor Parking'
  }
];

export const ROLE_MAP = [
  {
    id: -1,
    value: 'Guest'
  },
  {
    id: 1,
    value: 'User'
  },
  {
    id: 2,
    value: 'Building Manager'
  },
  {
    id: 3,
    value: 'Org Manager'
  },
  {
    id: 5,
    value: 'Vendor'
  },
  {
    id: 99,
    value: 'Super Admin'
  }
];

export const USER_ROLE_MAPPINGS = {
  GUEST: {
    id: -1,
    value: 'Guest'
  },
  USER: {
    id: 1,
    value: 'User'
  },
  MANAGER: {
    id: 2,
    value: 'Building Manager'
  },
  ADMIN: {
    id: 3,
    value: 'Org Manager'
  },
  ADVERTISER: {
    id: 4,
    value: 'Advertiser'
  },
  VENDOR: {
    id: 5,
    value: 'Vendor'
  },
  ACCOUNT_MANAGER: {
    id: 80,
    value: 'Account Manager'
  },
  SUPER_ADMIN: {
    id: 99,
    value: 'Super Admin'
  }
};

export const singleOptions = [
  { value: 1, label: 'All days' },
  { value: 2, label: 'Weekdays' },
  { value: 3, label: 'Weekends' },
  { value: 4, label: 'Select days' }
];

export const days = [
  { value: 'M', label: 'Monday' },
  { value: 'T', label: 'Tuesday' },
  { value: 'W', label: 'Wednesday' },
  { value: 'TH', label: 'Thursday' },
  { value: 'F', label: 'Friday' },
  { value: 'S', label: 'Saturday' },
  { value: 'SU', label: 'Sunday' }
];

export const hoursStart = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00'
];

export const hoursEnd = [
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
  '24:00'
];
export const org_tenant_provider_mapping = [
  {
    id: 0,
    value: 'Internal'
  },
  {
    id: 1,
    value: 'Yardi'
  }
];

export const org_property_provider_mapping = [
  {
    id: 0,
    value: 'Internal'
  },
  {
    id: 1,
    value: 'Yardi'
  }
];

export const org_service_provider_mapping = [
  {
    id: 0,
    value: 'Internal'
  },
  {
    id: 1,
    value: 'Yardi'
  }
];

export const org_tenant_mode_mapping = [
  {
    id: 0,
    value: 'Internal'
  },
  {
    id: 1,
    value: 'Read'
  },
  {
    id: 2,
    value: 'Write'
  },
  {
    id: 3,
    value: 'Read/Write'
  }
];

export const org_property_mode_mapping = [
  {
    id: 0,
    value: 'Internal'
  },
  {
    id: 1,
    value: 'Read'
  },
  {
    id: 2,
    value: 'Write'
  },
  {
    id: 3,
    value: 'Read/Write'
  }
];

export const org_service_mode_mapping = [
  {
    id: 0,
    value: 'Internal'
  },
  {
    id: 1,
    value: 'Read'
  },
  {
    id: 2,
    value: 'Write'
  },
  {
    id: 3,
    value: 'Read/Write'
  }
];
