import { createAction } from '@reduxjs/toolkit';
import {
  ADD_BUILDING,
  EDIT_BUILDING,
  GET_BUILDINGS,
  GET_BUILDING,
  GET_EDITED_BUILDINGS,
  GET_BUILDING_UNITS,
  SET_GLOBAL_BUILDING,
  SET_BUILDING_IMAGE,
  SET_GLOBAL_BUILDING_ID,
  SET_GLOBAL_ORG_ID,
  CLEAR_BUILDING,
  SET_BUILDING,
  SET_BUILDING_AVATAR,
  CLEAR_BUILDINGS,
  CLEAR_ERROR,
  SEARCH_BUILDINGS,
  REMOVE_BUILDING,
  SET_ALL_BUILDINGS,
  GET_ALL_BUILDINGS,
  SET_CACHED_BUILDINGS,
  SET_LOADING_CACHED_BUILDINGS,
  RESET_GLOBAL_BUILDINGS,
  SET_GLOBAL_BUILDING_STATUS,
  GET_DELIVERY_CODES,
  ADD_DELIVERY_CODE,
  REMOVE_DELIVERY_CODE,
  SET_GLOBAL_ORG,
  GET_BUILDING_SUITES,
  RESET_SUITE_IN_BUILDING,
  INCREMENT_BUILDING_EVENT_COUNT,
  GET_BUILDING_DEVICES,
  SHOW_GLOBAL_BUILDINGS_DROPDOWN,
  SET_GLOBAL_SELECTED_BUILDING,
  SET_NEW_BUILDING,
  CLEAR_NEW_BUILDING
} from '../constants';

export const searchBuildings = createAction(SEARCH_BUILDINGS);
export const removeBuilding = createAction(REMOVE_BUILDING);
export const addBuilding = createAction(ADD_BUILDING);
export const editBuilding = createAction(EDIT_BUILDING);
export const getBuildings = createAction(GET_BUILDINGS);
export const setAllBuildings = createAction(SET_ALL_BUILDINGS);
export const incrementBuildingEventCount = createAction(INCREMENT_BUILDING_EVENT_COUNT);
export const getEditedBuildings = createAction(GET_EDITED_BUILDINGS);
export const getBuilding = createAction(GET_BUILDING);
export const getBuildingsDevices = createAction(GET_BUILDING_DEVICES);
export const getBuildingUnits = createAction(GET_BUILDING_UNITS);
export const getBuildingSuites = createAction(GET_BUILDING_SUITES);
export const setGlobalBuilding = createAction(SET_GLOBAL_BUILDING);
export const clearBuildings = createAction(CLEAR_BUILDINGS);
export const setBuilingAvatar = createAction(SET_BUILDING_AVATAR);
export const setGlobalBuildingId = createAction(SET_GLOBAL_BUILDING_ID);
export const setGlobalSelectedBuilding = createAction(SET_GLOBAL_SELECTED_BUILDING);
export const setGlobalOrgId = createAction(SET_GLOBAL_ORG_ID);
export const setGlobalOrg = createAction(SET_GLOBAL_ORG);
export const setbuildingImg = createAction(SET_BUILDING_IMAGE);
export const setBuilding = createAction(SET_BUILDING);
export const clearBuilding = createAction(CLEAR_BUILDING);
export const setNewBuilding = createAction(SET_NEW_BUILDING);
export const clearNewBuilding = createAction(CLEAR_NEW_BUILDING);
export const clearBuildingError = createAction(CLEAR_ERROR);
export const getAllBuildings = createAction(GET_ALL_BUILDINGS);
export const setCachedBuildings = createAction(SET_CACHED_BUILDINGS);
export const setLoadingCachedBuildings = createAction(SET_LOADING_CACHED_BUILDINGS);
export const resetGlobalBuildings = createAction(RESET_GLOBAL_BUILDINGS);
export const setGlobalBuildingStatus = createAction(SET_GLOBAL_BUILDING_STATUS);
export const getDeliveryCodes = createAction(GET_DELIVERY_CODES);
export const addDeliveryCode = createAction(ADD_DELIVERY_CODE);
export const removeDeliveryCode = createAction(REMOVE_DELIVERY_CODE);
export const resetSuitesInBuilding = createAction(RESET_SUITE_IN_BUILDING);
export const showGlobalBuildingsDropdown = createAction(SHOW_GLOBAL_BUILDINGS_DROPDOWN);

export default {
  clearBuilding,
  removeBuilding,
  searchBuildings,
  addBuilding,
  editBuilding,
  getBuildings,
  getEditedBuildings,
  getBuilding,
  getBuildingsDevices,
  getBuildingUnits,
  setGlobalBuilding,
  setGlobalBuildingId,
  setGlobalOrgId,
  clearBuildingError,
  setbuildingImg,
  getAllBuildings,
  setCachedBuildings,
  setLoadingCachedBuildings,
  resetGlobalBuildings,
  setGlobalBuildingStatus,
  getDeliveryCodes,
  addDeliveryCode,
  removeDeliveryCode,
  getBuildingSuites,
  resetSuitesInBuilding,
  showGlobalBuildingsDropdown,
  setBuilding,
  setNewBuilding,
  clearNewBuilding
};
