import { CModalFooter } from '@coreui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeOpenedModal } from '../../redux/actions/headerModal';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { setToast } from '../../redux/actions/window';
import { addDeliveryCode, getAllBuildings, getDeliveryCodes } from '../../redux/actions/building';
import { UserRole } from '../users/constant';
import { getDDUnits } from '../../redux/actions/unit';
import {
  InputSeparator,
  SettingsModalBoldText,
  SettingsModalInput
} from '../settings/SettingsModals';
import { Select, SelectItem } from '../../components/ui/Select';
import { Button } from '../../components/ui/Button';
import { ModalHeader, StyledModal, StyledModalBody } from '../../components/Modals';
import { ErrorText } from '../../components/utils/AvatarCropper';
import { getRoomUnitTerminology } from '../../utils/utils';
import {useGetConfigs} from '../../hooks/useGetConfigs';

const AddDeliveryCode = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { modal } = useSelector((state) => state.headerModal);
  const { orgId, building_id, roleId } = useSelector((state) => state.auth);
  const { globalOrgId, AllOrganizations, organization } = useSelector(
    (state) => state.Organization
  );
  const { globalBuildingId, allBuildings } = useSelector((state) => state.building);
  const { unitsForDD } = useSelector((state) => state.unit);
  const { config } = useGetConfigs();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState({});
  const [isOrgSelected, setIsOrgSelected] = useState(false);
  const [isBuildingSelected, setIsBuildingSelected] = useState(false);

  const validationSchema = Yup.object().shape({
    deliveryCode: Yup.string()
      .matches(/^[0-9]+$/, t('Only numbers are allowed'))
      .min(4, t('Minimum length is 4 characters'))
      .max(8, t('Maximum length is 8 characters'))
      .required(t('Required')),
    orgInfo:
      roleId === UserRole.SUPER_ADMIN &&
      Yup.string()
        .matches(/^[0-9]+$/, t('Required!'))
        .required(t('Required')),
    buildingInfo:
      (roleId === UserRole.SUPER_ADMIN || roleId === UserRole.ADMIN) &&
      Yup.string()
        .matches(/^[0-9]+$/, t('Required!'))
        .required(t('Required')),
    unitInfo: Yup.string()
      .matches(/^[0-9]+$/, t('Required!'))
      .required(t('Required'))
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      deliveryCode: '',
      unitInfo: ''
    }
  });

  const handleGetDevices = useCallback(() => {
    if (roleId === UserRole.SUPER_ADMIN || roleId === UserRole.ADMIN) {
      const newRequestParams = {};
      if (globalOrgId !== 0) {
        newRequestParams.orgId = globalOrgId;
      }
      if (globalBuildingId !== 0) {
        newRequestParams.buildingId = globalBuildingId;
      }
      dispatch(getDeliveryCodes(newRequestParams));
    } else if (orgId && building_id) {
      dispatch(getDeliveryCodes({ orgId, buildingId: building_id }));
    }
  }, [globalOrgId, globalBuildingId, orgId, building_id]);

  const onSubmit = (values) => {
    setIsSubmitting(true);

    dispatch(
      addDeliveryCode({
        body: {
          code: values.deliveryCode,
          org_id: roleId === UserRole.SUPER_ADMIN ? Number(values?.orgInfo) : Number(orgId),
          building_id:
            roleId === UserRole.SUPER_ADMIN || roleId === UserRole.ADMIN
              ? Number(values?.buildingInfo)
              : Number(building_id),
          unit_id: Number(values.unitInfo)
        },
        success: () => {
          setIsSubmitting(false);
          handleGetDevices();
          dispatch(
            changeOpenedModal({
              modal: ''
            })
          );
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Delivery Code successfully added!')
            })
          );
        },
        fail: (err) => {
          setError((prev) => {
            if (typeof err?.data === 'string') {
              return { ...prev, message: err?.data };
            }
            return { ...prev, ...err?.data };
          });
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Failed to add Delivery Code!'),
              modal: 'errorToast'
            })
          );
          setIsSubmitting(false);
        }
      })
    );
  };

  const onClose = () => {
    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );
  };

  useEffect(() => {
    dispatch(
      getDDUnits({
        id: building_id
      })
    );
    if (roleId === UserRole.ADMIN) {
      setIsOrgSelected(true);
    } else if (roleId !== UserRole.SUPER_ADMIN && roleId !== UserRole.ADMIN) {
      setIsOrgSelected(true);
      setIsBuildingSelected(true);
    }
  }, []);

  return (
    modal === 'AddDeliveryCode' && (
      <div className="AddDeliveryCodeClass">
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledModal show={modal === 'AddDeliveryCode'} onClose={onClose} closeOnBackdrop={false}>
            <ModalHeader title={t('Add a Delivery Code')} />
            <StyledModalBody>
              <InputSeparator>
                <SettingsModalBoldText htmlFor="deliveryCode">
                  {t('Delivery Code')}
                </SettingsModalBoldText>

                <Controller
                  control={control}
                  name="deliveryCode"
                  render={({ field: { onChange, onBlur, value }, fieldState: {} }) => (
                    <SettingsModalInput
                      label="deliveryCode"
                      type="text"
                      placeholder={t('Codes...')}
                      autoComplete="text"
                      rows="5"
                      error={!!errors.code}
                      valid={!errors.code}
                      onBlur={onBlur}
                      onChange={(e) => {
                        const newValue = e.target.value.replace(/\D/, '');
                        onChange(newValue.slice(0, 8));
                        setError({});
                      }}
                      value={value}
                    />
                  )}
                />
                {errors.code && <ErrorText>{errors.code.message}</ErrorText>}
              </InputSeparator>
              {roleId === UserRole.SUPER_ADMIN && (
                <InputSeparator>
                  <SettingsModalBoldText>{t('Org Name')}</SettingsModalBoldText>

                  <Controller
                    control={control}
                    name="orgInfo"
                    render={({ field: { onChange, onBlur, value }, fieldState: {} }) => (
                      <Select
                        onBlur={onBlur}
                        error={!!errors.orgInfo}
                        onChange={(e) => {
                          if (e !== -1) {
                            dispatch(
                              getAllBuildings({
                                id: e
                              })
                            );
                            setIsOrgSelected(true);
                          } else {
                            setIsOrgSelected(false);
                          }
                          setValue('buildingInfo', undefined);
                          setValue('unitInfo', undefined);
                          setIsBuildingSelected(false);
                          onChange(e);
                        }}
                        placeholder="Select Organization"
                        id="organization"
                        value={value}>
                        <SelectItem value={-1}>{t('Select organization')}</SelectItem>

                        {AllOrganizations &&
                          AllOrganizations.map((item, index) => (
                            <SelectItem value={item.id} key={index}>
                              {item.name}
                            </SelectItem>
                          ))}
                      </Select>
                    )}
                  />
                  {errors.orgInfo && <ErrorText>{errors.orgInfo.message}</ErrorText>}
                </InputSeparator>
              )}
              {(roleId === UserRole.SUPER_ADMIN || roleId === UserRole.ADMIN) && (
                <InputSeparator>
                  <SettingsModalBoldText>{t('Building Name')}</SettingsModalBoldText>

                  <Controller
                    control={control}
                    name="buildingInfo"
                    render={({
                      field: { onChange, onBlur, value },
                      fieldState: { invalid, isDirty }
                    }) => (
                      <Select
                        error={!!errors.buildingInfo}
                        onBlur={onBlur}
                        onChange={(e) => {
                          if (e !== '') {
                            dispatch(
                              getDDUnits({
                                id: e
                              })
                            );
                            setIsBuildingSelected(true);
                          }
                          setValue('unitInfo', undefined);
                          onChange(e);
                        }}
                        id="building"
                        placeholder="Select Building"
                        value={value}
                        disabled={!isOrgSelected}>
                        <SelectItem value={''}>{t('Select Building')}</SelectItem>

                        {allBuildings &&
                          allBuildings.map((item, index) => (
                            <SelectItem value={item.id} key={index}>
                              {item.name}
                            </SelectItem>
                          ))}
                      </Select>
                    )}
                  />
                  {errors.buildingInfo && <ErrorText>{errors.buildingInfo.message}</ErrorText>}
                </InputSeparator>
              )}
              <InputSeparator>
                <SettingsModalBoldText>
                  {getRoomUnitTerminology(config)}
                </SettingsModalBoldText>

                <Controller
                  control={control}
                  name="unitInfo"
                  render={({ field: { onChange, onBlur, value, name, ref }, fieldState: {} }) => (
                    <Select
                      error={!!errors.unitInfo}
                      onBlur={onBlur}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      id="unit"
                      placeholder={getRoomUnitTerminology(config)}
                      value={value}
                      disabled={!isBuildingSelected || !isOrgSelected}>
                      <SelectItem value={''}>{`${t('Select')} ${getRoomUnitTerminology(
                        config
                      )}`}</SelectItem>

                      {unitsForDD &&
                        unitsForDD.map((item, index) => (
                          <SelectItem value={item.id} key={index}>
                            {item.unit_num}
                          </SelectItem>
                        ))}
                    </Select>
                  )}
                />

                {errors.unitInfo && <ErrorText>{errors.unitInfo.message}</ErrorText>}
              </InputSeparator>
            </StyledModalBody>

            <CModalFooter>
              <Button onClick={onClose} text={t('Cancel')} width={'100px'} />
              <Button
                isPrimary
                text={t('Add')}
                width={'100px'}
                disabled={isSubmitting || !isValid}
              />
            </CModalFooter>
          </StyledModal>
        </form>
      </div>
    )
  );
};

export default AddDeliveryCode;
