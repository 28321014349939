import React from 'react';
import styled from 'styled-components';
import { Fonts, Palette } from '../utils';

const DashboardBreadcrumb = () => {
  return <DashboardText>Dashboard</DashboardText>;
};

const DashboardText = styled.p`
  color: ${Palette.BLACK};
  text-align: center;
  font-family: ${Fonts.RED_HAT_FONT};
  font-size: 48.83px;
  font-style: normal;
  font-weight: 800;
  line-height: 56px;
  margin: 0;
`;

export default DashboardBreadcrumb;
