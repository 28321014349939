import React, { useEffect, useMemo, useState } from 'react';
import { CCol, CRow } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import {
  InputSeparator,
  SettingsModalBoldText,
  SettingsModalInput,
  SettingsModalLightText
} from '../../settings/SettingsModals';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../../helper/modals';
import {
  addUserTypeTitle,
  ToggleItem,
  ToggleItemComponentContainer,
  ToggleItemDescription,
  ToggleItemTextContainer,
  ToggleItemTitle
} from '../ManageUserComponents';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, FormProvider, useWatch } from 'react-hook-form';
import { ErrorMessage } from '../../../components/ErrorMessage';
import { ErrorText } from '../../../components/utils/AvatarCropper';
import { updateUserTypeData } from '../../../redux/actions/addUserType';
import {
  BUILDING_TYPE,
  DATE_FORMATS,
  hasPermission,
  USER_ROLE,
  USER_TYPE
} from '../../users/constant';
import {
  ConditionalBuildingField,
  ConditionalField,
  ConditionalResidentField
} from './ConditionalComponents';
import { FeatureSwitch } from '../../offers/styles';
import { Select, SelectItem } from '../../../components/ui/Select';
import { ConditionalUserTypeField } from './ConditionalComponents';
import { closeAddUserTypeModal } from '../ManageUserComponents';
import {
  ModalButtons,
  ModalHeader,
  StyledModal,
  StyledModalBody
} from '../../../components/Modals';
import { USER_SCHEMA } from '../../../helper/schemas';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '../../../components/ui/Form';
import { CustomDatePicker } from '../../../components/CustomSearchBar';
import moment from 'moment-timezone';
import { clearConflictingDatesData, getConflictingDates } from '../../../redux/actions/unit';
import { Palette } from '../../../components/utils';
import { TIME_ZONES } from '../../../helper/constants';
import { getFullDateWithOutTime, USER_TYPES } from '../../../helper';

const AddNewUserTypeModalStep2 = () => {
  const { t } = useTranslation();
  const { modal } = useSelector((state) => state.headerModal);
  const addUserType = useSelector((state) => state.addUserType);
  const Organization = useSelector((state) => state.Organization);
  const { roleId } = useSelector((state) => state.auth);
  const { conflictingMoveDates } = useSelector((state) => state.unit);
  const building = useSelector((state) => state.building);
  const dispatch = useDispatch();
  const [selectedBuildingsTimezone, setSelectedBuildingsTimezone] = useState(
    addUserType.selectedBuildingsTimezone
  );

  const disabledTime = (current) => {
    const now = moment();
    if (current && current.isSame(now, 'day')) {
      return {
        disabledHours: () => Array.from({ length: now.hour() }).map((_, i) => i),
        disabledMinutes: (selectedHour) => {
          if (selectedHour === now.hour()) {
            return Array.from({ length: now.minute() }).map((_, i) => i);
          }
          return [];
        }
      };
    }
    return {};
  };

  const isResident = addUserType.role === USER_ROLE.USER.id;
  const schema = USER_SCHEMA.concat(
    Yup.object().shape({
      email: Yup.string().email(t('Invalid email address')).required(t('Email is required')),
      organization: Yup.object()
        .shape({
          organizationId: Yup.number().required('Organization is required'),
          name: Yup.string().required('Organization is required')
        })
        .required('Organization is required'),
      buildings: Yup.object().shape({
        buildingId: Yup.number().required(),
        name: Yup.string().required('Building name is required')
      }),
      // buildings: Yup.mixed().required(t('Building is required')),
      sendWelcomeEmail: Yup.boolean().required(t('Unit Number is required')),
      welcomeEmailScheduleDateTime: Yup.string(),

      moveInDate: Yup.string().when(['buildings.type', 'type'], {
        is: (buildingType, userType) =>
          buildingType === BUILDING_TYPE.TEMPORARY_RESIDENCY.id && userType === USER_TYPES.resident,
        then: Yup.string().required('Move-in date is required for hotels'),
        otherwise: Yup.string()
      }),

      moveOutDate: Yup.string().when(['buildings.type', 'type'], {
        is: (buildingType, userType) =>
          buildingType === BUILDING_TYPE.TEMPORARY_RESIDENCY.id && userType === USER_TYPES.resident,
        then: Yup.string().required('Move-out date is required for hotels'),
        otherwise: Yup.string()
      }),

      // Conditional Validations:
      // Resident - Must provide and validate unit number
      unitNumber: Yup.mixed().when(['buildings.type'], {
        is: (buildingType) => buildingType === BUILDING_TYPE.TEMPORARY_RESIDENCY.id && isResident,
        then: Yup.mixed().required('Unit is required for hotels'),
        otherwise: Yup.mixed().notRequired()
      }),
      reservation: Yup.mixed().when(['buildings.type', 'type'], {
        is: (buildingType, userType) =>
          buildingType === BUILDING_TYPE.TEMPORARY_RESIDENCY.id &&
          userType === USER_TYPES['co-resident'],
        then: Yup.object()
          .shape({
            id: Yup.number().required('Reservation is required')
          })
          .typeError('Reservation is required')
          .required('Reservation is required'),
        otherwise: Yup.mixed().notRequired()
      }),

      // Resident - Must provide and validate user type
      type: Yup.mixed().when([], {
        is: () => addUserType.role === USER_ROLE.USER.id,
        then: Yup.number()
          .moreThan(0, t('User type is required'))
          .required(t('User type is required')),
        otherwise: Yup.mixed().notRequired()
      })
    })
  );

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      firstName: addUserType.firstName,
      lastName: addUserType.lastName,
      email: addUserType.email,
      type: addUserType.type,
      homePhoneNumber: addUserType.homePhoneNumber,
      mobilePhoneNumber: addUserType.mobilePhoneNumber,
      organization: addUserType.organization,
      buildings: addUserType.buildings,
      unitNumber: addUserType.unitNumber,
      reservation: undefined,
      moveInDate: addUserType.moveInDate,
      moveOutDate: addUserType.moveOutDate,
      monthlyRent: addUserType.monthlyRent,
      sendWelcomeEmail: addUserType.sendWelcomeEmail,
      welcomeEmailScheduleDateTime: addUserType.welcomeEmailScheduleDateTime
    }
  });

  const userTypeWatch = useWatch({ control: methods.control, name: 'type' });
  const buildingsWatch = useWatch({ control: methods.control, name: 'buildings' });
  const moveInDateWatch = useWatch({ control: methods.control, name: 'moveInDate' });
  const moveOutDateWatch = useWatch({ control: methods.control, name: 'moveOutDate' });
  const unitWatch = useWatch({ control: methods.control, name: 'unitNumber' });

  const onSubmit = (data) => {
    dispatch(
      updateUserTypeData({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        homePhoneNumber: data.homePhoneNumber,
        mobilePhoneNumber: data.mobilePhoneNumber,
        organization: data.organization,
        type: data.type,
        moveInDate: data.moveInDate,
        moveOutDate: data.moveOutDate,
        buildings: data.buildings,
        unitNumber: data.unitNumber,
        monthlyRent: data.monthlyRent,
        sendWelcomeEmail: data.sendWelcomeEmail,
        welcomeEmailScheduleDateTime: data.welcomeEmailScheduleDateTime,
        selectedBuildingsTimezone: selectedBuildingsTimezone,
        reservation: data.reservation
      })
    );
    openModal('AddNewUserTypeModalStep3');
  };

  const onClose = () => {
    dispatch(clearConflictingDatesData());
    closeAddUserTypeModal(addUserType.editingID);
  };

  useEffect(() => {
    if (unitWatch?.id && moveInDateWatch && moveOutDateWatch && userTypeWatch) {
      if (userTypeWatch === USER_TYPE.CO_RESIDENT.id) {
        return;
      }
      dispatch(
        getConflictingDates({
          unitId: unitWatch.id,
          moveInDate: moveInDateWatch,
          moveOutDate: moveOutDateWatch,
          userID: addUserType.editingID ? addUserType.editingID : 0
        })
      );
    }
  }, [unitWatch, moveInDateWatch, moveOutDateWatch, userTypeWatch, addUserType.editingID]);

  useEffect(() => {
    if (buildingsWatch) {
      const foundBuilding = building.allBuildings.find(
        (building) => building.id === buildingsWatch.buildingId
      );
      if (foundBuilding) {
        setSelectedBuildingsTimezone(() => TIME_ZONES.get(foundBuilding.state));
        if (foundBuilding.type === BUILDING_TYPE.TEMPORARY_RESIDENCY.id) {
          methods.setValue('sendWelcomeEmail', false);
        }
      } else {
        setSelectedBuildingsTimezone(undefined);
      }
    } else {
      setSelectedBuildingsTimezone(undefined);
    }
  }, [buildingsWatch, building.allBuildings]);

  useEffect(() => {
    dispatch(clearConflictingDatesData());
  }, [userTypeWatch]);

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <StyledModal show={modal === 'AddNewUserTypeModalStep2'} closeOnBackdrop={false}>
        <ModalHeader title={addUserTypeTitle(addUserType)} onCloseCallback={onClose} />
        <FormProvider {...methods}>
          <StyledModalBody>
            <CRow>
              <CCol>
                <InputSeparator>
                  <Controller
                    defaultValue={addUserType.firstName}
                    control={methods.control}
                    name="firstName"
                    render={({ field: { name, ...rest } }) => (
                      <>
                        <SettingsModalBoldText>{t('First Name')}</SettingsModalBoldText>
                        <SettingsModalInput
                          {...rest}
                          error={!!methods.formState.errors.firstName}
                          placeholder={t('First Name')}
                        />
                        <ErrorMessage
                          errors={methods.errors}
                          name={name}
                          render={({ message }) => <ErrorText>{message}</ErrorText>}
                        />
                      </>
                    )}
                  />
                </InputSeparator>
              </CCol>
              <CCol>
                <InputSeparator>
                  <Controller
                    defaultValue={addUserType.lastName}
                    control={methods.control}
                    name="lastName"
                    render={({ field: { name, ...rest } }) => (
                      <>
                        <SettingsModalBoldText>{t('Last Name')}</SettingsModalBoldText>
                        <SettingsModalInput
                          {...rest}
                          error={!!methods.formState.errors.lastName}
                          placeholder={t('Last Name')}
                        />
                        <ErrorMessage
                          errors={methods.errors}
                          name={name}
                          render={({ message }) => <ErrorText>{message}</ErrorText>}
                        />
                      </>
                    )}
                  />
                </InputSeparator>
              </CCol>
            </CRow>

            <InputSeparator>
              <Controller
                defaultValue={addUserType.email}
                control={methods.control}
                name="email"
                render={({ field: { name, ...rest } }) => (
                  <>
                    <SettingsModalBoldText>{t('Email')}</SettingsModalBoldText>
                    <SettingsModalInput
                      {...rest}
                      error={!!methods.formState.errors.email}
                      placeholder={t('Email')}
                    />
                    <ErrorMessage
                      errors={methods.errors}
                      name={name}
                      render={({ message }) => <ErrorText>{message}</ErrorText>}
                    />
                  </>
                )}
              />
            </InputSeparator>

            <CRow>
              <CCol>
                <InputSeparator>
                  <Controller
                    defaultValue={addUserType.homePhoneNumber}
                    control={methods.control}
                    name="homePhoneNumber"
                    render={({ field: { name, ...rest } }) => (
                      <>
                        <SettingsModalBoldText>{t('Home Phone Number')}</SettingsModalBoldText>
                        <SettingsModalInput
                          {...rest}
                          error={!!methods.formState.errors.homePhoneNumber}
                          placeholder={t('Home Number')}
                        />
                        <ErrorMessage
                          errors={methods.errors}
                          name={name}
                          render={({ message }) => <ErrorText>{message}</ErrorText>}
                        />
                      </>
                    )}
                  />
                </InputSeparator>
              </CCol>
              <CCol>
                <InputSeparator>
                  <Controller
                    defaultValue={addUserType.mobilePhoneNumber}
                    control={methods.control}
                    name="mobilePhoneNumber"
                    render={({ field: { name, ...rest } }) => (
                      <>
                        <SettingsModalBoldText>{t('Mobile Phone Number')}</SettingsModalBoldText>
                        <SettingsModalInput
                          {...rest}
                          error={!!methods.formState.errors.mobilePhoneNumber}
                          placeholder={t('Mobile Number')}
                        />
                        <ErrorMessage
                          errors={methods.errors}
                          name={name}
                          render={({ message }) => <ErrorText>{message}</ErrorText>}
                        />
                      </>
                    )}
                  />
                </InputSeparator>
              </CCol>
            </CRow>

            {/* Residents only - The unit the resident will be assigned to*/}
            <ConditionalField
              conditions={[addUserType.role === USER_ROLE.USER.id]}
              renderInactive={() => null}
              renderActive={() => <ConditionalUserTypeField />}
            />

            <InputSeparator>
              <Controller
                control={methods.control}
                defaultValue={addUserType.organization}
                name="organization"
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <>
                      <SettingsModalBoldText>{t('Organization')}</SettingsModalBoldText>
                      <Select
                        loading={Organization.status === 'GET_ALL_ORGANIZATIONS_PENDING'}
                        error={methods.formState.errors?.organization}
                        disabled={
                          addUserType.editingID !== undefined || roleId !== USER_ROLE.SUPER_ADMIN.id
                        }
                        placeholder="Select Organization"
                        onChange={(e) => {
                          const foundOrganization = Organization.AllOrganizations.find(
                            (org) => e === org.id
                          );
                          onChange({
                            organizationId: foundOrganization.id,
                            name: foundOrganization.name
                          });
                          methods.resetField('buildings');
                          methods.setValue('reservation', undefined);
                        }}
                        value={value.name || undefined}>
                        {Organization.AllOrganizations.map((org) => (
                          <SelectItem key={org.id} value={org.id}>
                            {org.name}
                          </SelectItem>
                        ))}
                      </Select>
                      <ErrorMessage
                        overrideError={methods.formState.errors?.organization?.name}
                        render={({ message }) => <ErrorText>{message}</ErrorText>}
                      />
                    </>
                  );
                }}
              />
            </InputSeparator>

            <ConditionalBuildingField />

            {/* Residents only - The unit the resident will be assigned to*/}
            <ConditionalField
              conditions={[addUserType.role === USER_ROLE.USER.id]}
              renderInactive={() => null}
              renderActive={() => <ConditionalResidentField />}
            />

            {conflictingMoveDates && conflictingMoveDates.length > 0 && (
              <>
                <SettingsModalBoldText cssOverride={`color: ${Palette.ERROR}`}>
                  Conflicting Move Dates
                </SettingsModalBoldText>
                {conflictingMoveDates
                  .filter((date) => !(addUserType.editingID && date.id === addUserType.editingID))
                  .map((date) => {
                    return (
                      <div>
                        <SettingsModalLightText cssOverride={`color: ${Palette.ERROR}`}>
                          {date.user}
                        </SettingsModalLightText>
                        <SettingsModalLightText cssOverride={`color: ${Palette.ERROR}`}>
                          {getFullDateWithOutTime(date.move_in_date)} -{' '}
                          {getFullDateWithOutTime(date.move_out_date)}
                        </SettingsModalLightText>
                      </div>
                    );
                  })}
              </>
            )}

            <ConditionalField
              conditions={[
                addUserType.editingID === undefined && hasPermission(roleId, USER_ROLE.SUPER_ADMIN)
              ]}
              renderInactive={() => null}
              renderActive={() => (
                <Controller
                  control={methods.control}
                  defaultValue={addUserType.sendWelcomeEmail}
                  name="sendWelcomeEmail"
                  render={({ field: { onChange, value, name } }) => (
                    <ToggleItem>
                      <ToggleItemTextContainer>
                        <ToggleItemTitle>{t('Send Welcome Email')}</ToggleItemTitle>
                        <ToggleItemDescription>{t('Send Welcome Email')}</ToggleItemDescription>
                        <ErrorMessage
                          errors={methods.errors}
                          name={name}
                          render={({ message }) => <ErrorText>{message}</ErrorText>}
                        />
                      </ToggleItemTextContainer>
                      <ToggleItemComponentContainer>
                        <FeatureSwitch
                          onChange={(e) => {
                            onChange(e);
                            methods.setValue('welcomeEmailScheduleDateTime', '');
                          }}
                          checked={value}
                          value={value}
                          shape="pill"
                          color="primary"
                        />
                      </ToggleItemComponentContainer>
                    </ToggleItem>
                  )}
                />
              )}
            />

            <ConditionalField
              conditions={[
                roleId === USER_ROLE.SUPER_ADMIN.id && methods.getValues('sendWelcomeEmail')
              ]}
              renderInactive={() => null}
              renderActive={() => (
                <FormField
                  control={methods.control}
                  name="welcomeEmailScheduleDateTime"
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormControl>
                          <FormLabel>Schedule Date</FormLabel>
                          <CustomDatePicker
                            {...field}
                            width={'100%'}
                            height={'56px'}
                            disabledTime={disabledTime}
                            disabledDate={(current) => current < moment().startOf('day')}
                            format={DATE_FORMATS.YYYY_MM_DD_hh_mm_A}
                            showTime={{
                              format: 'hh:mm a'
                            }}
                            showSecond={false}
                            placeholder={t('Instantly')}
                            onChange={(e) => {
                              if (e) {
                                field.onChange(e);
                              } else {
                                field.onChange(undefined);
                              }
                            }}
                            value={field.value ? moment(field.value) : undefined}
                          />
                          {/* {methods.getValues("buildings") && } */}
                          <FormMessage />
                        </FormControl>
                      </FormItem>
                    );
                  }}
                />
              )}
            />
          </StyledModalBody>
          <ModalButtons
            leftButtonText={t('Back')}
            leftButtonClick={() => openModal('AddNewUserTypeModalStep1')}
            rightButtonText={t('Next')}
            rightDisabled={
              (conflictingMoveDates &&
                conflictingMoveDates.filter(
                  (date) => !(addUserType.editingID && date.id === addUserType.editingID)
                ).length > 0) ||
              !selectedBuildingsTimezone
            }
          />
        </FormProvider>
      </StyledModal>
    </form>
  );
};

export default AddNewUserTypeModalStep2;
