// The ids should match STATUS_MAP
const DeliveryStatus = {
  NEW: 0,
  OPEN: 1,
  PENDING: 2,
  CANCELLED: 3,
  CLOSED: 4,
  COMPLETE: 5
};

const MAX_IMG_FILE_SIZE = 5 * 1024 * 1024;
const ACCEPTED_IMG_EXTENSIONS = [".png", ".jpeg", ".jpg"];
const ACCEPTED_IMG_MIME_TYPES = ["image/png", "image/jpeg"];

export {
  DeliveryStatus,
  MAX_IMG_FILE_SIZE,
  ACCEPTED_IMG_EXTENSIONS,
  ACCEPTED_IMG_MIME_TYPES
}
