import { STATUS_MAP } from '../utils/mappings';
import { sortBy, uniqBy } from 'lodash';
import { isArray } from 'lodash';
import Resizer from 'react-image-file-resizer';
import { MediaKeys } from '../views/offers/utils';
import { categoryTypes } from '../views/services/categories';
import { store } from '../index';
import { updateUserTypeData } from '../redux/actions/addUserType';
import { openModal } from './modals';
import { setToast } from '../redux/actions/window';
import { getUser } from '../redux/actions/user';
import { baseURL } from '../utils/axios';
import { getOffer } from '../redux/actions/offers';
import { getCommentId, getService } from '../redux/actions/services';
import { getParAMBooking } from '../redux/actions/bookingParAm';
import moment from 'moment';
import { getBuilding } from '../redux/actions/building';

export const NOTIFICATIONS_OFFERS_ID = 4;
export const NOTIFICATIONS_BOOKINGS_ID = 8;
export const NOTIFICATIONS_REQUESTS_ID = 10;

export const ImagesOptions = (increaseResolution = 1) => ({
  [MediaKeys.MEDIA]: {
    DIMENSION: {
      WIDTH: 428 * increaseResolution,
      HEIGHT: 144 * increaseResolution
    }
  },
  [MediaKeys.EXTRA_MEDIA]: {
    DIMENSION: {
      WIDTH: 366 * increaseResolution,
      HEIGHT: 160 * increaseResolution
    }
  },
  [MediaKeys.BANNER_LOGO]: {
    DIMENSION: {
      WIDTH: 125 * increaseResolution,
      HEIGHT: 125 * increaseResolution
    }
  }
});

export const ImageCropperOptions = {
  SCALE: {
    DEFAULT: 1,
    MIN: 1,
    MAX: 2,
    STEP: 0.1
  }
};

export const compressImgFile = (imgFile, width, height) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      imgFile,
      width,
      height,
      'png',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'blob',
      width,
      height
    );
  });
};

export const generateImageURLObj = (imageData) => {
  return URL.createObjectURL(imageData);
};

export const defaultImgCDN = `https://storage.googleapis.com/${process.env.REACT_APP_STORAGE_SUB_URL}/2-66161d78-374b-45df-b185-53db28b919b2.png`;
export const uploadBusinessImg = `https://storage.googleapis.com/${process.env.REACT_APP_STORAGE_SUB_URL}/2-39199882-2ccb-41e4-9618-75d8408a523d.png`;

export const businessId = '16';

export const dayTypes = {
  ALL_DAYS: 1,
  WEEKDAYS: 2,
  WEEKENDS: 3,
  SELECT_DAYS: 4
};

export const getFullDate = (string) => {
  if (string !== '' && string !== undefined && string !== null) {
    const date = new Date(string);
    const fullDate = `${date.toUTCString().split('GMT')[0]}`;
    return fullDate;
  }
  return '';
};
export const getDayInString = (selectedDays) => {
  const weekdays = 'M,T,W,TH,F';
  const weekends = 'S,SU';
  if (selectedDays) {
    let daysInstring = '';
    if (isArray(selectedDays)) {
      selectedDays.map(({ value }, index) => {
        if (index === selectedDays.length - 1) daysInstring = `${daysInstring}${value}`;
        else daysInstring = `${daysInstring}${value},`;
      });
    } else {
      if (selectedDays.value === dayTypes.ALL_DAYS) daysInstring = `${weekdays},${weekends}`;
      else if (selectedDays.value === dayTypes.WEEKENDS) daysInstring = `${weekends}`;
      else if (selectedDays.value === dayTypes.WEEKDAYS) daysInstring = `${weekdays}`;
    }
    return daysInstring;
  }
  return '';
};

// working in bookin parking and amenity details
export const getFullDateWithOutTime = (string) => {
  if (string !== '' && string !== undefined && string !== null) {
    const date = new Date(string);
    //${date.toUTCString().split(" ")[0]}
    const fullDate = `${date.toUTCString().split(' ')[2]}  ${date.toUTCString().split(' ')[1]},  ${
      date.toUTCString().split(' ')[3]
    }`;
    return fullDate;
  }
  return '';
};

//this is being used in service table.
export const showTimeInTable = (string) => {
  if (string !== '' && string !== undefined && string !== null) {
    const dateTime = convertTo12Hr(
      new Date(string).toUTCString().split(',')[1].toUTCString().trim()
    );
    return dateTime;
  }
  return '';
};

// this is give dateTime in 12 hours created by irzum (this is for created/updated Time)
//working fine in service Detail(created time)
export const getFullDate12Hr = (dateTime, useDetailedTimestamp) => {
  if (dateTime !== '' && dateTime !== undefined && dateTime !== null) {
    const tempDate = String(new Date(dateTime));
    const splitedTempDate = tempDate.split(' ');
    const fullDate = `${splitedTempDate[0]} ${splitedTempDate[1]} ${splitedTempDate[2]} ${splitedTempDate[3]}`;

    let timeArr = String(new Date(dateTime)).split(' GMT')[0].split(' ');
    let time;
    if (useDetailedTimestamp) {
      time = timeArr[timeArr.length - 1];
    } else {
      time = convertTo12Hr(timeArr[timeArr.length - 1]);
    }

    const splitFullDate = fullDate.split(' ');

    const splitedDay = splitFullDate[0];
    const splitedMonth = splitFullDate[1];
    const splitedDate = splitFullDate[2];
    const splitedYear = splitFullDate[3];

    return `${splitedMonth} ${splitedDate}, ${splitedYear}, ${time}`;
  }
  return '';
};

const convertTo12Hr = (string) => {
  if (string !== '' && string !== undefined && string !== null) {
    let [hours, minutes] = string.split(':').map(Number);

    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours || 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;

    return `${hours}:${minutes} ${ampm}`;
  }
  return '';
};

export const getTimeFromUtcString = (string) => {
  if (string !== '' && string !== undefined && string !== null) {
    const date = new Date(string);
    let time = `${date.getHours()}:${date.getMinutes()}`;
    time = convertTo12Hrs(time);
    return time;
  }
  return '';
};

export const maxFieldLengthCheck = (maxLength, e) => {
  const { value, length } = e.target;
  if (String(value).length >= maxLength) {
    e.preventDefault();
    return;
  }
};

export const dayOnlyFromString = (string) => {
  if (string !== '' && string !== undefined && string !== null) {
    const dayTime = getFullDate(string).split(' ');

    return `${dayTime[2]} ${dayTime[1]}, ${dayTime[3]}`;
  }
  return '';
};

// this is give time in 12 hours with hardcode min 00 created by sanan
export const convertTo12Hrs = (string) => {
  if (string !== '' && string !== undefined && string !== null) {
    let timehrs = parseInt(string.split(':')[0]);

    if (timehrs === 24) return `12:00 am`;
    if (timehrs > 12) {
      timehrs = timehrs - 12;

      return timehrs > 9 ? `${timehrs}:00 pm` : `0${timehrs}:00 pm`;
    }
    if (timehrs === 12) return timehrs > 9 ? `${timehrs}:00 pm` : `0${timehrs}:00 pm`;
    if (timehrs === 0) return timehrs > 9 ? `${timehrs}:00 am` : `0${timehrs}:00 am`;

    return timehrs > 9 ? `${timehrs}:00 am` : `0${timehrs}:00 am`;
  }
  return '';
};

export const convertTime = (string) => {
  if (string !== '' && string !== undefined && string !== null) {
    if (string.includes('T')) {
      const time = string.split('T')[1];

      //this check if for amenity if ending time is 12am then it ll be 11:59 because if it ll be 12am next day ll start and VIc havent implemented mulitple days yet
      if (time === '23:59:00Z') return '24:00';

      const hour = time.split(':');
      return `${hour[0]}:${hour[1]}`;
    } else {
      const hour = string.split(':');
      return `${hour[0]}:${hour[1]}`;
    }
  }
  return '';
};

export const getIconForService = (category) => {
  if (category === 'electric') return 'freeSet.cilBolt';
};

// get user according to their types START created by irzum
export const getUserType = (id) => {
  let usertype;
  if (id !== '' && id !== undefined && id !== null) {
    switch (parseInt(id)) {
      case 1:
        usertype = 'Resident';
        break;
      case 2:
        usertype = 'Co-Resident';
        break;
      default:
        usertype = '';
        break;
    }
    return usertype;
  }
  return localStorage.getItem('type_id');
};
// get user according to their types END created by irzum

//get all users of specific type, like resident or admin etc START created by irzum
export const getUsersOfSpecificType = (users, type) => {
  if (
    users !== '' &&
    users !== undefined &&
    users !== null &&
    type !== '' &&
    type !== undefined &&
    type !== null
  ) {
    const filteredUsers = users.filter((users) => String(users.type_id) === String(type));
    return filteredUsers;
  }
  return [];
};
//get all users of specific type, like resident or admin etc END

//get status from string Starts created by irzum
export const getStatusId = (statusName) => {
  if (statusName !== '' && statusName !== undefined && statusName !== null) {
    const statusId = STATUS_MAP.filter((status) => String(status.value) === String(statusName));
    return statusId[0].id;
  }
  return 0;
};
//get status from string ENDS

//get status from id Starts created by irzum
export const getStatusFromId = (id) => {
  if (id !== '' && id !== undefined && id !== null) {
    const statusId = STATUS_MAP.filter((status) => String(status.id) === String(id));
    return statusId[0]?.value;
  }
  return 'loading..';
};
//get status from id ENDS created by irzum

//get userName from User_id created by irzum
export const getUserFromId = (users, id) => {
  if (
    users !== '' &&
    users !== undefined &&
    users !== null &&
    id !== '' &&
    id !== undefined &&
    id !== null
  ) {
    const user = users.filter((item) => String(item.id) === String(id));
    return `${user[0].first_name} ${user[0].last_name}`;
  }
  return localStorage.getItem('firstname');
};
//get userName from User_id ENDS

//get buildingName from building_id Starts created by irzum
export const getBuildingFromId = (buildings, id) => {
  if (
    buildings !== '' &&
    buildings !== undefined &&
    buildings !== null &&
    id !== '' &&
    id !== undefined &&
    id !== null
  ) {
    const building = buildings.find((item) => String(item.id) === String(id));
    return building?.name || id;
  }
  return `builindId - ${localStorage.getItem('building_id')}`;
};
//get buildingName from building_id ENDS

//get amenity name from id
export const getAmenityFromId = (amenites, id) => {
  if (
    amenites !== '' &&
    amenites !== undefined &&
    amenites !== null &&
    id !== '' &&
    id !== undefined &&
    id !== null
  ) {
    const building = amenites.find((item) => String(item.id) === String(id));
    return building.type;
  }
  return '';
};
//get amenity name from id END

//get service CategoryName from categoryId_id Starts created by irzum
export const getServiceCategoryFromId = (categories, id) => {
  if (id === categoryTypes.SECURITY) return '';
  if (categories) {
    const cat = categories.filter((item) => String(item.ID) === String(id));
    return `${cat[0]?.Name}/${cat[0].SubCategory}`;
  }
  return '';
};
//get service CategoryName from categoryId_id ENDS

//get priority name from id start
export const getPriorityFromId = (id) => {
  let priority;
  switch (parseInt(id)) {
    case 1:
      priority = 'High';
      break;
    case 2:
      priority = 'Medium';
      break;
    case 3:
      priority = 'Low';
      break;
    default:
      priority = 'High';
  }

  return priority;
};
//get priority name from id end

//get ContactWith from id start

//get Action of logs agianst their number
export const getLogAction = (id) => {
  let logAction;
  switch (parseInt(id)) {
    case 1:
      logAction = 'EventLogin';
      break;
    case 2:
      logAction = 'EventClickServiceRequest';
      break;
    case 900:
      logAction = 'Unlock';
      break;
    case 901:
      logAction = 'Lock';
      break;
    case 902:
      logAction = 'Paired';
      break;
    case 903:
      logAction = 'Unpaired';
      break;
    case 904:
      logAction = 'Code Generated';
      break;
    default:
      logAction = 'None';
  }

  return logAction;
};
export const getContactWithFromId = (id) => {
  let contactWith;
  const parseId = parseInt(id);
  switch (parseId) {
    case 1:
      contactWith = 'Email';
      break;
    case 2:
      contactWith = 'Phone';
      break;
    default:
      contactWith = 'Email';
  }
  return contactWith;
};
//get ContactWith from id end

export const getAmenityAvailabelTime = (array, formType, editTime) => {
  if (array && array.length) {
    if (formType === 'add') {
      const mapedData = array.map(({ start_time, end_time }) => {
        let start_time_local = parseInt(start_time.split('T')[1].split(':')[0]);
        let end_time_local = parseInt(end_time.split('T')[1].split(':')[0]);

        return {
          start_time: start_time_local <= 9 ? `0${start_time_local}:00` : `${start_time_local}:00`,
          end_time: end_time_local <= 9 ? `0${end_time_local}:00` : `${end_time_local}:00`
        };
      });
      return mapedData;
    } else {
      if (editTime) {
        const appendarray = [];

        if (editTime.end_time !== -1) {
          const start_time_booked = parseInt(editTime.start_time);

          const end_time_booked = parseInt(editTime.end_time);

          for (let i = start_time_booked; i < end_time_booked; i++) {
            appendarray.push({
              start_time: i <= 9 ? `0${i}:00` : `${i}:00`,
              end_time: i + 1 <= 9 ? `0${i + 1}:00` : `${i + 1}:00`
            });
          }
        }

        const mapedData = array.map(({ start_time, end_time }) => {
          let start_time_local = parseInt(start_time.split('T')[1].split(':')[0]);
          let end_time_local = parseInt(end_time.split('T')[1].split(':')[0]);

          return {
            start_time:
              start_time_local <= 9 ? `0${start_time_local}:00` : `${start_time_local}:00`,
            end_time: end_time_local <= 9 ? `0${end_time_local}:00` : `${end_time_local}:00`
          };
        });
        const finalArrayData = [...mapedData, ...appendarray];
        return uniqBy(sortBy(finalArrayData, ['start_time', 'end_time']), 'start_time', 'end_time');
      }
    }
  }
  return [];
};

export const getDateMonthYear = (string) => {
  if (string) {
    const date = string.split('T')[0].split('-');
    return date;
  }
  return ['0000', '00', '00'];
};

export const imageString = (string, businesImg = false) => {
  if (string === '' || string === undefined || string === null)
    if (businesImg) return uploadBusinessImg;
    else return defaultImgCDN;
  else {
    if (string.includes('https')) return string;
    else return `https://storage.googleapis.com/${process.env.REACT_APP_STORAGE_SUB_URL}/${string}`;
  }
};

// NotificationGeneral  = 1
// NotificationRequests = 2 (service)
// NotificationSecurity = 3
// NotificationOffers   = 4 (offer)
// NotificationBookings = 5
export const getImgForNotification = (id) => {
  let img;
  switch (parseInt(id)) {
    case 1:
      img = 'Resident';
      break;
    case 2:
      img = 'Co-Resident';
      break;
    case 3:
      img = 'Building Manager';
      break;
    case 4:
      img = 'Technician';
      break;
    default:
      img = 'Resident';
      break;
  }

  return img;
};

// time to show notification received

export const convertTimeToMilliSec = (string) => {
  if (string !== '' && string !== undefined && string !== null) {
    const date = new Date(string);

    return date.getTime();
  }
  return '';
};

export const timeForReceivedItm = function (date) {
  if (typeof date !== 'object') {
    date = new Date(date);
  }

  var seconds = Math.floor((new Date() - date) / 1000);
  var intervalType;

  var interval = Math.floor(seconds / 31536000);
  if (interval >= 1) {
    intervalType = 'year';
  } else {
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      intervalType = 'month';
    } else {
      interval = Math.floor(seconds / 86400);
      if (interval >= 1) {
        intervalType = 'day';
      } else {
        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
          intervalType = 'hour';
        } else {
          interval = Math.floor(seconds / 60);
          if (interval >= 1) {
            intervalType = 'minute';
          } else {
            interval = seconds;
            intervalType = 'second';
          }
        }
      }
    }
  }

  if (interval > 1 || interval === 0) {
    intervalType += 's';
  }

  return interval + ' ' + intervalType;
};

// Rounds the moment datetime object up by 1 'roundBy'
export const roundUp = (momentObj, roundBy, amount = 1) => {
  return momentObj.add(amount, roundBy).startOf(roundBy);
};

export const splitStart =
  '<div style="background-color: #fff; padding: 15px; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px; margin-bottom: 64px;" class="bodySection">';
export const splitEnd = '<footer style="text-align: center;">';
export const getOrgLogoStyle = (url) =>
  `<img style="max-height: 150px; margin-left: auto; margin-right: auto; margin-top: 2em; object-fit: contain;" src="${url}" alt="logo" />`;
export const injectOrgLogo = ({ url = '', document }) => {
  const startPlaceHolder = '<!-- org logo placeholder start -->';
  const endPlaceHolder = '<!-- org logo placeholder end -->';
  const splitedStart = document.split(startPlaceHolder);
  // more than one placeholder should not appear
  if (splitedStart.length !== 2) {
    return document;
  }
  const splitedEnd = splitedStart[1].split(endPlaceHolder);
  if (splitedEnd.length !== 2) {
    return document;
  }
  if (url) {
    return splitedStart[0] + getOrgLogoStyle(url) + splitedEnd[1];
  }
  return splitedStart[0] + splitedEnd[1];
};

export const organizationDisabledProfiles = [1, 126];
//get Action of logs agianst their number

// format users data exported from CSV
// fields should be in order with xlsx template
const expectedFieldsMap = {
  'FIRST NAME': 'first_name',
  'LAST NAME': 'last_name',
  'USER TYPE': 'user_type',
  'EMAIL ADDRESS': 'email',
  'MOBILE PHONE NUMBER': 'mobile_phone',
  'HOME PHONE NUMBER': 'home_phone',
  'UNIT NUMBER': 'unit_num',
  'UNIT STATUS': 'unit_status',
  'MONTHLY RENT': 'rent',
  'MOVE IN DATE': 'move_in',
  'MOVE OUT DATE': 'move_out'
};

export const USER_TYPES = {
  resident: 1,
  'co-resident': 2,
  manager: 0,
  technician: 0,
  vendor: 0
};

export const ROLES = {
  user: 1,
  manager: 2,
  admin: 3,
  vendor: 5
};

const ROLE_TYPE_MAP = {
  resident: ROLES.user,
  'co-resident': ROLES.user,
  manager: ROLES.manager,
  technician: ROLES.manager,
  vendor: ROLES.vendor
};

const getTypeAndRole = (key, userType) => ({
  [key]: USER_TYPES[userType],
  role: ROLE_TYPE_MAP[userType]
});

const areAllValuesEmpty = (obj) => {
  return Object.values(obj).every((value) => !Boolean(value));
};

const formatRawUserData = (rawData) => {
  let user = {};

  Object.entries(expectedFieldsMap).forEach(([key, value], index) => {
    if (index === 0) {
      const nameValue = Object.entries(rawData).find(([key, value]) => !key.startsWith('__EMPTY'));
      user[value] = Boolean(nameValue) ? nameValue[1]?.trim() : '';
    }
    if (index === 1) user[value] = Boolean(rawData['__EMPTY']) ? rawData['__EMPTY']?.trim() : '';
    if (index > 1) {
      const isFieldEmpty = !Boolean(rawData[`__EMPTY_${index - 1}`]);
      let fieldData = '';

      if (!isFieldEmpty) {
        fieldData = rawData[`__EMPTY_${index - 1}`];
      }

      if (!isFieldEmpty && typeof fieldData === 'string') {
        fieldData = fieldData?.trim()?.toLowerCase();
      }

      if (value === 'user_type' && !isFieldEmpty) {
        user = {
          ...user,
          ...getTypeAndRole(value, fieldData)
        };
        return;
      }

      if (
        (value === 'move_in' || value === 'move_out') &&
        !isFieldEmpty & (typeof fieldData == 'number')
      ) {
        // convert excel sheet date serial to actual date - needs more testing
        const baseDate = moment('1900-01-01');
        const resultDate = baseDate.add(fieldData - (fieldData >= 61 ? 2 : 1), 'days');
        const dateString = resultDate.format('MM/DD/YYYY');

        fieldData = dateString;
      }
      user[value] = fieldData;
    }
  });

  delete user['unit_status'];

  if (areAllValuesEmpty(user)) return undefined;

  return user;
};

const separateUsersTable = (data) => {
  const staffHeaderInedx = data.findIndex((row) => Object.values(row).includes('Building Staff'));
  const residentsHeaderInedx = data.findIndex((row) => Object.values(row).includes('Residents'));

  const staff = data
    .slice(staffHeaderInedx + 1, residentsHeaderInedx - 1)
    .map((user) => formatRawUserData(user))
    .filter((user) => Boolean(user));
  const residents = data
    .slice(residentsHeaderInedx + 1)
    .map((user) => formatRawUserData(user))
    .filter((user) => Boolean(user));

  return { staff, residents };
};

export const extractUsersFromSheet = (sheetData) => {
  return separateUsersTable(sheetData);
};

export const generateHeadersFromUser = (users) => {
  const user = users.find((user) => Object.keys(user).length > 0);
  return { header: Object.keys(user) };
};

export const phoneNumberMask = [
  '(',
  /[0-9]/,
  /\d/,
  /\d/,
  ')',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

// Convert "2022-04-16T19:58:26.894307Z" to "2022-04-16 19:58:26"
export const getTimeString = (time) => {
  if (!time) return '- -';
  try {
    return time.split('T').join(' ').split('.')[0];
  } catch (error) {
    return '- -';
  }
};

export const BODY_CLASS = 'class="bodySection"';
export const ENDING_TAG = '<footer';

const prodTemplateErrorMessage = 'Error: Please contact admin to fix the template';

export const parseTemplateData = (template) => {
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  try {
    const bodyClassIdx = template.indexOf(BODY_CLASS);
    if (bodyClassIdx === -1) {
      return [false, isDev ? 'Body class not found' : prodTemplateErrorMessage];
    }
    const openingTagIndex = template.indexOf('>', bodyClassIdx);
    const bodyContentWithFooter = template.substring(openingTagIndex + 1);
    const footerTagIdx = bodyContentWithFooter.indexOf(ENDING_TAG);
    if (footerTagIdx === -1) {
      return [false, isDev ? 'Footer tag not found' : prodTemplateErrorMessage];
    }
    const bodyContent = bodyContentWithFooter.substring(0, footerTagIdx);
    return [true, bodyContent];
  } catch {
    return [false, isDev ? 'Error parsing template' : prodTemplateErrorMessage];
  }
};

export const editUser = (userId) => {
  store.dispatch(
    getUser({
      id: userId,
      success: (userRes) => {
        const user = userRes.data;
        const org =
          store.getState().Organization.AllOrganizations.length > 0
            ? store.getState().Organization.AllOrganizations.find((org) => org.id === user.org_id)
            : store.getState().Organization.organization;
        store.dispatch(
          getBuilding({
            id: user.building_id,
            success: (buildingRes) => {
              const building = buildingRes.data;
              store.dispatch(
                updateUserTypeData({
                  editingID: user.id,
                  type: user.type_id,
                  role: user.role_id,
                  firstName: user.first_name,
                  lastName: user.last_name,
                  email: user.email,
                  homePhoneNumber: user.home_phone,
                  mobilePhoneNumber: user.cell_phone,
                  organization: {
                    organizationId: org.id,
                    name: org.name
                  },
                  buildings: {
                    buildingId: user.building_id,
                    name: user.building,
                    type: building.type
                  },
                  unitNumber: {
                    id: user.unit_id ? user.unit_id : undefined,
                    name: user.unit_num
                  },
                  moveInDate: user.move_in,
                  moveOutDate: user.move_out,
                  monthlyRent: user.rent,
                  sendWelcomeEmail: false,
                  avatar: {
                    blobURL: user.avatar,
                    blobData: undefined,
                    blobName: undefined
                  }
                })
              );
              openModal('AddNewUserTypeModalStep1');
            },
            fail: () => {
              store.dispatch(
                setToast({
                  toastShow: true,
                  toastMessage: 'Failed to get user building!',
                  modal: 'errorToast'
                })
              );
            }
          })
        );
      },
      fail: () => {
        store.dispatch(
          setToast({
            toastShow: true,
            toastMessage: 'Failed to get user!',
            modal: 'errorToast'
          })
        );
      }
    })
  );
};

export const getBuildings = async (orgId, page = 0) => {
  const url = `${baseURL}/orgs/${orgId}/buildings?page=${page}`;

  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('idToken')}`,
      'org-location': window.localStorage.data_center
    }
  });
  return await response.json();
};

//const NOTIFICATIONS_OFFERS_ID = 4;
// const NOTIFICATIONS_BOOKINGS_ID = 8;
// const NOTIFICATIONS_REQUESTS_ID = 10;
export const handleLinkNotification = (notif) => {
  switch (notif?.type_id) {
    case NOTIFICATIONS_BOOKINGS_ID:
      store.dispatch(getParAMBooking({ id: notif?.related_id }));
      openModal('bookingDetails');
      break;
    case NOTIFICATIONS_REQUESTS_ID:
      store.dispatch(getService({ id: notif?.related_id }));
      store.dispatch(
        getCommentId({
          commentId: notif?.related_id
        })
      );
      openModal('serviceDetail');
      break;
    case NOTIFICATIONS_OFFERS_ID:
      store.dispatch(
        getOffer({
          id: notif?.related_id
        })
      );
      openModal('offerDetails');

      break;

    default:
      return;
  }
};
