import styled from 'styled-components';
import { Fonts, Palette } from '../../components/utils';
import { Separator } from '../../components/ui/Separator';

export const UserNameText = styled.p`
  color: ${Palette.BLACK};
  font-family: ${Fonts.RED_HAT_FONT};
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3.5rem;
  text-transform: capitalize;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  margin: 0;
`;

export const DeliverySeparator = styled(Separator)`
  margin: 0.5rem 0rem;
`;

export const DeliveryCodeContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-shrink: 0;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
  background-color: ${({ isOpen }) => isOpen ? Palette.HAZY_BLUE : 'rgba(19, 49, 85, 0.2)'};

  svg {
    width: 1.5rem;
    height: 1.5rem;
    fill: ${({ isOpen }) => isOpen ? Palette.WHITE_SMOKE : Palette.HAZY_BLUE};
  }
`;

export const DeliveryCodeText = styled.p`
  color: ${({ isOpen }) => isOpen ? Palette.WHITE_SMOKE : Palette.HAZY_BLUE};
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0;
`;

export const DeliveryImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

export const DeliveryTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 0rem;
`;

export const DeliveryText = styled.p`
  color: ${({ title }) => (title ? Palette.DARK_ASH : Palette.BLACK)};
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0;
`;
