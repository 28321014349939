import React, { useState } from 'react';
import { CRow, CCol, CImg } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { changeOpenedModal } from '../../redux/actions/headerModal';
import { ReactComponent as LeaseTimeIcon } from '../../assets/icons/leaseTime.svg';
import { ReactComponent as CoResidentIcon } from '../../assets/icons/twoUsers.svg';
import { LOADING_BLUE } from '../../utils/colorCode';
import { defaultImgCDN, getFullDateWithOutTime, imageString } from '../../helper';
import { clearUser, getUser, inviteUser } from '../../redux/actions/user';
import { ROLE_MAP, USER_TYPE_MAP } from '../../utils/mappings';
import LoadingAvatar from '../../components/LoadingAvatar';
import { getBuilding } from '../../redux/actions/building';
import { setToast } from '../../redux/actions/window';
import { getUnit } from '../../redux/actions/unit';
import { conformToMask } from 'react-text-mask';
import { phoneNumberMask } from '../../helper/Regx';
import DetailsModalTitleButtons from '../../components/DetailsModalTitleButtons';
import { useTranslation } from 'react-i18next';
import { DATE_FORMATS, UserRole, UserType } from './constant';
import { ModalHeader, StyledModal, StyledModalBody } from '../../components/Modals';
import {
  SettingsItem,
  SettingsItemContent,
  SettingsItemSubTitle,
  SettingsItemTitle
} from '../settings/SettingsItem';
import { FaCalendar, FaUser } from 'react-icons/fa';
import { Palette } from '../../components/utils';
import { MdNumbers, MdOutlineEmail, MdOutlinePhoneIphone } from 'react-icons/md';
import { BsBuildingsFill, BsPostcard } from 'react-icons/bs';
import { HiOutlineUsers } from 'react-icons/hi2';
import { ImageContainer, StyledImage } from '../settings/SettingsImages';
import { getRoomUnitTerminology } from '../../utils/utils';
import moment from 'moment-timezone';
import {useGetConfigs} from '../../hooks/useGetConfigs';

const UserDetailModal = () => {
  const { t } = useTranslation();
  const userDetailLabels = {
    name: t('Name'),
    role_type: (hasType) => t(`Role ${hasType ? '/ Type' : ''}`),
    email_address: t('Email address'),
    phone_number: t('Phone number'),
    home_phone_number: t('Home phone number'),
    building: t('Building'),
    move_in: t('Move in'),
    move_out: t('Move out'),
    insurance: t('Insurance'),
    co_residents: t('Co-residents'),
    unit_no: t('Unit number')
  };

  const { modal } = useSelector((state) => state.headerModal);
  const { user, status } = useSelector((state) => state.user);
  const { config } = useGetConfigs();
 
  const dispatch = useDispatch();
  const [errorImage, setErrorImage] = useState('');

  const isIsSuite = (user) => {
    return user && user.suite_id > 0;
  };

  const onClose = () => {
    setErrorImage('');
    dispatch(clearUser());
    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );
  };
  const showUserDetail = (id) => {
    dispatch(
      getUser({
        id,
        success: () => {
          dispatch(
            changeOpenedModal({
              modal: 'UserDetail'
            })
          );
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Failed to get user!'),
              modal: 'errorToast'
            })
          );
        }
      })
    );
  };

  const showCoResDetails = (id) => {
    dispatch(
      getUser({
        id,
        success: (res) => {
          dispatch(
            changeOpenedModal({
              modal: 'UserDetail',
              data: isIsSuite(res.data)
            })
          );
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Failed to get user!'),
              modal: 'errorToast'
            })
          );
        }
      })
    );
  };

  const handleInvite = (user) => {
    dispatch(
      inviteUser({
        id: user,
        success: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Invitation has been resent successfully!')
            })
          );
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Invitation sending failed!'),
              modal: 'errorToast'
            })
          );
        }
      })
    );
  };

  const handleDetails = (id) => {
    dispatch(
      getUnit({
        id,
        success: () => {
          dispatch(
            changeOpenedModal({
              modal: 'UnitDetail',
              data: config?.suite_enabled
            })
          );
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('failed to get unit data!'),
              modal: 'errorToast'
            })
          );
        }
      })
    );
  };

  return modal === 'UserDetail' || modal === 'UserDetailShort' ? (
    <StyledModal
      show={modal === 'UserDetail' || modal === 'UserDetailShort'}
      onClose={onClose}
      closeOnBackdrop={true}>
      <ModalHeader title="User details" />
      {status === 'GET_USER_PENDING' ? (
        <LoadingAvatar color={LOADING_BLUE} />
      ) : (
        <>
          <StyledModalBody>
            <div className="flex justify-content-center w-100">
              <ImageContainer>
                <StyledImage
                  onError={() => setErrorImage(defaultImgCDN)}
                  src={errorImage === '' ? imageString(user.avatar) : errorImage}
                />
              </ImageContainer>
            </div>

            <SettingsItem>
              <FaUser size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>{t('Name')}</SettingsItemTitle>
                <SettingsItemSubTitle>
                  {user.first_name} {user.last_name}
                </SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>

            <SettingsItem>
              <BsPostcard size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>
                  {userDetailLabels.role_type(user.role_id === UserRole.USER)}
                </SettingsItemTitle>
                <SettingsItemSubTitle>
                  {`${ROLE_MAP.find((map) => map.id === user.role_id)?.value} ${
                    user.role_id === UserRole.USER
                      ? `/ ${USER_TYPE_MAP.find((map) => map.id === user.type_id)?.value}`
                      : ''
                  }`}
                </SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>

            <SettingsItem>
              <MdOutlineEmail size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>{t('Email address')}</SettingsItemTitle>
                <SettingsItemSubTitle>{user.email}</SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>

            <SettingsItem>
              <MdOutlinePhoneIphone size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>{t('Phone number')}</SettingsItemTitle>
                <SettingsItemSubTitle>
                  {user.cell_phone === ''
                    ? '-'
                    : (conformToMask(user.cell_phone, phoneNumberMask) || {}).conformedValue}
                </SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>

            <SettingsItem>
              <MdOutlinePhoneIphone size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>{t('Home phone number')}</SettingsItemTitle>
                <SettingsItemSubTitle>
                  {user.home_phone === ''
                    ? '-'
                    : (conformToMask(user.home_phone, phoneNumberMask) || {}).conformedValue}
                </SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>

            <SettingsItem>
              <BsBuildingsFill size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>{t('Building')}</SettingsItemTitle>
                <SettingsItemSubTitle
                  link
                  onClick={() => {
                    dispatch(
                      getBuilding({
                        id: user.building_id,

                        success: () => {
                          dispatch(changeOpenedModal({ modal: 'BuildingDetail' }));
                        },
                        fail: () => {
                          dispatch(
                            setToast({
                              toastShow: true,
                              toastMessage: t('Failed to get building data!'),
                              modal: 'errorToast'
                            })
                          );
                        }
                      })
                    );
                  }}>
                  {user.building}
                </SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>

            <SettingsItem>
              <FaCalendar size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>{t('Move in')}</SettingsItemTitle>
                <SettingsItemSubTitle>
                  {user.move_in === ''
                    ? 'None'
                    : moment(user.move_in).format(DATE_FORMATS.MM_DD_YYYY)}
                </SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>

            <SettingsItem>
              <FaCalendar size={24} color={Palette.HAZY_BLUE} />
              <SettingsItemContent>
                <SettingsItemTitle>{t('Move out')}</SettingsItemTitle>
                <SettingsItemSubTitle>
                  {user.move_out === ''
                    ? 'None'
                    : moment(user.move_out).format(DATE_FORMATS.MM_DD_YYYY)}
                </SettingsItemSubTitle>
              </SettingsItemContent>
            </SettingsItem>

            {user?.reservations && user.reservations.length > 0 ? (
              <>
                <SettingsItem>
                  <HiOutlineUsers size={24} color={Palette.HAZY_BLUE} />
                  <SettingsItemContent>
                    <SettingsItemTitle>Reservations</SettingsItemTitle>
                    {user.reservations.map((reservation) => (
                      <>
                        <SettingsItemSubTitle
                          key={reservation.id}
                          onClick={() => showUserDetail(reservation.user_id)}>
                          {moment.utc(reservation.check_in_date).format(DATE_FORMATS.MM_DD_YYYY)}-{' '}
                          {moment.utc(reservation.check_out_date).format(DATE_FORMATS.MM_DD_YYYY)}
                        </SettingsItemSubTitle>
                        <SettingsItemSubTitle
                          link
                          cssOverride={'font-size: 16px;'}
                          onClick={() => showUserDetail(reservation.user_id)}>
                          {reservation.user_name}
                        </SettingsItemSubTitle>
                        {reservation.residents.map((resident) => (
                          <SettingsItemSubTitle link onClick={() => showUserDetail(resident.id)}>
                            {resident.name}
                          </SettingsItemSubTitle>
                        ))}
                      </>
                    ))}
                  </SettingsItemContent>
                </SettingsItem>
              </>
            ) : user.type_id === UserType.RESIDENT && user.residents ? (
              <>
                <SettingsItem>
                  <HiOutlineUsers size={24} color={Palette.HAZY_BLUE} />
                  <SettingsItemContent>
                    <SettingsItemTitle>{userDetailLabels.co_residents}</SettingsItemTitle>
                    {user.residents
                      .filter((o) => parseInt(o.type) !== 1)
                      .map((coUsers, index, array) => (
                        <SettingsItemSubTitle
                          key={coUsers.id}
                          link
                          onClick={() => showUserDetail(coUsers.id)}>
                          {coUsers.name}
                          {index < array.length - 1 ? ', ' : ''}
                        </SettingsItemSubTitle>
                      ))}
                  </SettingsItemContent>
                </SettingsItem>
              </>
            ) : null}

            {user.unit_id > 0 && (
              <SettingsItem>
                <MdNumbers size={24} color={Palette.HAZY_BLUE} />

                <SettingsItemContent>
                  <SettingsItemTitle>{getRoomUnitTerminology(config)}</SettingsItemTitle>
                  <SettingsItemSubTitle
                    link={user.unit_id > 0}
                    onClick={() => user.unit_id > 0 && handleDetails(user.unit_id)}>
                    {user.unit_id > 0 ? user.unit_num : 'None'}
                  </SettingsItemSubTitle>
                </SettingsItemContent>
              </SettingsItem>
            )}
          </StyledModalBody>
          {config?.org_allow_edits && (
            <DetailsModalTitleButtons
              handleInvite={modal === 'UserDetailShort' ? null : handleInvite}
              modalName="users"
              user={user}
            />
          )}
        </>
      )}
    </StyledModal>
  ) : null;
};

export default UserDetailModal;
