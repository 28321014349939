import React, { useEffect, useMemo, useState } from 'react';
import Table from '../../../../components/Table';
import { useDispatch, useSelector } from 'react-redux';
import { getBuildingsDevices } from '../../../../redux/actions/building';
import { GET_BUILDING_DEVICES } from '../../../../redux/constants';
import { requestPending } from '../../../../utils/status';
import { CPagination } from '@coreui/react';
import {
  TableColumn,
  TableColumnOptions,
  TableColumnTitle
} from '../../../../components/tables/TableComponents';
import { useParams } from 'react-router-dom';
import { HiMiniChevronDown, HiMiniChevronUp } from 'react-icons/hi2';
import { Palette } from '../../../../components/utils';
import { DATE_FORMATS } from '../../../users/constant';
import moment from 'moment';
import { setCsvFields } from '../../../../redux/actions/reports';
import { getRoomUnitTerminology } from '../../../../utils/utils';
import {useGetConfigs} from '../../../../hooks/useGetConfigs';

const PER_PAGE = 20;
const COLUMN_SORT = {
  unit: 'unit',
  suite_name: 'suite_name',
  user_name: 'user_name',
  battery_level: 'battery_level',
  event_status: 'event_id',
  device_status_date: 'device_status_date'
};

const BatteryStatusTable = ({ setPreparedBatteryTableInfo }) => {
  const { id: buildingId } = useParams();
  const dispatch = useDispatch();
  const organization = useSelector((state) => state.Organization.organization);
  const buildingDevicesStatus = useSelector((state) => state.building.building_devices_status);
  const { config } = useGetConfigs();

  const [currentPage, setCurrentPage] = useState(1);
  const [state, setState] = useState();
  const [sort, setSort] = useState({ sortBy: 'unit', sortOrder: 'DESC' });

  const getFields = () => {
    const tableFields = [
      { key: 'unit', label: getRoomUnitTerminology(config) },
      // { key: 'suite_name', label: 'Suite' },
      { key: 'user_name', label: 'Resident' },
      { key: 'battery_level', label: 'Battery Level' },
      { key: 'event_status', label: 'Activity' },
      { key: 'device_status_date', label: 'Date of Activity' }
    ];

    if (config?.suite_enabled) {
      tableFields.splice(1, 0, { key: 'suite_name', label: 'Unit' });
    }

    return tableFields;
  };

  const memoFields = useMemo(() => {
    return [
      ...getFields().map((column) => {
        return {
          key: column.key,
          label: (
            <TableColumn>
              <TableColumnTitle>{column.label}</TableColumnTitle>
              <TableColumnOptions>
                <HiMiniChevronUp
                  style={{ cursor: 'pointer', width: '16px', height: '16px' }}
                  color={
                    sort.sortBy === column.key && sort.sortOrder === 'ASC'
                      ? Palette.RED
                      : Palette.BLACK_50
                  }
                  onClick={() => {
                    if (sort.sortOrder === 'ASC') {
                      setSort(() => ({
                        sortBy: undefined,
                        sortOrder: undefined
                      }));
                    } else {
                      setSort(() => ({
                        sortBy: column.key,
                        sortOrder: 'ASC'
                      }));
                    }
                  }}
                />
                <HiMiniChevronDown
                  style={{ cursor: 'pointer', width: '16px', height: '16px' }}
                  color={
                    sort.sortBy === column.key && sort.sortOrder === 'DESC'
                      ? Palette.RED
                      : Palette.BLACK_50
                  }
                  onClick={() => {
                    if (sort.sortOrder === 'DESC') {
                      setSort(() => ({
                        sortBy: undefined,
                        sortOrder: undefined
                      }));
                    } else {
                      setSort(() => ({
                        sortBy: column.key,
                        sortOrder: 'DESC'
                      }));
                    }
                  }}
                />
              </TableColumnOptions>
            </TableColumn>
          )
        };
      })
    ];
  }, [dispatch, sort]);

  useEffect(() => {
    dispatch(
      getBuildingsDevices({
        id: organization.id,
        buildingId: buildingId,
        page: currentPage,
        perPage: PER_PAGE,
        batteryTable: true,
        sortBy: COLUMN_SORT[sort.sortBy],
        sortOrder: sort.sortOrder,
        success: (res) => {
          const deviceData = res.data.devices;
          setState({
            devices: deviceData,
            total: res.data.total_count
          });
          setPreparedBatteryTableInfo(
            deviceData.map((device) => {
              return {
                unit: device.unit,
                suite_name: device.suite_name,
                user_name: device.user_name,
                battery_level: device.battery_level !== -1 ? `${device.battery_level}%` : 'N/A',
                event_status: device.event_status,
                device_status_date: moment(device.device_status_date).format(
                  DATE_FORMATS.YYYY_MM_DD_HH_mm_ss
                )
              };
            })
          );
        }
      })
    );

    dispatch(setCsvFields(getFields()));
  }, [currentPage, sort, organization]);

  return (
    <div>
      <Table
        useNewStyle
        items={state?.devices}
        itemsPerPage={50}
        loading={buildingDevicesStatus === requestPending(GET_BUILDING_DEVICES)}
        fields={memoFields}
        clickableRows={true}
        scopedSlots={{
          unit: (item) => {
            return <td>{item.unit ? item.unit : '-'}</td>;
          },
          suite_name: (item) => {
            return <td>{item.suite_name ? item.suite_name : '-'}</td>;
          },
          battery_level: (item) => {
            return <td>{item.battery_level !== -1 ? `${item.battery_level}%` : 'N/A'}</td>;
          },
          event_status: (item) => {
            return <td>{item.event_status}</td>;
          },
          device_status_date: (item) => {
            return (
              <td>
                {moment(item.device_status_date).local().format(DATE_FORMATS.MM_DD_YYYY_h_mm_A)}
              </td>
            );
          }
        }}
      />
      <div className={'mt-2'}>
        <CPagination
          activePage={currentPage}
          pages={Math.ceil(state?.total / PER_PAGE)}
          onActivePageChange={(newPage) => setCurrentPage(newPage)}
        />
      </div>
    </div>
  );
};

export default BatteryStatusTable;
