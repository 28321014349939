import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  SettingsItem,
  SettingsItemContent,
  SettingsItemSubTitle,
  SettingsItemTitle
} from '../views/settings/SettingsItem';
import { FaBuilding, FaUser } from 'react-icons/fa';
import { Palette } from './utils';
import { HiOutlineSquare3Stack3D } from 'react-icons/hi2';
import { FaHouse } from 'react-icons/fa6';
import styled from 'styled-components';
import { getRoomUnitTerminology } from '../utils/utils';
import {useGetConfigs} from '../hooks/useGetConfigs';

const TenantDetail = ({ building, floor, unit, name }) => {
  const { t } = useTranslation();
  const {config} = useGetConfigs();

  return (
    <Container>
      <SettingsItem>
        <FaBuilding size={18} color={Palette.HAZY_BLUE} className="flex-shrink-0" />
        <SettingsItemContent>
          <SettingsItemTitle>{t('Building')}</SettingsItemTitle>
          <SettingsItemSubTitle>{building}</SettingsItemSubTitle>
        </SettingsItemContent>
      </SettingsItem>

      <SettingsItem>
        <HiOutlineSquare3Stack3D size={18} color={Palette.HAZY_BLUE} className="flex-shrink-0" />
        <SettingsItemContent>
          <SettingsItemTitle>{t('Floor')}</SettingsItemTitle>
          <SettingsItemSubTitle>{floor}</SettingsItemSubTitle>
        </SettingsItemContent>
      </SettingsItem>

      <SettingsItem>
        <FaHouse size={18} color={Palette.HAZY_BLUE} className="flex-shrink-0" />
        <SettingsItemContent>
          <SettingsItemTitle>{getRoomUnitTerminology(config)}</SettingsItemTitle>
          <SettingsItemSubTitle>{unit}</SettingsItemSubTitle>
        </SettingsItemContent>
      </SettingsItem>

      <SettingsItem>
        <FaUser size={18} color={Palette.HAZY_BLUE} className="flex-shrink-0" />
        <SettingsItemContent>
          <SettingsItemTitle>{t('Resident')}</SettingsItemTitle>
          <SettingsItemSubTitle>{name}</SettingsItemSubTitle>
        </SettingsItemContent>
      </SettingsItem>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default TenantDetail;
