import { createReducer } from '@reduxjs/toolkit';
import { CREATE_LICENSE_PLATE, DELETE_LICENSE_PLATE, UPDATE_LICENSE_PLATE } from '../constants';
import { requestFail, requestPending, requestSuccess } from '../../utils/status';

const initialState = {
  createLicensePlateStatus: '',
  createLicensePlateData: undefined,

  updateLicensePlateStatus: '',
  updateLicensePlateData: undefined,

  deleteLicensePlateStatus: '',

  search: '',
  filters: {},
  ordering: {},
  pagination: {
    page: 1,
    limit: 20
  }
};

export default createReducer(initialState, {
  // Create

  [requestSuccess(CREATE_LICENSE_PLATE)]: (state, { payload }) => ({
    ...state,
    createLicensePlateStatus: requestSuccess(CREATE_LICENSE_PLATE),
    createLicensePlateData: payload
  }),

  [requestPending(CREATE_LICENSE_PLATE)]: (state) => ({
    ...state,
    createLicensePlateStatus: requestPending(CREATE_LICENSE_PLATE)
  }),

  [requestFail(CREATE_LICENSE_PLATE)]: (state) => ({
    ...state,
    createLicensePlateStatus: requestFail(CREATE_LICENSE_PLATE),
    createLicensePlateData: null
  }),

  // Update

  [requestSuccess(UPDATE_LICENSE_PLATE)]: (state, { payload }) => ({
    ...state,
    updateLicensePlateStatus: requestSuccess(UPDATE_LICENSE_PLATE),
    updateLicensePlateData: payload
  }),

  [requestPending(UPDATE_LICENSE_PLATE)]: (state) => ({
    ...state,
    updateLicensePlateStatus: requestPending(UPDATE_LICENSE_PLATE)
  }),

  [requestFail(UPDATE_LICENSE_PLATE)]: (state) => ({
    ...state,
    updateLicensePlateStatus: requestFail(UPDATE_LICENSE_PLATE),
    updateLicensePlateData: null
  }),

  // Delete

  [requestSuccess(DELETE_LICENSE_PLATE)]: (state) => ({
    ...state,
    deleteLicensePlateStatus: requestSuccess(DELETE_LICENSE_PLATE)
  }),

  [requestPending(DELETE_LICENSE_PLATE)]: (state) => ({
    ...state,
    deleteLicensePlateStatus: requestPending(DELETE_LICENSE_PLATE)
  }),

  [requestFail(DELETE_LICENSE_PLATE)]: (state) => ({
    ...state,
    deleteLicensePlateStatus: requestFail(DELETE_LICENSE_PLATE)
  })
});
