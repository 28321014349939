// https://ui.shadcn.com/docs/components/form

import * as React from 'react';
import { Controller, FormProvider, useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { Fonts, Palette } from '../utils';
import { MdError } from 'react-icons/md';

const Form = FormProvider;

const FormFieldContext = React.createContext({});

const FormField = ({ ...props }) => {
  return (
    <FormFieldContext.Provider value={{ name: props.name }}>
      <Controller {...props} />
    </FormFieldContext.Provider>
  );
};

const useFormField = () => {
  const fieldContext = React.useContext(FormFieldContext);
  const itemContext = React.useContext(FormItemContext);
  const { getFieldState, formState } = useFormContext();

  const fieldState = getFieldState(fieldContext.name, formState);

  if (!fieldContext) {
    throw new Error('useFormField should be used within <FormField>');
  }

  const { id } = itemContext;

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState
  };
};

const FormItemContext = React.createContext({});

// Form Item

const StyledFormItem = styled.div`
  .space-y-2 > :not([hidden]) ~ :not([hidden]) {
    margin-top: 0.5rem;
  }
  
  ${({ alt }) =>
    alt &&
    css`
      display: flex;
      flex-direction: column;
      gap: 8px;
    `}
`;

const FormItem = React.forwardRef(({ className, ...props }, ref) => {
  // const id = React.useId();

  return (
    <FormItemContext.Provider value={{ id: 'id' }}>
      <StyledFormItem ref={ref} className="w-100" {...props} />
    </FormItemContext.Provider>
  );
});
FormItem.displayName = 'FormItem';

// Form Label

const StyledFormLabel = styled.label`
  color: ${Palette.BLACK};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: ${({ fontSize }) => fontSize || '16px'};
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0;

  ${({ alt }) =>
    alt &&
    css`
      color: ${Palette.BLACK_50};
      font-size: 10px;
      line-height: 16px;
      text-transform: uppercase;
    `}

  ${({ error }) => error && `color: ${Palette.ERROR};`}

  ${({ cssOverride }) => cssOverride};
`;

const FormLabel = React.forwardRef(({ ...props }, ref) => {
  const { error, formItemId } = useFormField();

  return <StyledFormLabel ref={ref} error={error} htmlFor={formItemId} {...props} />;
});
FormLabel.displayName = 'FormLabel';

const StyledFormControl = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: ${({ rowGap }) => rowGap || '12px'};
`;

const FormControl = React.forwardRef(({ ...props }, ref) => {
  const { error, formItemId, formDescriptionId, formMessageId } = useFormField();

  return (
    <StyledFormControl
      ref={ref}
      id={formItemId}
      aria-describedby={!error ? `${formDescriptionId}` : `${formDescriptionId} ${formMessageId}`}
      aria-invalid={!!error}
      {...props}
    />
  );
});
FormControl.displayName = 'FormControl';

const StyledErrorText = styled.p`
  color: ${Palette.ERROR};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0;
  margin-top: 0;
`;

const FormMessage = React.forwardRef(({ className, children, ...props }, ref) => {
  const { error, formMessageId } = useFormField();
  const body = error ? String(error?.message) : children;

  if (!body) {
    return null;
  }

  return (
    <StyledErrorText ref={ref} id={formMessageId} {...props}>
      <MdError size={24} className="mr-2" /> {body}
    </StyledErrorText>
  );
});
FormMessage.displayName = 'FormMessage';

export { useFormField, Form, FormItem, FormLabel, FormControl, FormMessage, FormField };
