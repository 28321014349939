import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { closeActiveModal } from '../../helper/modals';
import { ModalHeader, StyledModal, StyledModalBody } from '../../components/Modals';
import { Flex } from '../../components/ui/Flex';
import styled from 'styled-components';
import { Fonts, Palette } from '../../components/utils';
import moment from 'moment-timezone';
import { DATE_FORMATS } from '../users/constant';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { Tooltip } from 'antd';
import { getRoomUnitTerminology } from '../../utils/utils';
import {useGetConfigs} from '../../hooks/useGetConfigs';

const LicensePlateScanDetailsModal = () => {
  const { t } = useTranslation();
  const { modal, data } = useSelector((state) => state.headerModal);
  const { config } = useGetConfigs();

  const formatPlateConfidence = (confidence) => `${(confidence * 100).toFixed(2)}%`;

  return (
    <StyledModal
      show={modal === 'LicensePlateScanDetailsModal'}
      closeOnBackdrop
      onClose={closeActiveModal}>
      <ModalHeader title={t('License plate scan details')} />

      <StyledModalBody>
        <Flex direction="column" gap="16px">
          <Flex direction="column" gap="16px">
            <TitleText>{t('License plate')}</TitleText>
            <Text>{data.license_plate}</Text>
          </Flex>

          <Flex direction="column" gap="16px">
            <TitleText>{t('Building name')}</TitleText>
            <Text>{data.building_name}</Text>
          </Flex>

          {data.unit_id && (
            <Flex direction="column" gap="16px">
              <TitleText>{getRoomUnitTerminology(config)}</TitleText>
              <Text>{data.unit_num}</Text>
            </Flex>
          )}

          <Flex direction="column" gap="16px">
            <TitleText>{t('Captured information')}</TitleText>

            <Flex direction="column" gap="8px">
              <Text>
                {t('Captured on')}:{' '}
                {moment.utc(data.create_date).local().format(DATE_FORMATS.YYYY_MM_DD_hh_mm_ss_A)}
              </Text>
              {data.data?.plateConfidence && (
                <Flex direction="row" gap="4px">
                  <Text>{t('Plate confidence score')}: </Text>
                  <Text plateConfidence={data.data.plateConfidence}>
                    {formatPlateConfidence(data.data.plateConfidence)}
                  </Text>
                  <Tooltip title={t('License plate scan confidence score description')}>
                    <span>
                      <AiFillQuestionCircle color="#3f95e0" />
                    </span>
                  </Tooltip>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      </StyledModalBody>
    </StyledModal>
  );
};

// todo move to common file
export const TitleText = styled.p`
  color: ${Palette.BLACK};
  font-family: ${Fonts.RED_HAT_FONT};
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.5rem;
  text-transform: capitalize;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  margin: 0;
`;

export const Text = styled.p`
  color: ${Palette.BLACK};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${({ plateConfidence }) => {
    plateConfidence = plateConfidence * 100;
    if (plateConfidence > 75) return Palette.TEXT_TAG_GREEN;
    if (plateConfidence > 50) return Palette.RUSTY_ORANGE;
    if (plateConfidence >= 0) return Palette.ERROR;
    return Palette.BLACK;
  }};

  margin: 0;
`;

export default LicensePlateScanDetailsModal;
