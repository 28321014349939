import i18next from 'i18next';
import { LOCK_PROVIDERS, ROLE_MAP } from './mappings';
import moment from 'moment';
import { DATE_FORMATS } from '../views/users/constant';

export function getRoleNameByID(roleID) {
  const parsedRoleID = parseInt(roleID);
  return ROLE_MAP.find((type) => type.id === parsedRoleID)?.value || undefined;
}

export function getLockProviderNameByID(lockProviderId) {
  const parsedLockProviderID = parseInt(lockProviderId);
  return LOCK_PROVIDERS.find((type) => type.id === parsedLockProviderID)?.value || undefined;
}

export function getRoomUnitTerminology(config) {
  return config?.suite_enabled ? i18next.t('Room number') : i18next.t('Unit number');
}

// TODO: This function is temporary, in the future all options will be placed inside of the filters object
export function getAllSearchOptions(searchState) {
  return {
    search: searchState.search,
    minCreationDate: searchState.minCreationDate,
    maxCreationDate: searchState.maxCreationDate,
    category: searchState.category,
    building_id: searchState.building_id,

    filters: searchState.filters,
    ordering: searchState.ordering,
    pagination: searchState.pagination
  };
}

export function getFilterCount(searchState) {
  function countValidItems(value) {
    if (value === null || value === undefined) {
      return 0;
    }
    if (Array.isArray(value)) {
      return value.reduce((sum, item) => sum + countValidItems(item), 0);
    }
    if (typeof value === 'object') {
      return Object.values(value).reduce((sum, item) => sum + countValidItems(item), 0);
    }
    return 1;
  }

  return Object.values(searchState).reduce((sum, value) => sum + countValidItems(value), 0);
}

export function formatMbFileSize(size) {
  return Number((size / (1024 * 1024)).toFixed(2));
}

export const getUTCTimestampForLocalDay = (localDate) => {
  if (!localDate) return undefined;

  const date = moment(localDate).startOf('day');
  return date.utc().format(DATE_FORMATS.YYYY_MM_DD_hh_mm_ss_A);
};

export const parseUnitNumber = (unitNum) => {
  if (!unitNum) return { number: 0, suffix: '' };

  // Since we don't have a standard way of formatting units/suites, this regex captures the most commonly used formats such as:
  // A103, A-103, 103A, 103-A, B-42-C, B42C
  const match = unitNum.match(/^([A-Z])?-?(\d+)-?([A-Z])?$/i);
  if (!match) return { number: 0, suffix: '' };

  return {
    prefix: (match[1] || '').toUpperCase(),
    number: parseInt(match[2]),
    suffix: (match[3] || '').toUpperCase()
  };
};
