import React from 'react';
import { BreadcrumbItem, BreadcrumbLink, BreadcrumbSeparator } from '../ui/Breadcrumb';
import { useSelector } from 'react-redux';
import DefaultBreadcrumb from './DefaultBreadcrumb';

const BuildingsBreadcrumb = ({ route, href }) => {
  const building = useSelector((state) => state.building.building);

  return (
    <>
      {building?.name && (
          <BreadcrumbItem>
            <BreadcrumbLink href={href}>{building.name}</BreadcrumbLink>
          </BreadcrumbItem>
      )}
    </>
  );
};

export default BuildingsBreadcrumb;
