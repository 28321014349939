import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Flex } from "../../../components/ui/Flex";
import ImageUploadWithPreviewV2 from '../../../components/ImageUploadWithPreviewV2';
import { ACCEPTED_IMG_EXTENSIONS, MAX_IMG_FILE_SIZE } from '../constants';
import { formatMbFileSize } from '../../../utils/utils';
import { Palette } from '../../../components/utils';
import { MediaKeys } from '../../offers/utils';
import { FormField } from '../../../components/ui/Form';

const UploadDeliveryImage = ({ form }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <FormField
      control={form.control}
      name="delivery_image"
      render={(controllerProps) => (
        <ImageUploadWithPreviewV2 
          form={form} 
          fieldName='delivery_image' 
          acceptedImageFormats={ACCEPTED_IMG_EXTENSIONS}
          mediaKey={MediaKeys.MEDIA}
          controllerProps={controllerProps} 
        >
          <TextContent>
            <MainText>{t('Drag and drop an image of the delivery')}</MainText>
            <Flex direction="column" gapX="0.2rem">
              <SecondaryText>{t('max file size', {maxFileSize: formatMbFileSize(MAX_IMG_FILE_SIZE)})}.</SecondaryText>
              <SecondaryText>{t('JPG and PNG supported')}.</SecondaryText>
            </Flex>
          </TextContent>
        </ImageUploadWithPreviewV2>
      )}
    />
  );
};

export default UploadDeliveryImage;

const TextContent = styled.div`
  text-align: center;
  font-weight: 600;
`;

const MainText = styled.p`
  margin-bottom: 0.8125rem;
  font-size: 1rem;
  color: ${Palette.BLACK};
`;

const SecondaryText = styled.p`
  color: ${Palette.BLACK_50};
  margin-bottom: 0;
`;
