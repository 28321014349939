import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import Page404 from '../views/pages/page404/Page404';

const PrivateRoute = (props) => {
  const { component: Component, viewable: viewable, ...rest } = props;

  return (
    <Route
      {...rest}
      render={() => {
        return viewable == false ? <Page404 /> : <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
