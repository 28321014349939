import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  DeliveryCodeContainer,
  DeliveryCodeText,
  DeliveryImage,
  DeliverySeparator,
  DeliveryText,
  DeliveryTextContainer,
  UserNameText
} from './DeliveryComponents';
import { Flex } from '../../components/ui/Flex';
import { StyledModal, StyledModalBody } from '../../components/Modals';
import moment from 'moment';
import { DATE_FORMATS } from '../users/constant';
import { ModalCloseButton } from '../settings/SettingsButton';
import { ButtonBodyVariant } from '../../components/ui/ButtonVariant';
import { CLOSED, OPEN } from '../../utils/mappings';
import { searchTable, setSearchState } from '../../redux/actions/search';
import { SEARCH_TABLES } from '../../helper/constants';
import { getAllSearchOptions } from '../../utils/utils';
import { setUserSearch } from '../../redux/actions/user';
import { updateDelivery } from '../../redux/actions/deliveries';
import { setToast } from '../../redux/actions/window';
import { changeOpenedModal } from '../../redux/actions/headerModal';
import { PiPackageFill } from 'react-icons/pi';

const ViewDeliveryModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.headerModal.modal);
  const deliveryData = useSelector((state) => state.headerModal.data);
  const globalOrgId = useSelector((state) => state.Organization.globalOrgId);
  const organization = useSelector((state) => state.Organization.organization);
  const searchState = useSelector((state) => state.search);

  const deliveryIsOpen = deliveryData.status_id === OPEN.id;

  const openEditModal = () => {
    dispatch(
      changeOpenedModal({
        modal: 'addEditDelivery',
        data: deliveryData
      })
    );
  };

  const searchOptions = getAllSearchOptions(searchState);

  // Get deliveries
  const search = (overrideValues = {}) => {
    const searchBody = { ...searchOptions, ...overrideValues };
    dispatch(setSearchState(searchBody));
    dispatch(
      searchTable({
        entity: SEARCH_TABLES.DELIVERIES,
        orgID: globalOrgId || organization.id,
        body: searchBody,
        success: (res) => {
          dispatch(
            setSearchState({
              currentData: res.data.data ?? [],
              currentPaginationData: res.data.pagination
            })
          );

          if (!res.data.data) {
            dispatch(setUserSearch({ page: 1 }));
          }
        }
      })
    );
  };

  const reopenDelivery = () => {
    const updatedDelivery = {
      status: OPEN.id
    };

    dispatch(updateDelivery({
      orgId: globalOrgId || organization.id,
      deliveryId: deliveryData.id,
      body: updatedDelivery,
      success: () => {
        search();
        dispatch(
          setToast({
            toastShow: true,
            toastMessage: t('Delivery status updated successfully!')
          })
        );
        dispatch(
          changeOpenedModal({
            modal: ''
          })
        );
      },
      fail: () => {
        dispatch(
          setToast({
            toastShow: true,
            toastMessage: t('Delivery status update failed!'),
            modal: 'errorToast'
          })
        );
      }
    }));
  };

  return (
    <StyledModal show={modal === 'viewDeliveryModal'} width="646px;" closeOnBackdrop>
      <Flex alignItems="center" gap="1rem" justify="flex-end">
        {deliveryIsOpen ? (
          <ButtonBodyVariant onClick={openEditModal}>{t('Edit details')}</ButtonBodyVariant>
        ) : (
          <ButtonBodyVariant variant="secondary" onClick={reopenDelivery}>
            {t('Reopen delivery')}
          </ButtonBodyVariant>
        )}
        <ModalCloseButton />
      </Flex>
      <Flex justify="space-between" alignItems="center" gap="12px">
        <UserNameText>{deliveryData.user_name}</UserNameText>
        <DeliveryCodeContainer isOpen={deliveryIsOpen}>
          <PiPackageFill />
          <DeliveryCodeText isOpen={deliveryIsOpen}>{deliveryIsOpen ? `${t('Delivery Code')} ${deliveryData.code}` : 'Closed'}</DeliveryCodeText>
        </DeliveryCodeContainer> 
      </Flex>

      <StyledModalBody>
          <Flex direction="column" gap="1.5rem">
            <Flex direction="column">
              <DeliveryTextContainer>
                <DeliveryText title>{t('Unit')}</DeliveryText>
                <DeliveryText>{deliveryData.unit_num}</DeliveryText>
              </DeliveryTextContainer>
              <DeliverySeparator orientation="horizontal" />

              <DeliveryTextContainer>
                <DeliveryText title>{t('Carrier')}</DeliveryText>
                <DeliveryText>{deliveryData.carrier_name}</DeliveryText>
              </DeliveryTextContainer>
              <DeliveryTextContainer>
                <DeliveryText title>{t('Weight')}</DeliveryText>
                <DeliveryText>{deliveryData.weight}</DeliveryText>
              </DeliveryTextContainer>
              <DeliveryTextContainer>
                <DeliveryText title>{t('Dimensions')}</DeliveryText>
                <DeliveryText>{deliveryData.dimensions}</DeliveryText>
              </DeliveryTextContainer>

              {deliveryData.status_id === CLOSED.id && (
                <>
                  <DeliverySeparator orientation="horizontal" />

                  <DeliveryTextContainer>
                    <DeliveryText title>{t('Date')}</DeliveryText>
                    <DeliveryText>
                      {moment(deliveryData.created_at).format(DATE_FORMATS.MM_DD_YYYY)}
                    </DeliveryText>
                  </DeliveryTextContainer>
                  <DeliveryTextContainer>
                    <DeliveryText title>{t('Time of delivery')}</DeliveryText>
                    <DeliveryText>
                      {moment(deliveryData.created_at).format(DATE_FORMATS.hh_mm_a)}
                    </DeliveryText>
                  </DeliveryTextContainer>
                </>
              )}
            </Flex>

            {deliveryData.image && <DeliveryImage src={deliveryData.image} />}
          </Flex>
      </StyledModalBody>
    </StyledModal>
  );
};

export default ViewDeliveryModal;
