import React from "react";

const PlusIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <path d="M15.5 23.25C15.8825 23.25 16.2034 23.1204 16.4626 22.8612C16.7209 22.6029 16.85 22.2825 16.85 21.9V17.85H20.9338C21.3162 17.85 21.6313 17.7204 21.8788 17.4612C22.1263 17.2029 22.25 16.8825 22.25 16.5C22.25 16.1175 22.1204 15.7966 21.8612 15.5374C21.6029 15.2792 21.2825 15.15 20.9 15.15H16.85V11.0662C16.85 10.6838 16.7209 10.3688 16.4626 10.1213C16.2034 9.87375 15.8825 9.75 15.5 9.75C15.1175 9.75 14.7971 9.87915 14.5388 10.1375C14.2796 10.3967 14.15 10.7175 14.15 11.1V15.15H10.0662C9.68375 15.15 9.36875 15.2792 9.12125 15.5374C8.87375 15.7966 8.75 16.1175 8.75 16.5C8.75 16.8825 8.87915 17.2029 9.13745 17.4612C9.39665 17.7204 9.7175 17.85 10.1 17.85H14.15V21.9338C14.15 22.3162 14.2796 22.6313 14.5388 22.8788C14.7971 23.1263 15.1175 23.25 15.5 23.25ZM15.5 30C13.6325 30 11.8775 29.6454 10.235 28.9362C8.5925 28.2279 7.16375 27.2663 5.94875 26.0513C4.73375 24.8363 3.7721 23.4075 3.0638 21.765C2.3546 20.1225 2 18.3675 2 16.5C2 14.6325 2.3546 12.8775 3.0638 11.235C3.7721 9.5925 4.73375 8.16375 5.94875 6.94875C7.16375 5.73375 8.5925 4.77165 10.235 4.06245C11.8775 3.35415 13.6325 3 15.5 3C17.3675 3 19.1225 3.35415 20.765 4.06245C22.4075 4.77165 23.8363 5.73375 25.0513 6.94875C26.2663 8.16375 27.2279 9.5925 27.9362 11.235C28.6454 12.8775 29 14.6325 29 16.5C29 18.3675 28.6454 20.1225 27.9362 21.765C27.2279 23.4075 26.2663 24.8363 25.0513 26.0513C23.8363 27.2663 22.4075 28.2279 20.765 28.9362C19.1225 29.6454 17.3675 30 15.5 30Z" fill="#133155"/>
    </svg>
  );
}

export default PlusIcon;
