import { store } from '../index';
import { changeOpenedModal } from '../redux/actions/headerModal';
import { setToast } from '../redux/actions/window';

// Simple helper functions to dispatch open/close modal events. Used to reduce the amount of duplicate code needed when there are multiple modals in a flow.

export const openModal = (modalName, data) => {
  store.dispatch(
    changeOpenedModal({
      modal: modalName,
      data
    })
  );
};

export const closeActiveModal = (onCloseCallback) => {
  store.dispatch(
    changeOpenedModal({
      modal: ''
    })
  );
  if (typeof onCloseCallback === 'function') {
    onCloseCallback();
  }
};

export const showToast = (message, isError = false) => {
  store.dispatch(
    setToast({
      toastShow: true,
      toastMessage: message,
      modal: isError ? 'errorToast' : ''
    })
  );
};
