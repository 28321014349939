import React from "react";
import { CInput, CLabel, CInputGroup, CInvalidFeedback } from "@coreui/react";

const InputField = ({
  label,
  errors,
  id,
  name,
  placeholder,
  autoComplete,
  valid,
  invalid,
  required,
  onChange,
  onBlur,
  onFocus,
  value,
  disabled,
  autoFocus,
  className,
  type,
  onKeyDown
}) => {

  let errorMsg;
  if (errors && errors[name] && errors[name]["message"]) {
    errorMsg = errors[name].message;
  } else if (errors && errors[name]) {
    errorMsg = errors[name];
  }

  return (
    <CInputGroup>
      <CInput
        onKeyDown={onKeyDown}
        className={className}
        type={type}
        id={id}
        name={name}
        placeholder={placeholder}
        autoComplete={autoComplete}
        valid={valid}
        invalid={invalid}
        required={required}
        onChange={(e) => onChange(e)}
        onBlur={(e) => onBlur(e)}
        onFocus={onFocus ? (e) => onFocus(e) : (e) => {}}
        value={value || ""}
        disabled={disabled}
        autoFocus={autoFocus}
      />
      <CInvalidFeedback>{errorMsg}</CInvalidFeedback>
    </CInputGroup>
  );
};

export default InputField;
