import React, { useState } from 'react';
import { CCol, CRow } from '@coreui/react';
import { FeatureSwitch } from '../../offers/styles';
import { Alfred } from '../../../utils/lockInfo';
import { getSequentialArr } from '../../../utils/arrayOps';
import { useTranslation } from 'react-i18next';
import { data_center_locations } from '../../../helper/types';
import { SettingsItem, SettingsItemTitle } from '../../settings/SettingsItem';
import { ToggleHelper } from '../../manage-users/ManageUserComponents';
import styled from 'styled-components';
import { SettingsModalBoldText, SettingsModalInput } from '../../settings/SettingsModals';
import { Select, SelectItem } from '../../../components/ui/Select.jsx';
import { ErrorText } from '../../../components/utils/AvatarCropper.js';

const Configurations = ({
  fieldName,
  values,
  errors,
  touched,
  setTouched,
  setFieldValue,
  handleChange,
  handleBlur,
  handleInputChange,
  handleInputOnBlur,
  handleInputOnFocus,
  isEdit,
  isReadOnly,
  isOrg = true
}) => {
  const { t } = useTranslation();
  const [showText, setShowText] = useState(false);

  const boolKeyToLabelMap = {
    enable_posts: t('Enable Post'),
    enable_offers: t('Enable Offers'),
    enable_booking: t('Enable Booking'),
    enable_lock_screen: t('Enable Lock Screen'),
    enable_service_requests: t('Enable Service Request'),
    // enable_security: t("Enable Security"),
    readonly_users: t('Read Only Users'),
    show_user_pin_resident: t('Residents can view their PINs'),
    org_allow_edits: t(`Allow Edit (${isOrg ? 'Org' : 'Building'})`),
    suite_enabled: t('Enable Multi-Lock'),
    show_user_pin_admin: t('Admin can view Master PIN'),
    show_user_pin_to_admin: t('Admin can view User PIN'),
    enable_license_plates: t('Enable License Plates'),
    enable_thermostats: t('Enable Thermostats'),
    enable_leak_detectors: t('Enable Leak Detectors'),
    enable_custom_service_request_categories: t('Enable Custom Service Request Categories'),
    enable_deliveries: t('Enable Deliveries'),
    enable_akuvox: t('Enable Akuvox'),
    enable_security_cameras: t('Enable Security Cameras'),
    enable_add_guests: t('Enable Add Guests')
  };

  const otherKeyToLabelMap = {
    user_pin_len: t('User Pin Length'),
    service_request_url: t('Service Request Url'),
    location: t('Location')
  };

  // the combined name is used to track values in formik
  const combineFieldName = (valName) => fieldName + '.' + valName;

  // frequently used name
  const serviceRequestName = combineFieldName('service_request_url');
  const locationName = combineFieldName('location');

  const onInputBlur = (e) => {
    if (isReadOnly) {
      return;
    }
    isEdit ? handleBlur(e) : handleInputOnBlur(e, values, touched, handleBlur);
  };

  const onInputChange = (e) => {
    if (isReadOnly) {
      return;
    }
    isEdit ? handleChange(e) : handleInputChange(e, touched, setTouched, handleChange);
  };

  return (
    <StyledContainer>
      {Object.keys(boolKeyToLabelMap).map((val, index, arr) => {
        const valName = combineFieldName(val);
        if (index % 2 == 1) {
          return;
        }
        let nextVal = null;
        if (index + 1 < arr.length) {
          nextVal = arr[index + 1];
        }
        let nextValName = combineFieldName(nextVal);
        return (
          <StyledRow key={val + index} isFullWidth={Boolean(nextVal)}>
            <StyledCol>
              <SettingsItem option>
                <SettingsItemTitle>{boolKeyToLabelMap[val]}</SettingsItemTitle>
                {isReadOnly ? (
                  <ToggleHelper enabled={values[fieldName][val]} />
                ) : (
                  <FeatureSwitch
                    onChange={(e) => {
                      if (!isReadOnly) {
                        setFieldValue(e.target.name, !values[fieldName][val]);
                      }
                    }}
                    checked={values[fieldName][val]}
                    value={values[fieldName][val]}
                    shape="pill"
                    id={valName}
                    name={valName}
                    disabled={!!isReadOnly}
                    color="primary"
                  />
                )}
              </SettingsItem>
            </StyledCol>
            {nextVal && (
              <StyledCol>
                <SettingsItem option>
                  <SettingsItemTitle> {boolKeyToLabelMap[nextVal]}</SettingsItemTitle>
                  {isReadOnly ? (
                    <ToggleHelper enabled={values[fieldName][nextVal]} />
                  ) : (
                    <FeatureSwitch
                      onChange={(e) =>
                        !isReadOnly && setFieldValue(e.target.name, !values[fieldName][nextVal])
                      }
                      checked={values[fieldName][nextVal]}
                      value={values[fieldName][nextVal]}
                      shape="pill"
                      id={nextValName}
                      name={nextValName}
                      disabled={!!isReadOnly}
                      color="primary"
                    />
                  )}
                </SettingsItem>
              </StyledCol>
            )}
          </StyledRow>
        );
      })}

      <SettingsModalBoldText>{otherKeyToLabelMap['user_pin_len']}</SettingsModalBoldText>
      <Select
        onChange={(v) => {
          !isReadOnly && setFieldValue(combineFieldName('user_pin_len'), parseInt(v));
        }}
        id={combineFieldName('user_pin_len')}
        name={combineFieldName('user_pin_len')}
        defaultValue={values[fieldName]['user_pin_len']}
        value={values[fieldName]['user_pin_len']}
        placeholder={otherKeyToLabelMap['user_pin_len']}
        error={errors?.user_pin_len}
        disabled={!!isReadOnly}>
        {getSequentialArr({ min: Alfred.pinMinLength, max: Alfred.pinMaxLength }).map((val) => (
          <SelectItem key={'pin-length-' + val} value={val}>
            {val}
          </SelectItem>
        ))}
      </Select>

      <SettingsModalBoldText>{otherKeyToLabelMap['location']}</SettingsModalBoldText>
      <Select
        value={values[fieldName]['location']}
        onChange={(e) => {
          setFieldValue(locationName, e);
        }}
        onBlur={handleBlur}
        onFocus={(e) =>
          !isReadOnly && !isEdit && handleInputOnFocus(e, errors, touched, setTouched)
        }
        name={locationName}
        id={locationName}
        invalid={!isReadOnly && touched[locationName] && !!errors[locationName]}
        disabled={!!isReadOnly}>
        {data_center_locations.map((item) => (
          <SelectItem key={'config-location-' + item.id} value={item.value}>
            {item.value}
          </SelectItem>
        ))}
      </Select>

      <SettingsModalBoldText>{otherKeyToLabelMap['service_request_url']}</SettingsModalBoldText>
      <SettingsModalInput
        onChange={onInputChange}
        value={values[fieldName]['service_request_url']}
        name={serviceRequestName}
        error={errors?.service_request_url}
        placeholder={otherKeyToLabelMap['service_request_url']}
        disabled={!!isReadOnly}
      />
      {errors?.service_request_url && <ErrorText>{errors.service_request_url}</ErrorText>}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  width: 100%;
`;

const StyledRow = styled(CRow)`
  margin-right: 0;
  margin-left: 0;
  justify-content: space-between;
  gap: 8px;
  width: ${(props) => (props.isFullWidth ? '100%' : '50%')};
  padding-right: ${(props) => (props.isFullWidth ? '0px' : '4px')};
`;

const StyledCol = styled(CCol)`
  padding-right: 0;
  padding-left: 0;
`;

export default Configurations;
