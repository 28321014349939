import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setGlobalBuildingId, setGlobalSelectedBuilding } from '../redux/actions/building';
import { useResetAllPaginations } from '../hooks/useResetAllPaginations';
import {
  DropdownIndicator,
  filterConfig,
  IconOption,
  inputSelectCustomStyles,
  StyledAsyncPaginate
} from './CustomSearchBar';
import { closeActiveModal } from '../helper/modals';
import { getBuildings } from '../helper';
import { components } from 'react-select';
import { Fonts, Palette } from './utils';
import styled from 'styled-components';
import { ReactComponent as BuildingsIcon } from '../assets/icons/amenities.svg';

const defaultBuilding = [
  {
    value: 0,
    label: 'All buildings'
  }
];

const GlobalBuildingDropDown = () => {
  const { globalOrgId } = useSelector((state) => state.Organization);
  const {
    globalBuildingId,
    globalBuildingName,
    buildingChangeEventCount,
    showGlobalBuildingsDropdown,
    globalSelectedBuilding
  } = useSelector((state) => state.building);
  const { t } = useTranslation();
  const { resetAllPaginations } = useResetAllPaginations();
  const dispatch = useDispatch();

  const handleOnChange = (building) => {
    dispatch(
      setGlobalBuildingId({ globalBuildingId: building.value, globalBuildingName: building.label })
    );
    dispatch(setGlobalSelectedBuilding(building));
    resetAllPaginations();
    closeActiveModal();
  };

  const loadOptions = useCallback(
    async (searchQuery, loadedOptions, additional) => {
      if (globalOrgId <= 0) {
        return {
          options: [],
          hasMore: false
        };
      }

      const { page } = additional;
      const responseJSON = await getBuildings(globalOrgId, page);
      const myResponse = defaultBuilding.concat(
        responseJSON.map((building) => {
          return {
            ...building,
            value: building.id,
            label: building.name
          };
        })
      );

      return {
        options: myResponse,
        hasMore: responseJSON !== null && responseJSON.length >= 20,
        additional: {
          page: searchQuery ? 2 : page + 1
        }
      };
    },
    [globalOrgId, globalBuildingId]
  );

  useEffect(() => {
    // TODO: Leave this here for now, but we should just favour using the entire object rather than just the ID
    dispatch(
      setGlobalBuildingId({
        globalBuildingId: 0,
        globalBuildingName: ''
      })
    );
    dispatch(setGlobalSelectedBuilding(undefined));
  }, [globalOrgId]);

  return (
    showGlobalBuildingsDropdown && (
      <StyledAsyncPaginate
        key={`${globalOrgId}_${globalBuildingId}_${buildingChangeEventCount}`}
        debounceTimeout={500}
        isDisabled={globalOrgId <= 0}
        className="react-select-container"
        classNamePrefix="react-select"
        value={globalBuildingId ? { label: globalBuildingName } : ''}
        loadOptionsOnMenuOpen={true}
        loadOptions={loadOptions}
        onChange={handleOnChange}
        filterOption={filterConfig}
        placeholder={t('All buildings')}
        styles={inputSelectCustomStyles({
          height: '44px',
          minHeight: '44px',
          padding: '0 10px'
        })}
        components={{
          IndicatorSeparator: () => null,
          Option: IconOption,
          Control,
          DropdownIndicator
        }}
        additional={{
          page: 1
        }}
      />
    )
  );
};

const Control = (props) => (
  <StyledControl {...props}>
    <BuildingsIcon />
    {props.children}
  </StyledControl>
);

const fontStyles = `
  color: ${Palette.BLACK_50};
  font-family: ${Fonts.OPEN_SANS_FONT};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const StyledControl = styled(components.Control)`
  display: flex;
  justify-content: space-between;
  gap: 8px;

  .react-select__placeholder {
    ${fontStyles}
  }

  .react-select__single-value {
    ${fontStyles}
  }
`;

export default GlobalBuildingDropDown;
